/**
 * Imports
 */
import { Box, Divider, Space, Text } from 'components';
import { NextButton, SignupText } from 'features/signupflow';
import { useSignupFlow } from 'features/signupflow/api';
import { countryCodeSelector } from 'features/signupflow/redux/selectors';
import {
  GoogleSignupButton,
  LoggedInAs,
  SignupForm,
} from 'features/user/components';
import { maybeUserSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ROUTES } from 'router';
import { styled } from 'theme';
import { WelcomeSection } from '../..';

/**
 * Styling
 */
const EmailText = styled(Text)`
  margin-bottom: 24px;
`;
const Subheading = styled(Text)``;

/**
 * AuthStep component
 */
const AuthStep: React.FC = () => {
  const { t } = useTranslation();
  const navigation = useHistory();
  const user = useSelector(maybeUserSelector);
  const countryCode = useSelector(countryCodeSelector);
  const {
    handlers: { onNextStep },
  } = useSignupFlow();

  const _nextStep = () => {
    onNextStep();
  };
  /**
   * If no user and no countryCode - navigate to country step
   */
  React.useEffect(() => {
    if (!user && !countryCode) {
      navigation.push(ROUTES.home.path);
    }
  }, []);

  return (
    <React.Fragment>
      <Box mb={countryCode === 'ES' ? '16px' : '32px'}>
        <Text size="h5">{t('AuthStep.heading')}</Text>
      </Box>
      {!user ? (
        <React.Fragment>
          {countryCode === 'ES' && <WelcomeSection />}
          <GoogleSignupButton />
          <Divider whiteSpace="lg" />
          <EmailText size="ui2" bold>
            {t('AuthStep.text_use_email')}
          </EmailText>
          <SignupForm />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Space />
          <LoggedInAs />
          <Space />
          <NextButton onClick={_nextStep} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

/**
 * Exports
 */
export { AuthStep };
