/**
 * Imports
 */
import { Divider, Label, Space, Text } from 'components';
import {
  VatData,
  VatError,
  VatPinField,
  VatSearchField,
} from 'features/signupflow';
import { useMerchantApi, useSignupFlow } from 'features/signupflow/api';
import { vatDataSelector } from 'features/signupflow/redux/selectors';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { styled } from 'theme';
import { captureError } from 'utils';
import { NextButton, SignupText } from '../../SignupLayout';
/**
 * Styling
 */
const PinFieldWrapper = styled.div`
  & input:first-of-type {
    margin-left: -0 !important;
  }
  & input:last-of-type {
    margin-right: -0 !important;
  }
  margin-bottom: 32px;
`;

const VatSearchFieldWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

/**
 * VatStep component
 */
const VatStep: React.FC = () => {
  const vatData = useSelector(vatDataSelector);
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  const {
    handlers: { onUpdateMerchantVat },
  } = useMerchantApi();
  const {
    handlers: { onNextStep },
  } = useSignupFlow();
  /* Local state */
  const [isLoading, setIsLoading] = React.useState(false);
  /* In-use variables */
  const hasCvrData = onboardingCollection?.companyRegistrationNumber;
  const hasSearchData = vatData?.vat;
  const isValid = hasCvrData || hasSearchData;

  const _onNext = async () => {
    setIsLoading(true);
    try {
      // Check if data exists and no new search data is present
      if (hasCvrData && !hasSearchData) {
        setIsLoading(false);
        onNextStep();
      } else if (vatData?.address && vatData?.companyName && vatData?.vat) {
        await onUpdateMerchantVat({
          city: vatData.city,
          zipCode: vatData.zipcode,
          addressLine1: vatData.address,
          companyRegistrationNumber: vatData.vat,
          merchantTradingName: vatData.companyName,
        });
        setIsLoading(false);
        onNextStep();
      }
    } catch (exception) {
      setIsLoading(false);
      captureError('Error: Failed to update merchant information', exception);
    }
  };

  return (
    <>
      <SignupText
        subheading={<Trans i18nKey="RegistrationNumberStep.cvr.heading" />}
      />
      <PinFieldWrapper>
        <VatPinField />
      </PinFieldWrapper>

      <Divider whiteSpace="md" />
      <Text size="ui2" bold>
        <Trans i18nKey="RegistrationNumberStep.cvr.heading_search" />
      </Text>
      <VatSearchFieldWrapper>
        <Label
          text={<Trans i18nKey="RegistrationNumberStep.cvr.label_company" />}
        />
        <VatSearchField />
      </VatSearchFieldWrapper>
      <VatError />
      <VatData />
      <Space />
      <NextButton disabled={!isValid} loading={isLoading} onClick={_onNext} />
    </>
  );
};

/**
 * Exports
 */
export { VatStep };
