/**
 * Imports
 */
import { VibrantIcon } from 'components';
import { VibrantIconTypes } from 'components/VibrantIcon/VibrantIcon';
import * as React from 'react';

import { styled } from 'theme';
import { StringLiteralPick } from 'utils';

/**
 * Styling
 */
const Root = styled.div`
  background-color: ${({ theme }) => theme.colors.Background.Primary};
  border-radius: 4px;
  height: 22px;
  width: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * Types
 */
export type CardTypes = StringLiteralPick<
  VibrantIconTypes,
  'visa' | 'master-card'
>;
export interface CreditCardProps {
  card: CardTypes;
}

/**
 * CreditCard component
 */
const CreditCard: React.FC<CreditCardProps> = (_props) => {
  return (
    <Root>
      <VibrantIcon icon="visa" />
    </Root>
  );
};

/**
 * Exports
 */
export { CreditCard };
