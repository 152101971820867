/**
 * Imports
 */
import { InfoCircleOutlined } from '@ant-design/icons';
import { Radio, RadioChangeEvent, Row, Tooltip } from 'antd';
import { Icon, RadioButton, Text, VibrantIcon } from 'components';
import {
  NextButton,
  SignupText,
  useMerchantApi,
  useSignupFlow,
} from 'features/signupflow';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { styled } from 'theme';

/**
 * Styling
 */
const StyledSelect = styled(Radio.Group)`
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`;
const TextWrapper = styled.div`
  margin-left: 32px;
`;

const StyledTooltip = styled(Tooltip)`
  margin-top: 4px;
  margin-left: 8px;
`;

/**
 * DeviceStep component
 */
const DeviceStep: React.FC = () => {
  const [selecedValue, setSelectedValue] = React.useState('');
  const [isValid, setIsValid] = React.useState(false);
  const {
    handlers: { onUpdateDeviceType },
  } = useMerchantApi();
  const {
    handlers: { onNextStep },
  } = useSignupFlow();

  const _next = async () => {
    await onUpdateDeviceType({ deviceType: selecedValue });
    onNextStep();
  };

  const _onChange = (e: RadioChangeEvent) => {
    setSelectedValue(e.target.value);
    setIsValid(true);
  };

  return (
    <>
      <SignupText subheading={<Trans i18nKey="DeviceStep.heading" />} />
      <StyledSelect onChange={_onChange} buttonStyle="outline">
        <RadioButton
          checked={selecedValue === 'Android'}
          value="Android"
          icon={<VibrantIcon icon="android" size={28} />}
          text={
            <TextWrapper>
              <Text size="ui2" color="heading">
                <Trans i18nKey="DeviceStep.btn_text" />
              </Text>
              <Text size="ui1" bold color="heading">
                <Trans i18nKey="DeviceStep.btn_android" />
              </Text>
            </TextWrapper>
          }
        />
        <RadioButton
          checked={selecedValue === 'Lease'}
          value="Lease"
          icon={<VibrantIcon icon="android" size={28} />}
          text={
            <TextWrapper>
              <Row align="middle">
                <Text size="ui2" color="heading">
                  <Trans i18nKey="DeviceStep.btn_text_alt" />
                </Text>
                <StyledTooltip
                  placement="top"
                  title={<Trans i18nKey="DeviceStep.vibrant_lease_info" />}
                >
                  <div>
                    <Icon icon={<InfoCircleOutlined />} />
                  </div>
                </StyledTooltip>
              </Row>
              <Text size="ui1" bold color="heading">
                <Trans i18nKey="DeviceStep.btn_android" />
              </Text>
            </TextWrapper>
          }
        />
      </StyledSelect>
      <NextButton onClick={_next} disabled={!isValid} />
    </>
  );
};

/**
 * Exports
 */
export { DeviceStep };
