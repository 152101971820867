/**
 * Redirect to Merchant Dashboard
 */

import { FIREBASE_CONFIG } from 'config';

export const redirectToMerchantDashboard = (token?: string): any => {
  const url = `${FIREBASE_CONFIG.dashboardURL}/tokenLogin/${token}`;
  const urlNormal = `${FIREBASE_CONFIG.dashboardURL}`;
  return (window.location.href = token ? url : urlNormal);
};
