/**
 * Imports
 */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { ProviderProps } from 'react-redux';
// Firebase setup
import { actionTypes } from 'react-redux-firebase';
import { rootReducer } from './reducer';

/**
 * Functions
 */
const storePromise: Promise<ProviderProps['store']> = new Promise(
  (resolve, reject) => {
    try {
      const store = configureStore({
        reducer: rootReducer,
        middleware: [
          /**
           * We need to disable the serialization check here because of Firebase's
           * auth object. See this link for more info:
           *
           * https://github.com/prescottprue/react-redux-firebase/issues/761
           */
          ...getDefaultMiddleware({
            serializableCheck: {
              ignoredActions: [actionTypes.LOGIN],
            },
          }),
        ],
      });
      resolve(store);
    } catch (error) {
      console.log('ERROR', error);
      reject(error);
    }
  },
);
/**
 * Redux store
 */
export { storePromise };
