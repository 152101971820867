/**
 * Imports
 */
import { InfoCircleOutlined } from '@ant-design/icons';
import { Radio } from 'antd';
import {
  Icon,
  Label,
  RadioButton,
  Select,
  Space,
  Text,
  Tooltip,
  VibrantIcon,
} from 'components';
import { NextButton, SignupText, useSignupFlow } from 'features/signupflow';
import { useMerchantApi } from 'features/signupflow/api';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { styled } from 'theme';
import { Firebase } from 'types/firebase';

/**
 * Styling
 */
const StyledSelect = styled(Radio.Group)`
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled.div`
  margin: 24px 0;
  width: 100%;
`;

/**
 * Types
 */
export interface BusinessStructureStepProps {}

/**
 * BusinessStructureStep component
 */
const BusinessStructureStep: React.FC<BusinessStructureStepProps> = (
  _props,
) => {
  const [selecedValue, setSelectedValue] = React.useState<
    Firebase.BusinessStructure | ''
  >('');
  const [isValid, setIsValid] = React.useState(false);
  const [otherValue, setValue] = React.useState('');
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  const {
    handlers: { onNextStep },
  } = useSignupFlow();
  const {
    handlers: { onUpdateBusinessStructure },
  } = useMerchantApi();

  const _onSelect = (businessStructure: string) => {
    setSelectedValue(businessStructure as Firebase.BusinessStructure);
    if (businessStructure === 'other') {
      if (otherValue.length === 0) {
        setIsValid(false);
      }
    } else {
      setIsValid(true);
    }
  };

  const _onChange = (value: string) => {
    console.log('_onChange', value);
    setIsValid(!!value ? true : false);
    setValue(value);
  };

  const _next = () => {
    onUpdateBusinessStructure({
      businessStructure:
        selecedValue === 'other'
          ? (otherValue as Firebase.BusinessStructure)
          : (selecedValue as Firebase.BusinessStructure),
    });
    onNextStep();
  };

  React.useEffect(() => {
    if (onboardingCollection.businessStructure) {
      const { businessStructure } = onboardingCollection;
      if (
        !(
          businessStructure === 'soleTrader' ||
          businessStructure === 'limitedLiability'
        )
      ) {
        _onSelect('other');
        _onChange(businessStructure);
      } else {
        _onSelect(businessStructure);
      }
    }
  }, []);

  return (
    <>
      <SignupText
        subheading={<Trans i18nKey="BusinessStructureStep.heading" />}
      />
      <StyledSelect
        onChange={(e) => _onSelect(e.target.value)}
        buttonStyle="outline"
      >
        <RadioButton
          checked={selecedValue === 'soleTrader'}
          icon={null}
          value="soleTrader"
          text={
            <>
              <Text size="ui2" color="heading">
                <Trans i18nKey="BusinessStructureStep.options.sole_trader" />
              </Text>
              <Tooltip
                title={
                  <Trans i18nKey="BusinessStructureStep.options.sole_trader_tooltip">
                    <b />
                  </Trans>
                }
              >
                <Icon icon={<InfoCircleOutlined />} />
              </Tooltip>
            </>
          }
        />

        <RadioButton
          checked={selecedValue === 'limitedLiability'}
          icon={null}
          value="limitedLiability"
          text={
            <>
              <Text size="ui2" color="heading">
                <Trans i18nKey="BusinessStructureStep.options.limited_liability" />
              </Text>
              <Tooltip
                title={
                  <Trans i18nKey="BusinessStructureStep.options.limited_liability_tooltip">
                    <b />
                  </Trans>
                }
              >
                <Icon icon={<InfoCircleOutlined />} />
              </Tooltip>
            </>
          }
        />
        <RadioButton
          checked={selecedValue === 'other'}
          icon={null}
          value="other"
          text={
            <Text size="ui2" color="heading">
              <Trans i18nKey="BusinessStructureStep.options.other" />
            </Text>
          }
        />
      </StyledSelect>
      {selecedValue === 'other' && (
        <InputWrapper>
          <Label text={<Trans i18nKey="BusinessStructureStep.input_label" />} />

          <Select
            allowClear
            value={otherValue || undefined}
            suffixIcon={<VibrantIcon icon="chevron-down" size={8} />}
            menuItemSelectedIcon={
              <VibrantIcon icon="checkmark-checked" size={12} />
            }
            style={{ width: '100%' }}
            // Needed to override select dropdown style
            getPopupContainer={(trigger) => {
              return trigger;
            }}
            // @ts-ignore
            onChange={(val) => _onChange(val)}
            placeholder={
              <Trans i18nKey="BusinessStructureStep.input_placeholder" />
            }
          >
            <Select.Option value={'ngo'}>
              <Trans i18nKey="BusinessStructureStep.options.ngo" />
            </Select.Option>
            <Select.Option value={'public'}>
              <Trans i18nKey="BusinessStructureStep.options.public" />
            </Select.Option>
            <Select.Option value={'other'}>
              <Trans i18nKey="BusinessStructureStep.options.other" />
            </Select.Option>
          </Select>
        </InputWrapper>
      )}
      <NextButton onClick={_next} disabled={!isValid} />
      <Space />
    </>
  );
};

/**
 * Exports
 */
export { BusinessStructureStep };
