/**
 * Imports
 */
import * as React from 'react';

import { styled } from 'theme';

/**
 * Styling
 */
const Root = styled.div`
  padding: 24px;
`;

/**
 * Types
 */
export interface AccountPageProps {}

/**
 * AccountPage component
 */
const AccountPage: React.FC<AccountPageProps> = (_props) => {
  return <Root>AccountPage</Root>;
};

/**
 * Exports
 */
export { AccountPage };
