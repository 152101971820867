/**
 * Imports
 */
import * as React from 'react';
import { Modal } from 'antd';
import { Typography } from 'antd';
import { Button } from 'antd';
import styled from 'styled-components';
import { getFirebaseInstance } from 'firebaseAPI/firebaseAdapter';
import { Input } from 'components';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
/**
 * Types
 */
type PasswordModalProps = {
  show: boolean;
  setShowModal: (show: boolean) => void;
};

const EmailText = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const ResetButton = styled(Button)`
  margin-top: 25px;
`;

/**
 * LogoutButton component
 */
const PasswordModal: React.FC<PasswordModalProps> = ({
  show,
  setShowModal,
}) => {
  const [error, setError] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const { t } = useTranslation();
  const isEmailValid = (email: string) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const _ok = async () => {
    const isValid = isEmailValid(email);
    if (isValid) {
      try {
        const firebase = getFirebaseInstance();
        await firebase.auth().sendPasswordResetEmail(email);
        alert(t('_Common.forgot_password.new_password_send'));
        setEmail('');
        setShowModal(false);
      } catch (e) {
        console.log(e);
      }
    } else {
      setError(true);
      setTimeout(() => setError(false), 3000);
    }
  };
  return (
    <Modal
      title="Send me a new password"
      visible={show}
      onOk={_ok}
      onCancel={() => setShowModal(false)}
      footer={null}
    >
      <EmailText>Email</EmailText>
      <Input
        placeholder="Your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <ResetButton onClick={_ok} type="primary">
        Send new password
      </ResetButton>
      {error && <Text type="danger">Please input valid email</Text>}
    </Modal>
  );
};

/**
 * Exports
 */
export { PasswordModal };
