/**
 * Imports
 */
import { BaseLayout } from 'features/navigation/components';
import { SignupLayout } from 'features/signupflow';
import { BankStep } from 'features/signupflow/components/Steps';
import * as React from 'react';

/**
 * Types
 */
export interface BankPageProps {}

/**
 * BankPage component
 */
const BankPage: React.FC<BankPageProps> = (_props) => {
  return (
    <BaseLayout>
      <SignupLayout>
        <BankStep />
      </SignupLayout>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { BankPage };
