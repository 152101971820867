/**
 * Imports
 */
import { BaseLayout } from 'features/navigation/components';
import { SignupLayout } from 'features/signupflow';
import {
  RegistrationNumberForm,
  RegistrationNumberStep,
  VatStep,
} from 'features/signupflow/components/Steps';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';

/**
 * Types
 */
export interface RegistrationNumberPageProps {}

/**
 * RegistrationNumberPage component
 */
const RegistrationNumberPage: React.FC<RegistrationNumberPageProps> = (
  _props,
) => {
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  return (
    <BaseLayout>
      <SignupLayout>
        {onboardingCollection.countryCode === 'ES' ? (
          <RegistrationNumberStep />
        ) : onboardingCollection.countryCode === 'SE' ? (
          <RegistrationNumberForm />
        ) : (
          <VatStep />
        )}
      </SignupLayout>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { RegistrationNumberPage };
