/**
 * Imports
 */
import { BaseLayout } from 'features/navigation/components';
import {
  CompanyDetailsForm,
  CompanyDetailsStep,
  PhoneStep,
  SignupLayout,
} from 'features/signupflow';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';

/**
 * Types
 */
export interface CompanyDetailsPageProps {}

/**
 * CompanyDetailsPage component
 */
const CompanyDetailsPage: React.FC<CompanyDetailsPageProps> = (_props) => {
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  return (
    <BaseLayout>
      <SignupLayout>
        {onboardingCollection.countryCode === 'DK' ? (
          <PhoneStep />
        ) : onboardingCollection.countryCode === 'SE' ? (
          <CompanyDetailsForm />
        ) : (
          <CompanyDetailsStep />
        )}
      </SignupLayout>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { CompanyDetailsPage };
