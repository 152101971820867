/**
 * Imports
 */
import { Form } from 'antd';
import { Input, Label, messageError, Space, SubText } from 'components';
import { NextButton, SignupText } from 'features/signupflow';
import { useMerchantApi, useSignupFlow } from 'features/signupflow/api';
import { vatDataSelector } from 'features/signupflow/redux/selectors';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { captureError } from 'utils';

/**
 * PhoneStep component
 */
const PhoneStep: React.FC = () => {
  const [t] = useTranslation();
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  const {
    handlers: { onUpdateContactInformation },
  } = useMerchantApi();
  const {
    handlers: { onNextStep },
  } = useSignupFlow();
  /* Redux */
  const vatData = useSelector(vatDataSelector);
  /* Local state */
  const [isLoading, setIsLoading] = React.useState(false);
  const [isPhoneValid, setPhoneValid] = React.useState(false);
  const [_phone, setPhone] = React.useState('');

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneValid(e.target.value.length > 7 ? true : false);
    setPhone(e.target.value);
  };

  const _onFinish = async () => {
    setIsLoading(true);
    try {
      await onUpdateContactInformation({ phone: _phone });
      setIsLoading(false);
      onNextStep();
    } catch (exception) {
      setIsLoading(false);
      messageError({
        content: 'Error: Failed to update company phone',
      });
      captureError('Error: Failed to update company phone', exception);
    }
  };

  React.useEffect(() => {
    if (onboardingCollection?.contact?.phone) {
      setPhone(onboardingCollection.contact.phone);
      setPhoneValid(true);
    }
    if (vatData?.phone) {
      setPhone(vatData.phone);
      setPhoneValid(true);
    }
  }, [vatData]);

  return (
    <>
      <SignupText
        subheading={<Trans i18nKey="CompanyDetails.phone_step.heading" />}
      />
      <Form name="formCeo" layout="vertical" onFinish={_onFinish}>
        <Label
          text={<Trans i18nKey="CompanyDetails.phone_step.label_phone" />}
        />
        <Input
          value={_phone}
          placeholder={t('CompanyDetails.phone_step.input_placeholder')}
          onChange={_onChange}
        />
        <Space size="sm" />
        {vatData?.phone && (
          <SubText
            text={<Trans i18nKey="CompanyDetails.phone_step.subtext" />}
          />
        )}
        <Space size="lg" />
        <Form.Item>
          <NextButton loading={isLoading} disabled={!isPhoneValid} />
        </Form.Item>
      </Form>
    </>
  );
};

/**
 * Exports
 */
export { PhoneStep };
