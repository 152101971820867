/**
 * Imports
 */
import { Form } from 'antd';
import { Label, Select, Space, VibrantIcon } from 'components';
import {
  NextButton,
  SignupText,
  useMerchantApi,
  useSignupFlow,
} from 'features/signupflow';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { styled } from 'theme';
import { captureError, ONBOARDING_FIELDS } from 'utils';

const LastFormItem = styled(Form.Item)`
  && {
    margin-bottom: 8px;
  }
`;

/**
 * CONST
 */

const MONTHLY_TURNOVER_VALUES: {
  [country: string]: { [rev: number]: string };
} = {
  DK: {
    30000: '0 - 30.000 DKK',
    100000: '30.000 - 100.000 DKK',
    100001: '100.000+ DKK',
  },
  SE: {
    30000: '0 - 30.000 SEK',
    100000: '30.000 - 100.000 SEK',
    100001: '100.000+ SEk',
  },
  ES: {
    5000: '0 - 5,000 EUR',
    14000: '5,000 - 14.000 EUR',
    14001: '14.000+ EUR',
  },
};
/**
 * RevenueStep component
 */
const RevenueStep: React.FC = () => {
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  const {
    handlers: { onUpdateEstimatedRevenue },
  } = useMerchantApi();
  const {
    handlers: { onNextStep },
  } = useSignupFlow();
  const [form] = Form.useForm();
  /* Local state */
  const [selectValues, setSelectValues] = React.useState({
    select1: -1,
    select2: '',
  });
  const [isLoading, setIsLoading] = React.useState(false);

  /**
   * Submit
   */
  const _onFinish = async () => {
    setIsLoading(true);
    try {
      await onUpdateEstimatedRevenue({
        merchantSegment: selectValues.select2,
        monthlyTurnover: selectValues.select1,
      });
      setIsLoading(false);
      onNextStep();
    } catch (exception) {
      setIsLoading(false);
      captureError('Error: Failed to update revenue information', exception);
    }
  };
  /**
   * Change monthly turnover
   */
  const _onChangeTurnover = (value: number) => {
    setSelectValues((prev) => ({
      select1: !value ? -1 : value,
      select2: prev.select2,
    }));
  };
  /**
   * Change merchant segment
   */
  const _onChangeTransaction = (value: string) => {
    setSelectValues((prev) => ({
      select1: prev.select1,
      select2: !value ? '' : value,
    }));
  };

  React.useEffect(() => {
    if (onboardingCollection?.monthlyTurnover) {
      form.setFieldsValue({
        monthlyTurnover: onboardingCollection.monthlyTurnover,
      });
      setSelectValues((prev) => ({
        select1: onboardingCollection.monthlyTurnover!,
        select2: prev.select2,
      }));
    }
    if (onboardingCollection?.merchantSegment) {
      form.setFieldsValue({
        merchantSegment: onboardingCollection.merchantSegment,
      });
      setSelectValues((prev) => ({
        select1: prev.select1,
        select2: onboardingCollection.merchantSegment!,
      }));
    }
  }, [onboardingCollection]);
  return (
    <>
      <SignupText subheading={<Trans i18nKey="RevenueStep.heading" />} />
      <Form name="revenue-form" onFinish={_onFinish} form={form}>
        <Label text={<Trans i18nKey="RevenueStep.select_1_label" />} />
        <Form.Item name={ONBOARDING_FIELDS.monthlyTurnover}>
          <Select
            dropdownClassName="jørgen"
            allowClear
            suffixIcon={<VibrantIcon icon="chevron-down" size={8} />}
            menuItemSelectedIcon={
              <VibrantIcon icon="checkmark-checked" size={12} />
            }
            // Needed to override select dropdown style
            getPopupContainer={(trigger) => {
              return trigger;
            }}
            // @ts-ignore
            onChange={(val) => _onChangeTurnover(val)}
            placeholder={<Trans i18nKey="RevenueStep.select_1_placeholder" />}
          >
            {onboardingCollection?.countryCode &&
              Object.entries(
                MONTHLY_TURNOVER_VALUES[onboardingCollection?.countryCode],
              ).map((entry) => {
                return (
                  <Select.Option key={entry[0]} value={entry[0]}>
                    {entry[1]}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Label text={<Trans i18nKey="RevenueStep.select_2_label" />} />
        <LastFormItem name={ONBOARDING_FIELDS.merchantSegment}>
          <Select
            allowClear
            // Needed to override select dropdown style
            getPopupContainer={(trigger) => {
              return trigger;
            }}
            suffixIcon={<VibrantIcon icon="chevron-down" size={8} />}
            menuItemSelectedIcon={
              <VibrantIcon icon="checkmark-checked" size={12} />
            }
            // @ts-ignore
            onChange={(val) => _onChangeTransaction(val)}
            placeholder={<Trans i18nKey="RevenueStep.select_2_placeholder" />}
          >
            {onboardingCollection?.countryCode && (
              <>
                <Select.Option value={'daily'}>
                  <Trans i18nKey="RevenueStep.select_2_option_1" />
                </Select.Option>
                <Select.Option value={'weekly'}>
                  <Trans i18nKey="RevenueStep.select_2_option_2" />
                </Select.Option>
                <Select.Option value={'events/seasonal'}>
                  <Trans i18nKey="RevenueStep.select_2_option_3" />
                </Select.Option>{' '}
              </>
            )}
          </Select>
        </LastFormItem>
        <Space />
        <Form.Item>
          <NextButton
            loading={isLoading}
            disabled={
              !(selectValues.select1 !== -1 && selectValues.select2 !== '')
            }
          />
        </Form.Item>
      </Form>
    </>
  );
};

/**
 * Exports
 */
export { RevenueStep };
