/**
 * Imports
 */
import { BaseLayout } from 'features/navigation/components';
import { SignupLayout } from 'features/signupflow';
import { RevenueStep } from 'features/signupflow/components/Steps';
import * as React from 'react';

/**
 * Types
 */
export interface RevenuePageProps {}

/**
 * RevenuePage component
 */
const RevenuePage: React.FC<RevenuePageProps> = (_props) => {
  return (
    <BaseLayout>
      <SignupLayout>
        <RevenueStep />
      </SignupLayout>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { RevenuePage };
