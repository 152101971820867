/**
 * Imports
 */
import { createReducer } from '@reduxjs/toolkit';
import { RoutesType } from 'router';

import {
  closeMobileMenu,
  openMobileMenu,
  setCurrentRoute,
  SetCurrentRouteAction,
} from './actions';

/**
 * Types
 */
export interface NavigationState {
  pageId: RoutesType | null;
  path: string | null;
  route: string | null;
  showMobileMenu: boolean;
}

/**
 * Constants
 */
const INITIAL_STATE: NavigationState = {
  pageId: null,
  path: null,
  route: null,
  showMobileMenu: false,
};

/**
 * `navigation` reducer
 */
const navigationReducer = createReducer(INITIAL_STATE, {
  /**
   * SET_CURRENT_ROUTE
   */
  [setCurrentRoute.type]: (state, action: SetCurrentRouteAction) => {
    state.pageId = action.payload.pageId;
    state.path = action.payload.path;
    state.route = action.payload.route;
  },
  /**
   * OPEN_MOBILE_MENU
   */
  [openMobileMenu.type]: (state) => {
    state.showMobileMenu = true;
  },
  /**
   * CLOSE_MOBILE_MENU
   */
  [closeMobileMenu.type]: (state) => {
    state.showMobileMenu = false;
  },
});

/**
 * Exports
 */
export default navigationReducer;
