/**
 * Imports
 */
import { Form } from 'antd';
import { Input, Label, Space, SubText } from 'components';
import { NextButton, SignupText } from 'features/signupflow';
import { useMerchantApi, useSignupFlow } from 'features/signupflow/api';
import { temporaryDisplayNameSelector } from 'features/signupflow/redux/selectors';
import {
  displayNameSelector,
  onboardingCollectionSelector,
} from 'features/user/redux/selectors';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Firebase } from 'types/firebase';
import { captureError } from 'utils';

/**
 * CeoStep component
 */
const CeoStep: React.FC = () => {
  const [t] = useTranslation();
  const {
    handlers: { onUpdateCeo },
  } = useMerchantApi();
  const {
    handlers: { onNextStep },
  } = useSignupFlow();
  /* Redux */
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  const displayName = useSelector(displayNameSelector);
  const temporaryDisplayName = useSelector(temporaryDisplayNameSelector);
  /* Local state */
  const [isLoading, setIsLoading] = React.useState(false);
  const [isNameValid, setNameValid] = React.useState(false);
  const [fullname, setFullname] = React.useState('');

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameValid(e.target.value.length > 1 ? true : false);
    setFullname(e.target.value);
  };

  const _onFinish = async () => {
    setIsLoading(true);
    try {
      const ownerData: Firebase.Owner = {
        addressId: null,
        name: fullname,
        fileUrl: null,
        photoId: null,
        roleDirector: true,
        socialSecurityNumber: '',
      };
      await onUpdateCeo({ ownerData: [ownerData] });
      setIsLoading(false);

      onNextStep();
    } catch (exception) {
      setIsLoading(false);
      captureError('Error: Failed to update ceo information', exception);
    }
  };

  React.useEffect(() => {
    if (onboardingCollection?.ownerData) {
      const { ownerData } = onboardingCollection;
      setFullname(ownerData[0].name);
      setNameValid(true);
    } else {
      const fullName = displayName || temporaryDisplayName;
      setFullname(fullName);
      setNameValid(fullName ? true : false);
    }
  }, [displayName, onboardingCollection]);

  return (
    <>
      <SignupText subheading={<Trans i18nKey="CeoStep.heading" />} />
      <Form name="formCeo" layout="vertical" onFinish={_onFinish}>
        <Label text={<Trans i18nKey="CeoStep.input_label" />} />
        <Input
          value={fullname}
          placeholder={t('CeoStep.input_placeholder')}
          onChange={_onChange}
        />
        <Space size="sm" />
        <SubText text={<Trans i18nKey="CeoStep.subtext" />} />
        <Space size="lg" />
        <Form.Item>
          <NextButton loading={isLoading} disabled={!isNameValid} />
        </Form.Item>
      </Form>
    </>
  );
};

/**
 * Exports
 */
export { CeoStep };
