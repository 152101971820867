/**
 * Imports
 */
import { createSelector } from '@reduxjs/toolkit';

import { VibrantlyState } from 'store/types';
import { NavigationState } from './reducer';

/**
 * Selectors
 */
export const rootSelector = (state: VibrantlyState): NavigationState =>
  state.navigation;
export const currentRouteSelector = createSelector(
  [rootSelector],
  (root) => root,
);
export const showMobileMenuSelector = createSelector(
  [rootSelector],
  (root) => root.showMobileMenu,
);
