/**
 * Imports
 */
import { Alert } from 'antd';
import { Button, Input, Logo, Text } from 'components';
import userFeature from 'features/user';
import { firebaseLogin } from 'firebaseAPI';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { styled } from 'theme';
import { LostPassword } from '../ResetPassword';

/**
 * Styles Components
 */
const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  align-self: center;
`;
const LoginContent = styled.div`
  background-color: ${(props) => props.theme.colors.Background.Brand};
  border-radius: 4px;
  padding: 24px;
  max-height: 400px;
  max-width: 400px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
`;
const LogoWrapper = styled.div`
  padding: 12px 0;
`;

/**
 * LoginForm component
 */
const LoginForm: React.FC = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const isAuthenticated = !!useSelector(
    userFeature.redux.selectors.maybeUserSelector,
  );

  React.useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(false);
      history.replace({ pathname: '/' });
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const _handleLogin = async () => {
    try {
      setIsLoading(true);
      const user = await firebaseLogin(email, password);
      dispatch(userFeature.redux.actions.userUpdated(user));
      history.replace({ pathname: '/' });
    } catch (error) {
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(null), 3000);
      setIsLoading(false);
    }
  };

  const _handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      _handleLogin();
    }
  };

  return (
    <Root>
      <LoginContent>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <Text size="h3" color="white" />
        <Row>
          <Input
            placeholder="Email address"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onKeyDown={_handleKeyDown}
          />
        </Row>
        <Row>
          <Input
            placeholder="Password"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyDown={_handleKeyDown}
          />
        </Row>
        <Button type="primary" onClick={_handleLogin} loading={isLoading}>
          Login
        </Button>
        <LostPassword />
        {errorMessage && <Alert type="error" message={errorMessage} />}
      </LoginContent>
    </Root>
  );
};

/**
 * Exports
 */
export { LoginForm };
