/**
 * IBAN examples
 * https://www.iban.com/structure
 * regex checker
 * http://ht5ifv.serprest.pt/extensions/tools/IBAN/index.html
 */
/**
 * Regex checkers
 */
export const IBAN_REGEX = /^AL\d{10}[0-9A-Z]{16}$|^AD\d{10}[0-9A-Z]{12}$|^AT\d{18}$|^BH\d{2}[A-Z]{4}[0-9A-Z]{14}$|^BE\d{14}$|^BA\d{18}$|^BG\d{2}[A-Z]{4}\d{6}[0-9A-Z]{8}$|^HR\d{19}$|^CY\d{10}[0-9A-Z]{16}$|^CZ\d{22}$|^DK\d{16}$|^FO\d{16}$|^GL\d{16}$|^DO\d{2}[0-9A-Z]{4}\d{20}$|^EE\d{18}$|^FI\d{16}$|^FR\d{12}[0-9A-Z]{11}\d{2}$|^GE\d{2}[A-Z]{2}\d{16}$|^DE\d{20}$|^GI\d{2}[A-Z]{4}[0-9A-Z]{15}$|^GR\d{9}[0-9A-Z]{16}$|^HU\d{26}$|^IS\d{24}$|^IE\d{2}[A-Z]{4}\d{14}$|^IL\d{21}$|^IT\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^[A-Z]{2}\d{5}[0-9A-Z]{13}$|^KW\d{2}[A-Z]{4}22!$|^LV\d{2}[A-Z]{4}[0-9A-Z]{13}$|^LB\d{6}[0-9A-Z]{20}$|^LI\d{7}[0-9A-Z]{12}$|^LT\d{18}$|^LU\d{5}[0-9A-Z]{13}$|^MK\d{5}[0-9A-Z]{10}\d{2}$|^MT\d{2}[A-Z]{4}\d{5}[0-9A-Z]{18}$|^MR13\d{23}$|^MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}$|^MC\d{12}[0-9A-Z]{11}\d{2}$|^ME\d{20}$|^NL\d{2}[A-Z]{4}\d{10}$|^NO\d{13}$|^PL\d{10}[0-9A-Z]{,16}n$|^PT\d{23}$|^RO\d{2}[A-Z]{4}[0-9A-Z]{16}$|^SM\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^SA\d{4}[0-9A-Z]{18}$|^RS\d{20}$|^SK\d{22}$|^SI\d{17}$|^ES\d{22}$|^SE\d{22}$|^CH\d{7}[0-9A-Z]{12}$|^TN59\d{20}$|^TR\d{7}[0-9A-Z]{17}$|^AE\d{21}$|^GB\d{2}[A-Z]{4}\d{14}$/;
export const DA_IBAN_REGEX = /^DK\d{16}$|^FO\d{16}$|^GL\d{16}$/;
/**
 * IBAN examples to for testing
 */
export const IBAN_EXAMPLES = {
  AD: 'AD1400080001001234567890',
  AE: 'AE460090000000123456789',
  AL: 'AL35202111090000000001234567',
  AT: 'AT483200000012345864',
  AZ: 'AZ96AZEJ00000000001234567890',
  BA: 'BA393385804800211234',
  BE: 'BE71096123456769',
  BG: 'BG18RZBB91550123456789',
  BH: 'BH02CITI00001077181611',
  BR: 'BR1500000000000010932840814P2',
  BY: 'BY86AKBB10100000002966000000',
  CH: 'CH5604835012345678009',
  CR: 'CR23015108410026012345',
  CY: 'CY21002001950000357001234567',
  CZ: 'CZ5508000000001234567899',
  DE: 'DE75512108001245126199',
  DK: 'DK9520000123456789',
  DO: 'DO22ACAU00000000000123456789',
  EE: 'EE471000001020145685',
  EG: 'EG800002000156789012345180002',
  ES: 'ES7921000813610123456789',
  FI: 'FI1410093000123458',
  FO: 'FO9264600123456789',
  FR: 'FR7630006000011234567890189',
  GB: 'GB33BUKB20201555555555',
  GE: 'GE60NB0000000123456789',
  GI: 'GI04BARC000001234567890',
  GL: 'GL8964710123456789',
  GR: 'GR9608100010000001234567890',
  GT: 'GT20AGRO00000000001234567890',
  HR: 'HR1723600001101234565',
  HU: 'HU93116000060000000012345676',
  IE: 'IE64IRCE92050112345678',
  IL: 'IL170108000000012612345',
  IQ: 'IQ20CBIQ861800101010500',
  IS: 'IS750001121234563108962099',
  IT: 'IT60X0542811101000000123456',
  JO: 'JO71CBJO0000000000001234567890',
  KW: 'KW81CBKU0000000000001234560101',
  KZ: 'KZ563190000012344567',
  LB: 'LB92000700000000123123456123',
  LC: 'LC14BOSL123456789012345678901234',
  LI: 'LI7408806123456789012',
  LT: 'LT601010012345678901',
  LU: 'LU120010001234567891',
  LV: 'LV97HABA0012345678910',
  MC: 'MC5810096180790123456789085',
  MD: 'MD21EX000000000001234567',
  ME: 'ME25505000012345678951',
  MK: 'MK07200002785123453',
  MR: 'MR1300020001010000123456753',
  MT: 'MT31MALT01100000000000000000123',
  MU: 'MU43BOMM0101123456789101000MUR',
  NL: 'NL02ABNA0123456789',
  NO: 'NO8330001234567',
  PK: 'PK36SCBL0000001123456702',
  PL: 'PL10105000997603123456789123',
  PS: 'PS92PALS000000000400123456702',
  PT: 'PT50002700000001234567833',
  QA: 'QA54QNBA000000000000693123456',
  RO: 'RO09BCYP0000001234567890',
  RS: 'RS35105008123123123173',
  SA: 'SA4420000001234567891234',
  SC: 'SC52BAHL01031234567890123456USD',
  SE: 'SE7280000810340009783242',
  SI: 'SI56192001234567892',
  SK: 'SK8975000000000012345671',
  SM: 'SM76P0854009812123456789123',
  ST: 'ST23000200000289355710148',
  SV: 'SV43ACAT00000000000000123123',
  TL: 'TL380010012345678910106',
  TN: 'TN5904018104004942712345',
  TR: 'TR320010009999901234567890',
  UA: 'UA903052992990004149123456789',
  VA: 'VA59001123000012345678',
  VG: 'VG21PACG0000000123456789',
  XK: 'XK051212012345678906',
};
