/**
 * Imports
 */
import {
  AuthPage,
  BankPage,
  BusinessActivityPage,
  BusinessStructurePage,
  CeoPage,
  CompanyDetailsPage,
  CompanyDocumentsPage,
  DevicePage,
  HomePage,
  OwnerPage,
  RegistrationNumberPage,
  RevenuePage,
} from 'features/signupflow';
import { LoginPage, LogoutPage } from 'features/user/pages';
import * as React from 'react';

/**
 * types
 */
export type RoutesType =
  | 'home'
  | 'devices'
  | 'auth'
  | 'registration-number'
  | 'company-details'
  | 'company-documents'
  | 'business-activity'
  | 'business-structure'
  | 'revenue'
  | 'bank'
  | 'ceo'
  | 'owner'
  | 'final'
  | 'logout'
  | 'login';

export type RouteLookup = {
  [k in RoutesType]: RouteType;
};

export type RouteType = {
  component: React.FC;
  dontCheckAuth?: boolean;
  isPrivate: boolean;
  pageId: RoutesType;
  path: string;
  route: string;
};

/**
 * ROUTES
 */
export const ROUTES: RouteLookup = {
  auth: {
    component: AuthPage,
    dontCheckAuth: true,
    isPrivate: false,
    pageId: 'auth',
    path: '/auth',
    route: '/auth',
  },
  bank: {
    component: BankPage,
    isPrivate: true,
    pageId: 'bank',
    path: '/bank',
    route: '/bank',
  },
  'business-activity': {
    component: BusinessActivityPage,
    isPrivate: true,
    pageId: 'business-activity',
    path: '/business-activity',
    route: '/business-activity',
  },
  'business-structure': {
    component: BusinessStructurePage,
    isPrivate: true,
    pageId: 'business-structure',
    path: '/business-structure',
    route: '/business-structure',
  },
  ceo: {
    component: CeoPage,
    isPrivate: true,
    pageId: 'ceo',
    path: '/ceo',
    route: '/ceo',
  },
  'company-details': {
    component: CompanyDetailsPage,
    isPrivate: true,
    pageId: 'company-details',
    path: '/company-details',
    route: '/company-details',
  },
  'company-documents': {
    component: CompanyDocumentsPage,
    isPrivate: true,
    pageId: 'company-documents',
    path: '/company-documents',
    route: '/company-documents',
  },
  devices: {
    component: DevicePage,
    isPrivate: true,
    pageId: 'devices',
    path: '/devices',
    route: '/devices',
  },
  final: {
    component: OwnerPage,
    isPrivate: true,
    pageId: 'final',
    path: '/final',
    route: '/final',
  },
  home: {
    component: HomePage,
    dontCheckAuth: true,
    isPrivate: false,
    pageId: 'home',
    path: '/',
    route: '/',
  },
  owner: {
    component: OwnerPage,
    isPrivate: true,
    pageId: 'owner',
    path: '/owner',
    route: '/owner',
  },
  'registration-number': {
    component: RegistrationNumberPage,
    isPrivate: true,
    pageId: 'registration-number',
    path: '/registration-number',
    route: '/registration-number',
  },
  revenue: {
    component: RevenuePage,
    isPrivate: true,
    pageId: 'revenue',
    path: '/revenue',
    route: '/revenue',
  },
  // Misc
  login: {
    component: LoginPage,
    isPrivate: false,
    pageId: 'login',
    path: '/login',
    route: '/login',
  },
  logout: {
    component: LogoutPage,
    dontCheckAuth: true,
    isPrivate: false,
    pageId: 'logout',
    path: '/logout',
    route: '/logout',
  },
};
