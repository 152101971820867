/**
 * Imports
 */
import { apiCreateCustomToken } from 'apis';
import { userSelector } from 'features/user/redux/selectors';
import { FB_COLLECTIONS_LOOKUP, getFirebaseInstance } from 'firebaseAPI';
import { flattenDeep } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { Firebase } from 'types/firebase';
import { CURRENCY_MAP, getFileExtension, UploadDocument } from 'utils';
import { OwnerFormData, OwnerFormRow } from '../components';
const firebase = getFirebaseInstance();

/**
 * Types
 */
export type MerchantAPIHanders = {
  onUpdateBankDetails: (
    data: Pick<
      Firebase.Merchant,
      'bankAccountNumber' | 'bankRegistrationNumber'
    >,
  ) => Promise<void>;
  onUpdateBusinessActivity: (
    data: Pick<Firebase.Merchant, 'businessActivityDescription' | 'website'>,
  ) => Promise<void>;
  onUpdateBusinessStructure: (
    data: Pick<Firebase.Merchant, 'businessStructure'>,
  ) => Promise<void>;
  onUpdateCeo: (data: Pick<Firebase.Merchant, 'ownerData'>) => Promise<void>;
  onUpdateCompanyDetails: (
    details: Pick<
      Firebase.Merchant,
      | 'addressLine1'
      | 'businessLegalName'
      | 'city'
      | 'companyRegistrationNumber'
      | 'merchantTradingName'
      | 'vatNumber'
      | 'zipCode'
    > &
      Pick<Firebase.Contact, 'contactEmail' | 'phone'>,
  ) => Promise<void>;
  onUpdateCompanyDocuments: (data: UploadDocument[]) => Promise<void>;
  onUpdateContactInformation: (
    data: Pick<Firebase.Contact, 'phone'>,
  ) => Promise<void>;
  onUpdateCountryCode: (
    data: Pick<Firebase.Merchant, 'countryCode'>,
  ) => Promise<void>;
  onUpdateDeviceType: (
    data: Pick<Firebase.Merchant, 'deviceType'>,
  ) => Promise<void>;
  onUpdateEstimatedRevenue: (
    data: Pick<Firebase.Merchant, 'monthlyTurnover' | 'merchantSegment'>,
  ) => Promise<void>;
  onUpdateIban: (data: Pick<Firebase.Merchant, 'iban'>) => Promise<void>;
  onUpdateRegistrationNumber: (
    data: Pick<Firebase.Merchant, 'companyRegistrationNumber'>,
  ) => Promise<void>;
  onUpdateMerchantVat: (
    data: Pick<
      Firebase.Merchant,
      | 'addressLine1'
      | 'companyRegistrationNumber'
      | 'merchantTradingName'
      | 'city'
      | 'zipCode'
    >,
  ) => Promise<void>;
  onUpdateOwners: (data: OwnerFormData) => Promise<void>;
  // Misc
  onCreateCustomToken: () => Promise<{ token: string }>;
  onFinishSignup: () => Promise<{ token: string }>;
};

export type MerchantAPIType = {
  handlers: MerchantAPIHanders;
};

/**
 * CONST
 */
const DOCUMENT_ROOT_FOLDER = 'onboarding_documents';
const ID_ROOT_FOLDER = 'onboarding_photo_ids';

/**
 * Merchant APi collection
 */
const useMerchantApi = (): MerchantAPIType => {
  /* Firebase */
  const firestore = useFirestore();
  const user = useSelector(userSelector);

  /**
   * Get path for file
   */
  const _getStoragePathForImage = (
    rootName: string,
    userId: string,
    fileIndex: number,
    ownerIndex: string,
    fileType: string,
  ) => {
    if (fileIndex === 0) {
      return `${rootName}/${userId}/owner_${ownerIndex}_photoId.${fileType}`;
    } else {
      return `${rootName}/${userId}/owner_${ownerIndex}_addressId.${fileType}`;
    }
  };
  /**
   * Get path for documents
   */
  const _getStoragePathForDocument = (
    rootName: string,
    userId: string,
    documentName: string,
    fileExtension: string,
  ) => {
    const timestamp = moment().format('DD.MM.YY_HH.mm');
    return `${rootName}/${userId}/${documentName}_${timestamp}.${fileExtension}`;
  };

  /**
   * Upload document
   */
  const _uploadDocument = async (file: File, documentName: string) => {
    const fileExtension = getFileExtension(file.type);
    const storagePath = _getStoragePathForDocument(
      DOCUMENT_ROOT_FOLDER,
      user.id,
      documentName,
      fileExtension,
    );
    const metadata = {
      cacheControl: 'public, max-age=900',
      contentType: file.type,
    };
    await firebase.storage().ref().child(storagePath).put(file, metadata);
    return storagePath;
  };

  /**
   * Upload file
   */
  const _uploadFile = async (
    file: File,
    fileIndex: number,
    ownerIndex: string,
  ) => {
    const fileExtension = getFileExtension(file.type);
    const storagePath = _getStoragePathForImage(
      ID_ROOT_FOLDER,
      user.id,
      fileIndex,
      ownerIndex,
      fileExtension,
    );
    const metadata = {
      cacheControl: 'public, max-age=900',
      contentType: file.type,
    };
    await firebase.storage().ref().child(storagePath).put(file, metadata);

    return storagePath;
  };

  const _getContactInfo = (phone: string, email?: string) => {
    const contactEmail = email ? email : firebase.auth().currentUser?.email;
    const fullName = firebase.auth().currentUser?.displayName;

    return { contactEmail, fullName, phone };
  };

  const handlers: MerchantAPIHanders = {
    /**
     * onCreateCustomToken
     */
    onCreateCustomToken: async () => {
      const resp = await apiCreateCustomToken();
      return resp;
    },

    /**
     * onFinishSignup
     */
    onFinishSignup: async () => {
      await firestore
        .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
        .doc(user.id)
        .update({
          onboardingDone: true,
          onboardedAt: Date.now(),
        });
      const resp = await apiCreateCustomToken();
      return resp;
    },
    /**
     * onUpdateBankDetails
     */
    onUpdateBankDetails: async (data) => {
      await firestore
        .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
        .doc(user.id)
        .update(data);
    },

    /**
     * onUpdateBusinessActivity
     */
    onUpdateBusinessActivity: async (data) => {
      await firestore
        .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
        .doc(user.id)
        .update(data);
    },

    /**
     * onUpdateBusinessStructure
     */
    onUpdateBusinessStructure: async (data) => {
      await firestore
        .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
        .doc(user.id)
        .update(data);
    },

    /**
     * onUpdateCeo
     */
    onUpdateCeo: async (data) => {
      await firestore
        .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
        .doc(user.id)
        .update(data);
    },

    /**
     * onUpdateCompanyDetails
     */
    onUpdateCompanyDetails: async (data) => {
      const extractKeys = ['contactEmail', 'phone'];
      const rest = Object.keys(data)
        .filter((key) => !extractKeys.includes(key))
        .reduce((obj, key) => {
          // @ts-ignore
          obj[key] = data[key];
          return obj;
        }, {});
      const contactFields = Object.keys(data)
        .filter((key) => extractKeys.includes(key))
        .reduce((obj, key) => {
          // @ts-ignore
          obj[key] = data[key];
          return obj;
        }, {} as { contactEmail: string; phone: string });
      const contact = _getContactInfo(contactFields.phone);

      await firestore
        .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
        .doc(user.id)
        .update(rest);
      await firestore
        .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
        .doc(user.id)
        .update({ contact });
    },
    /**
     * onUpdateCompanyDocuments
     */
    onUpdateCompanyDocuments: async (data) => {
      await Promise.all(
        data.map(async (doc) => {
          await _uploadDocument(doc.file, doc.name);
        }),
      );
      const folderPath = `${DOCUMENT_ROOT_FOLDER}/${user.id}/`;
      await firestore
        .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
        .doc(user.id)
        .update({
          companyDocumentsPath: folderPath,
        });
    },

    /**
     * onUpdateContactInformation
     */
    onUpdateContactInformation: async (data) => {
      const contact = _getContactInfo(data.phone);
      await firestore
        .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
        .doc(user.id)
        .update({ contact });
    },

    /**
     * onUpdateCountryCode
     * fallback if signup fails but user is created, set setOnboardingStarted values
     */
    onUpdateCountryCode: async (data) => {
      const currency = CURRENCY_MAP[data.countryCode];
      await firestore
        .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
        .doc(user.id)
        .update({
          countryCode: data.countryCode,
          currency,
          onboardingStartedAt: Date.now(),
        });
    },

    /**
     * onUpdateDeviceType
     */
    onUpdateDeviceType: async (data) => {
      await firestore
        .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
        .doc(user.id)
        .update(data);
    },

    /**
     * onUpdateEstimatedRevenue
     */
    onUpdateEstimatedRevenue: async (data) => {
      await firestore
        .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
        .doc(user.id)
        .update(data);
    },
    /**
     * onUpdateIban
     */
    onUpdateIban: async (data) => {
      await firestore
        .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
        .doc(user.id)
        .update(data);
    },
    /**
     * onUpdateRegistrationNumber
     */
    onUpdateRegistrationNumber: async (data) => {
      await firestore
        .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
        .doc(user.id)
        .update(data);
    },
    /**
     * onUpdateMerchantVat
     */
    onUpdateMerchantVat: async (data) => {
      const dataFields = {
        ...data,
        businessLegalName: data.merchantTradingName,
      };
      await firestore
        .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
        .doc(user.id)
        .update(dataFields);
    },

    /**
     * onUpdateOwners
     */
    onUpdateOwners: async (data: OwnerFormData) => {
      const storagePaths = await Promise.all(
        data.owners.map(async (owner, ownerIndex) => {
          const photoIds = await Promise.all(
            owner.photoId.fileList.map(
              async (file, fileListIndex) =>
                await _uploadFile(
                  file.originFileObj as File,
                  0,
                  `${ownerIndex}_${fileListIndex}`,
                ),
            ),
          );
          const addressIds = await Promise.all(
            owner.addressId.fileList.map(
              async (file, fileListIndex) =>
                await _uploadFile(
                  file.originFileObj as File,
                  1,
                  `${ownerIndex}_${fileListIndex}`,
                ),
            ),
          );
          return [photoIds, addressIds];
        }),
      );

      const ownerData: Firebase.Owner[] = data.owners.map(
        (owner: OwnerFormRow, ownerIndex) => {
          const fileMap = flattenDeep(storagePaths[ownerIndex]).reduce(
            (acc, item, index) => {
              return { ...acc, [index]: item };
            },
            {},
          );
          return {
            name: owner.name,
            fileUrl: fileMap,
            photoId: null,
            roleDirector: ownerIndex === 0 ? true : false,
            socialSecurityNumber: '',
            addressId: null,
          };
        },
      );
      if (!ownerData || ownerData.length === 0) {
        throw new Error('Failed to upload ownerdata');
      }
      await firestore
        .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
        .doc(user.id)
        .update({
          ownerData,
        });
    },
  };

  return { handlers };
};

/**
 * Exports
 */
export { useMerchantApi };
