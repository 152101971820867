/**
 * Imports
 */
import { TableProps as AntdTableProps } from 'antd/lib/table';
import { Table as AntdTable } from 'antd';
import * as React from 'react';

import { styled } from 'theme';

/**
 * Styling
 */
const Root = styled.div`
  & .ant-table-thead {
    background-color: ${(props) => props.theme.colors.Background.White};
  }
  & .ant-table-thead .ant-table-cell {
    color: ${(props) => props.theme.colors.Text.Label};
    font-size: 13px;
  }
  & .ant-table-cell {
    cursor: pointer;
  }
`;
/**
 * Types
 */
export type TableTProps<T> = AntdTableProps<T> & {
  color?: string;
};

/**
 * Table component
 */
function Table<T>({ ...props }: TableTProps<T>): JSX.Element {
  return (
    <Root>
      <AntdTable<any> {...props} />
    </Root>
  );
}

/**
 * Exports
 */
export { Table };
