/**
 * Imports
 */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Divider,
  Input,
  Label,
  Snackbar,
  Text,
  VibrantIcon,
} from 'components';
import {
  NextButton,
  SignupText,
  useMerchantApi,
  useSignupFlow,
} from 'features/signupflow';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { styled } from 'theme';
import { Firebase } from 'types/firebase';
import { object, SchemaOf, string } from 'yup';
import { IBAN_REGEX } from './iban-examples';

/**
 * Helper functions
 */
const removeWhitespaceFromString = (stringToRemoveWhitespace: string) => {
  const noWhiteSpaceRegex = /\s/g;
  return stringToRemoveWhitespace?.replaceAll(noWhiteSpaceRegex, '');
};

/**
 * Styling
 */

const StyledButton = styled.div`
  cursor: pointer;
  display: inline;

  text-decoration: underline;
  & svg {
    margin-left: 4px;
  }
`;
const StyledLink = styled.a`
  color: ${(props) => props.theme.colors.Text.UI}!important;
  transition: ${(props) => props.theme.transitions.HoverTextColor};
  margin-right: auto;
  max-width: fit-content;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:hover {
    transition: color 0.2s ease;
    color: ${(props) => props.theme.colors.Shades.Orange700};
  }
`;
/**
 * Types
 */
export interface BankFormProps {}

export type BankData = Pick<
  Firebase.SoftPosOnboarding,
  'bankAccountNumber' | 'bankRegistrationNumber' | 'iban'
>;

/* @ts-ignore */
const BankDataSchema: SchemaOf<BankData> = object().shape(
  {
    bankAccountNumber: string().when('iban', {
      is: (iban: string) => {
        return iban?.length > 0;
      },
      then: string().nullable(true),
      otherwise: string().trim().min(1),
    }),
    bankRegistrationNumber: string().when('iban', {
      is: (iban: string) => {
        return iban?.length > 0;
      },
      then: string().nullable(true),
      otherwise: string().trim().min(4),
    }),
    iban: string().when('bankRegistrationNumber', {
      is: (bankRegistrationNumber: string) => {
        return bankRegistrationNumber?.length > 0;
      },
      then: string().nullable(true),
      otherwise: () => {
        return string()
          .transform((value) => removeWhitespaceFromString(value as string))
          .uppercase()
          .matches(IBAN_REGEX, 'Is not in correct format')
          .required();
      },
    }),
  },
  // set index to order the dependency of the check
  [['bankRegistrationNumber', 'iban']],
);

/**
 * BankForm component
 */
const BankForm: React.FC<BankFormProps> = (_props) => {
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  /* Used for forcing IBAN to be correct */
  const [validIban, setValidIban] = React.useState(false);
  const { t } = useTranslation();
  const {
    handlers: { onUpdateIban, onUpdateBankDetails },
  } = useMerchantApi();
  const {
    handlers: { onNextStep },
  } = useSignupFlow();

  const { formState, control, handleSubmit, clearErrors } = useForm<BankData>({
    mode: 'onChange',
    resolver: yupResolver(BankDataSchema),
  });
  const { isValid, errors, isSubmitting, touchedFields } = formState;

  const _onSubmit = handleSubmit(
    async (data) => {
      if (data.iban) {
        await onUpdateIban({ iban: removeWhitespaceFromString(data.iban) });
      } else {
        await onUpdateBankDetails({
          bankAccountNumber: data.bankAccountNumber,
          bankRegistrationNumber: data.bankRegistrationNumber,
        });
      }
      onNextStep();
    },
    ({ bankAccountNumber, bankRegistrationNumber, iban }) => {
      console.log('error', { bankAccountNumber, bankRegistrationNumber, iban });
    },
  );

  const _forceSubmit = () => {
    setValidIban(true);
    clearErrors();
  };

  React.useEffect(() => {
    // Clear errors if one of the options to enter details is valid
    if (formState.isValid) {
      clearErrors();
    }
  }, [formState.isValid]);

  return (
    <form onSubmit={_onSubmit}>
      <SignupText subheading={<Trans i18nKey="BankStep.bank_form.heading" />} />
      <Box width="auto" mt="-16px">
        <StyledLink
          href="https://vibrant-help.zendesk.com/hc/en-us/articles/4417185693841-How-to-locate-your-IBAN-in-top-4-Danish-banks"
          target="_blank"
        >
          <Trans i18nKey="BankStep.bank_form.subtext_button" />
          <Box ml="8px" />
          <VibrantIcon icon="arrow-long-right" color="Grey500" />
        </StyledLink>
      </Box>
      <Box mt="16px" />
      <Label text={t('BankStep.bank_form.iban_label')} />
      <Controller
        name="iban"
        defaultValue={onboardingCollection?.iban}
        control={control}
        render={({ field }) => (
          <>
            {/* @ts-ignore */}
            <Input
              placeholder={t('BankStep.bank_form.iban_placeholder')}
              {...field}
            />
            {errors.iban && touchedFields.iban && (
              <>
                <Box mt="8px" />
                <Snackbar type="warning">
                  <Trans i18nKey="BankStep.iban_form.warning_msg">
                    <StyledButton onClick={_forceSubmit} />
                  </Trans>
                </Snackbar>
              </>
            )}
          </>
        )}
      />

      <Divider whiteSpace="lg" />

      <Text size="h5">
        <Trans i18nKey="BankStep.bank_form.heading_b" />
      </Text>
      <Box mt="16px" />
      <Label text={t('BankStep.bank_form.reg_label')} />
      <Controller
        name="bankRegistrationNumber"
        defaultValue={onboardingCollection?.bankRegistrationNumber}
        control={control}
        render={({ field }) => (
          <>
            {/* @ts-ignore */}
            <Input
              placeholder={t('BankStep.bank_form.reg_placeholder')}
              {...field}
            />
            {errors.bankRegistrationNumber &&
              touchedFields.bankRegistrationNumber && (
                <>
                  <Box mt="4px" />
                  <Text size="ui3" color="warning">
                    {t('BankStep.bank_form.reg_err_msg')}
                  </Text>
                </>
              )}
          </>
        )}
      />
      <Box mt="16px" />
      <Label text={t('BankStep.bank_form.account_label')} />
      <Controller
        name="bankAccountNumber"
        defaultValue={onboardingCollection?.bankAccountNumber}
        control={control}
        render={({ field }) => (
          <>
            {/* @ts-ignore */}
            <Input
              placeholder={t('BankStep.bank_form.account_placeholder')}
              {...field}
            />
            {errors.bankAccountNumber && touchedFields.bankAccountNumber && (
              <>
                <Box mt="4px" />
                <Text size="ui3" color="warning">
                  {t('BankStep.bank_form.account_err_msg')}
                </Text>
              </>
            )}
          </>
        )}
      />
      <Box mt="24px" />
      <NextButton
        loading={isSubmitting}
        onClick={_onSubmit}
        disabled={!(isValid || validIban)}
      />
    </form>
  );
};

/**
 * Exports
 */
export { BankForm };
