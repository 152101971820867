/**
 * Imports
 */
import * as React from 'react';
import { Trans } from 'react-i18next';
import { ROUTES, RoutesType } from 'router';

/**
 * Types
 */
export type SignupStepType = {
  pageName: () => string | React.ReactNode;
  pageSubheading: () => string | React.ReactNode;
  pageSubheadingAlt?: () => string | React.ReactNode;
  pageTime: () => string | React.ReactNode;
  path: any;
  signupStep: number;
};

export type SignupStepLookup = {
  [k in RoutesType]: SignupStepType;
};

/**
 * Signupflow A - DK
 */
export const SIGNUP_FLOW_A: SignupStepLookup = {
  home: {
    pageName: () => <Trans i18nKey="Breadcrumb.country" />,
    pageSubheading: () => '',
    pageTime: () => '',
    path: ROUTES.home.path,
    signupStep: 0,
  },
  auth: {
    pageName: () => <Trans i18nKey="Breadcrumb.auth" />,
    pageSubheading: () => <Trans i18nKey="AuthStep.subheading" />,
    pageTime: () => <Trans i18nKey="Breadcrumb.auth_time" />,
    path: ROUTES.auth.path,
    signupStep: 1,
  },
  'registration-number': {
    pageName: () => <Trans i18nKey="Breadcrumb.vat" />,
    pageSubheading: () => (
      <Trans i18nKey="RegistrationNumberStep.cvr.subheading" />
    ),
    pageTime: () => <Trans i18nKey="Breadcrumb.vat_time" />,
    path: ROUTES['registration-number'].path,
    signupStep: 2,
  },
  'company-details': {
    pageName: () => <Trans i18nKey="Breadcrumb.company_details" />,
    pageSubheading: () => (
      <Trans i18nKey="CompanyDetails.phone_step.subheading" />
    ),
    pageTime: () => <Trans i18nKey="Breadcrumb.company_details_time" />,
    path: ROUTES['company-details'].path,
    signupStep: 3,
  },
  'business-activity': {
    pageName: () => <Trans i18nKey="Breadcrumb.business_activity" />,
    pageSubheading: () => <Trans i18nKey="BusinessActivityStep.subheading" />,
    pageTime: () => <Trans i18nKey="Breadcrumb.business_activity_time" />,
    path: ROUTES['business-activity'].path,
    signupStep: 4,
  },
  revenue: {
    pageName: () => <Trans i18nKey="Breadcrumb.revenue" />,
    pageSubheading: () => <Trans i18nKey="RevenueStep.subheading" />,
    pageTime: () => <Trans i18nKey="Breadcrumb.revenue_time" />,
    path: ROUTES.revenue.path,
    signupStep: 5,
  },
  bank: {
    pageName: () => <Trans i18nKey="Breadcrumb.bank" />,
    pageSubheading: () => <Trans i18nKey="BankStep.iban_form.subheading" />,
    pageTime: () => <Trans i18nKey="Breadcrumb.bank_time" />,
    path: ROUTES.bank.path,
    signupStep: 6,
  },
  ceo: {
    pageName: () => <Trans i18nKey="Breadcrumb.ceo" />,
    pageSubheading: () => <Trans i18nKey="CeoStep.subheading" />,
    pageTime: () => <Trans i18nKey="Breadcrumb.ceo_time" />,
    path: ROUTES.ceo.path,
    signupStep: 7,
  },
  owner: {
    pageName: () => <Trans i18nKey="Breadcrumb.owner" />,
    pageSubheading: () => <Trans i18nKey="OwnerStep.subheading" />,
    pageTime: () => <Trans i18nKey="Breadcrumb.owner_time" />,
    path: ROUTES.owner.path,
    signupStep: 8,
  },
  final: {
    pageName: () => <Trans i18nKey="Breadcrumb.final" />,
    pageSubheading: () => <Trans i18nKey="OwnerStep.subheading_done" />,
    pageTime: () => '',
    path: ROUTES.final.path,
    signupStep: 9,
  },
  // Misc
  'company-documents': {
    pageName: () => '',
    pageSubheading: () => '',
    pageTime: () => '',
    path: ROUTES.home.path,
    signupStep: -1,
  },
  'business-structure': {
    pageName: () => '',
    pageSubheading: () => '',
    pageTime: () => '',
    path: ROUTES.home.path,
    signupStep: -1,
  },
  devices: {
    pageName: () => <Trans i18nKey="Breadcrumb.device" />,
    pageSubheading: () => <Trans i18nKey="DeviceStep.subheading" />,
    pageTime: () => <Trans i18nKey="Breadcrumb.device_time" />,
    path: ROUTES.devices.path,
    signupStep: -1,
  },
  login: {
    pageName: () => '',
    pageSubheading: () => '',
    pageTime: () => '',
    path: ROUTES.login.path,
    signupStep: -1,
  },
  logout: {
    pageName: () => '',
    pageSubheading: () => '',
    pageTime: () => '',
    path: ROUTES.login.path,
    signupStep: -1,
  },
};

/**
 * Signupflow B
 */
export const SIGNUP_FLOW_B: SignupStepLookup = {
  home: {
    pageName: () => <Trans i18nKey="Breadcrumb.country" />,
    pageSubheading: () => '',
    pageTime: () => '',
    path: ROUTES.home.path,
    signupStep: 0,
  },
  auth: {
    pageName: () => <Trans i18nKey="Breadcrumb.auth" />,
    pageSubheading: () => <Trans i18nKey="AuthStep.subheading" />,
    pageTime: () => <Trans i18nKey="Breadcrumb.auth_time" />,
    path: ROUTES.auth.path,
    signupStep: 1,
  },
  'business-structure': {
    pageName: () => <Trans i18nKey="Breadcrumb.business_structure" />,
    pageSubheading: () => <Trans i18nKey="BusinessStructureStep.subheading" />,
    pageTime: () => <Trans i18nKey="Breadcrumb.business_structure_time" />,
    path: ROUTES['business-structure'].path,
    signupStep: 2,
  },
  'registration-number': {
    pageName: () => <Trans i18nKey="Breadcrumb.registration_number" />,
    pageSubheading: () => (
      <Trans i18nKey="RegistrationNumberStep.limited_liability.subheading" />
    ),
    pageSubheadingAlt: () => (
      <Trans i18nKey="RegistrationNumberStep.sole_trader.subheading" />
    ),
    pageTime: () => <Trans i18nKey="Breadcrumb.registration_number_time" />,
    path: ROUTES['registration-number'].path,
    signupStep: 3,
  },
  'company-details': {
    pageName: () => <Trans i18nKey="Breadcrumb.company_details" />,
    pageSubheading: () => <Trans i18nKey="CompanyDetails.subheading" />,
    pageTime: () => <Trans i18nKey="Breadcrumb.company_details_time" />,
    path: ROUTES['company-details'].path,
    signupStep: 4,
  },
  'company-documents': {
    pageName: () => <Trans i18nKey="Breadcrumb.company_documents" />,
    pageSubheading: () => (
      <Trans i18nKey="CompanyDocuments.limited_liability.subheading" />
    ),
    pageSubheadingAlt: () => (
      <Trans i18nKey="CompanyDocuments.sole_trader.subheading" />
    ),
    pageTime: () => <Trans i18nKey="Breadcrumb.company_documents_time" />,
    path: ROUTES['company-documents'].path,
    signupStep: 5,
  },
  'business-activity': {
    pageName: () => <Trans i18nKey="Breadcrumb.business_activity" />,
    pageSubheading: () => <Trans i18nKey="BusinessActivityStep.subheading" />,
    pageTime: () => <Trans i18nKey="Breadcrumb.business_activity_time" />,
    path: ROUTES['business-activity'].path,
    signupStep: 6,
  },
  revenue: {
    pageName: () => <Trans i18nKey="Breadcrumb.revenue" />,
    pageSubheading: () => <Trans i18nKey="RevenueStep.subheading" />,
    pageTime: () => <Trans i18nKey="Breadcrumb.revenue_time" />,
    path: ROUTES.revenue.path,
    signupStep: 7,
  },
  bank: {
    pageName: () => <Trans i18nKey="Breadcrumb.bank" />,
    pageSubheading: () => <Trans i18nKey="BankStep.iban_form.subheading" />,
    pageTime: () => <Trans i18nKey="Breadcrumb.bank_time" />,
    path: ROUTES.bank.path,
    signupStep: 8,
  },
  ceo: {
    pageName: () => <Trans i18nKey="Breadcrumb.ceo" />,
    pageSubheading: () => <Trans i18nKey="CeoStep.subheading" />,
    pageTime: () => <Trans i18nKey="Breadcrumb.ceo_time" />,
    path: ROUTES.ceo.path,
    signupStep: 9,
  },
  owner: {
    pageName: () => <Trans i18nKey="Breadcrumb.owner" />,
    pageSubheading: () => <Trans i18nKey="OwnerStep.subheading" />,
    pageTime: () => <Trans i18nKey="Breadcrumb.owner_time" />,
    path: ROUTES.owner.path,
    signupStep: 10,
  },
  final: {
    pageName: () => <Trans i18nKey="Breadcrumb.final" />,
    pageSubheading: () => <Trans i18nKey="OwnerStep.subheading_done" />,
    pageTime: () => '',
    path: ROUTES.final.path,
    signupStep: 11,
  },
  devices: {
    pageName: () => '',
    pageSubheading: () => '',
    pageTime: () => '',
    path: ROUTES.home.path,
    signupStep: -1,
  },
  login: {
    pageName: () => '',
    pageSubheading: () => '',
    pageTime: () => '',
    path: ROUTES.login.path,
    signupStep: -1,
  },
  logout: {
    pageName: () => '',
    pageSubheading: () => '',
    pageTime: () => '',
    path: ROUTES.login.path,
    signupStep: -1,
  },
};
