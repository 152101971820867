/**
 * Imports
 */
import { createSelector } from '@reduxjs/toolkit';

import { VibrantlyState } from 'store/types';
import { SignupState } from './reducer';

/**
 * Selectors
 */
export const rootSelector = (state: VibrantlyState): SignupState =>
  state.signupflow;

export const stepSelector = createSelector([rootSelector], (root) => root.step);
export const vatDataSelector = createSelector(
  [rootSelector],
  (root) => root.vatData,
);
export const vatErrorSelector = createSelector(
  [rootSelector],
  (root) => root.vatError,
);
export const temporaryDisplayNameSelector = createSelector(
  [rootSelector],
  (root) => root.displayName,
);
export const countryCodeSelector = createSelector(
  [rootSelector],
  (root) => root.countryCode,
);
export const signupInititatedSelector = createSelector(
  [rootSelector],
  (root) => root.signupInititated,
);
