/**
 * Imports
 */
import { Spinner } from 'components';
import * as React from 'react';
import { styled, VibrantColors } from 'theme';

/**
 * Styling
 */
const Root = styled.div`
  background-color: ${VibrantColors.Background.White};
  min-height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

/**
 * Types
 */
export interface SplashScreenProps {}

/**
 * SplashScreen component
 */
const SplashScreen: React.FC<SplashScreenProps> = (_props) => {
  return (
    <Root>
      <Spinner showSpinner size={48} />
    </Root>
  );
};

/**
 * Exports
 */
export { SplashScreen };
