/**
 * Imports
 */
import { currentRouteSelector } from 'features/navigation/redux/selectors';
import { find } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  SignupStepLookup,
  SignupStepType,
  SIGNUP_FLOW_A,
  SIGNUP_FLOW_B,
} from 'router';
import { countryCodeSelector } from '../redux/selectors';

/**
 * Types
 */
export type useSignupFlowAPIHandlers = {
  onNextStep: () => Promise<void>;
};
export type useSignupFlowAPIType = {
  lastStep: number;
  currentStep: SignupStepType;
  signupSteps: SignupStepLookup;
  handlers: useSignupFlowAPIHandlers;
};

/**
 * useSignupFlow
 */
const useSignupFlow = (): useSignupFlowAPIType => {
  const navigation = useHistory();
  const currentRoute = useSelector(currentRouteSelector);
  const countryCode = useSelector(countryCodeSelector);

  const signupSteps = React.useMemo(() => {
    return countryCode !== 'ES' ? SIGNUP_FLOW_A : SIGNUP_FLOW_B;
  }, [countryCode]);

  const lastStep = React.useMemo(() => {
    return Object.values(signupSteps).filter((step) => step.signupStep !== -1)
      .length;
  }, [signupSteps]);

  const currentStep = React.useMemo(() => {
    return signupSteps[currentRoute.pageId!];
  }, [currentRoute.pageId, signupSteps]);

  const handlers: useSignupFlowAPIHandlers = {
    /**
     * onNextStep
     */
    onNextStep: async () => {
      const nextStep = find(signupSteps, {
        signupStep: currentStep.signupStep + 1,
      });
      nextStep && navigation.push(nextStep.path);
    },
  };

  return { handlers, signupSteps, currentStep, lastStep };
};

/**
 * Exports
 */
export { useSignupFlow };
