/**
 * imports
 */
import i18n from 'i18next';
import se from './se.json';
import da from './da.json';
import en from './en.json';
import es from './es.json';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
/**
 * Exports
 */
export const resources = {
  se,
  da,
  en,
  es,
} as const;
/**
 * Localization setup
 */
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    react: {
      bindI18n: 'loaded languageChanged',
      bindI18nStore: 'added',
      useSuspense: true,
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'da', 'es', 'se'],
    resources,
    ns: ['text'], // have a common namespace used around the full app
    defaultNS: 'text',
  });

export default i18n;
