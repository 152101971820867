/**
 * Imports
 */
import { maybeUserSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Text } from 'components';
import { Trans } from 'react-i18next';

/**
 * Types
 */
export interface LoggedInAsProps {}

/**
 * LoggedInAs component
 */
const LoggedInAs: React.FC<LoggedInAsProps> = (_props) => {
  const user = useSelector(maybeUserSelector);

  if (!user) {
    return null;
  }
  return (
    <Text size="p2">
      <Trans
        i18nKey="AuthStep.already_logged_in.text"
        values={{ email: user.email }}
      >
        <b />
      </Trans>
    </Text>
  );
};

/**
 * Exports
 */
export { LoggedInAs };
