/**
 * Notes
 * https://stackoverflow.com/questions/42217121/how-to-start-search-only-when-user-stops-typing
 */
/**
 * Imports
 */
import { apiSearchCvr } from 'apis';
import { Input } from 'components';
import { enterVatInfo, vatError } from 'features/signupflow/redux/actions';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
const { Search } = Input;

/**
 * Types
 */
export interface VatSearchFieldProps {}

/**
 * VatSearchField component
 */
const VatSearchField: React.FC<VatSearchFieldProps> = (_props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsloading] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');

  const _search = async () => {
    setIsloading(true);
    try {
      // const resp = await apiGetVatInfo(searchTerm, true);
      const resp = await apiSearchCvr(searchTerm, true);
      dispatch(enterVatInfo(resp));
      setIsloading(false);
    } catch (error) {
      if (error.message) {
        setIsloading(false);
        dispatch(vatError(error.message));
      }
    }
  };

  React.useEffect(() => {
    // The debounce function delays the processing of the keyup event until the user has stopped typing
    // for a predetermined amount of time, to avoid unnecessary requests
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length > 0) {
        _search();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);
  return (
    <Search
      allowClear
      loading={isLoading}
      placeholder={t('RegistrationNumberStep.cvr.input_placeholder')}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  );
};

/**
 * Exports
 */
export { VatSearchField };
