/**
 * Imports
 */
import { createAction } from '@reduxjs/toolkit';
import { VibrantlyAction } from 'store/types';
import { EnterVatErrorPayload, EnterVatInfoPayload } from '../types';

/**
 * Constants
 */
const PREFIX = 'signupflow';

/**
 * SIGNUP_INITIATED
 */
export const SIGNUP_INITIATED = `${PREFIX}/SIGNUP_INITIATED`;
export type SignupInitiatedAction = VibrantlyAction<{
  signupInititated: boolean;
}>;
export const signupInititated = createAction<SignupInitiatedAction['payload']>(
  SIGNUP_INITIATED,
);
/**
 * SET_SIGNUP_COUNTRY
 */
export const SET_SIGNUP_COUNTRY = `${PREFIX}/SET_SIGNUP_COUNTRY`;
export type SetSignupCountryTypeAction = VibrantlyAction<{
  countryCode: string;
}>;
export const setcountryCode = createAction<
  SetSignupCountryTypeAction['payload']
>(SET_SIGNUP_COUNTRY);
/**
 * SET_DISPLAY_NAME
 * Used to set a temporary display name when using email signup
 */
export const SET_DISPLAY_NAME = `${PREFIX}/SET_DISPLAY_NAME`;
export type SetDisplayNameAction = VibrantlyAction<{ displayName: string }>;
export const setDisplayName = createAction<SetDisplayNameAction['payload']>(
  SET_DISPLAY_NAME,
);
/**
 * ENTER_VAT_INFO
 */
export const ENTER_VAT_INFO = `${PREFIX}/ENTER_VAT_INFO`;
export type EnterVatInfoAction = VibrantlyAction<EnterVatInfoPayload>;
export const enterVatInfo = createAction<EnterVatInfoAction['payload']>(
  ENTER_VAT_INFO,
);
/**
 * VAT_ERROR
 */
export const VAT_ERROR = `${PREFIX}/VAT_ERROR`;
// @ts-ignore
export type EnterVatErrorAction = VibrantlyAction<EnterVatErrorPayload>;
export const vatError = createAction<EnterVatErrorAction['payload']>(VAT_ERROR);
