/**
 * Imports
 */
import { SelectValue } from 'antd/lib/select';
import { Anchor, Select, Space, SubText } from 'components';
import { NextButton, SignupText, useMerchantApi } from 'features/signupflow';
import {
  setcountryCode,
  signupInititated,
} from 'features/signupflow/redux/actions';
import { countryCodeSelector } from 'features/signupflow/redux/selectors';
import { maybeUserSelector } from 'features/user/redux/selectors';
import * as React from 'react';
// @ts-ignore
import ReactCountryFlag from 'react-country-flag';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ROUTES } from 'router';
import { styled } from 'theme';
import { Firebase } from 'types/firebase';
/**
 *Styling
 */

const StyledSelect = styled(Select)`
  && {
    min-width: 100%;
    .ant-select-selector .ant-select-selection-search-input {
      height: 42px;
    }
    .ant-select-arrow svg {
      fill: ${({ theme }) => theme.colors.Text.UI};
    }
  }
`;
const StyledOption = styled(Select.Option)``;

/**
 * CONST
 */
const COUNTRY_CODES: {
  [key in Firebase.CountryCode]: { name: () => React.ReactNode };
} = {
  DK: {
    name: () => 'Danmark',
  },
  SE: {
    name: () => 'Sverige',
  },
  ES: {
    name: () => <Trans i18nKey="CountryStep.countries.spain" />,
  },
};

/**
 * CountryStep component
 */
const CountryStep: React.FC = () => {
  const navigation = useHistory();
  const dispatch = useDispatch();
  const countryCode = useSelector(countryCodeSelector);
  const user = useSelector(maybeUserSelector);
  const {
    handlers: { onUpdateCountryCode },
  } = useMerchantApi();
  const [selecedValue, setSelectedValue] = React.useState<null | string>(null);
  /* In-use variable */
  const hasValue = !!(selecedValue || !!countryCode);
  const selectValue = countryCode || selecedValue || undefined;

  const _next = async () => {
    if (user && countryCode) {
      await onUpdateCountryCode({
        countryCode: countryCode as Firebase.CountryCode,
      });
      navigation.push(ROUTES.auth.path);
    } else {
      navigation.push(ROUTES.auth.path);
    }
  };

  const _onChange = (countryCode: SelectValue) => {
    setSelectedValue(countryCode as string);
    dispatch(setcountryCode({ countryCode: countryCode as string }));
    dispatch(signupInititated({ signupInititated: true }));
  };

  const _detechCountry = () => {
    const lang = navigator.language.split('-')[1];
    const preselect = lang && Object.keys(COUNTRY_CODES).includes(lang);
    if (preselect) {
      setSelectedValue(lang);
      dispatch(setcountryCode({ countryCode: lang }));
      dispatch(signupInititated({ signupInititated: true }));
    }
  };

  React.useEffect(() => {
    _detechCountry();
  }, []);

  return (
    <>
      <SignupText subheading={<Trans i18nKey="CountryStep.heading" />} />
      <StyledSelect
        placeholder={<Trans i18nKey="CountryStep.select_placeholder" />}
        value={selectValue}
        onChange={(countryCode: SelectValue) => _onChange(countryCode)}
      >
        {Object.entries(COUNTRY_CODES).map((c) => {
          return (
            <StyledOption key={c[0]} value={c[0]}>
              <ReactCountryFlag
                style={{
                  fontSize: '18px',
                  lineHeight: '18px',
                  marginRight: '8px',
                  marginTop: '-4px',
                }}
                countryCode={c[0]}
                svg
              />
              {c[1].name()}
            </StyledOption>
          );
        })}
      </StyledSelect>
      <Space />
      <NextButton onClick={_next} disabled={!hasValue} />
      <Space size="lg" />
      <SubText
        text={
          <Trans i18nKey="CountryStep.cta_text">
            <Anchor size="ui3" href="mailto:support@vibrant.io" color="brand" />
          </Trans>
        }
      />
    </>
  );
};

/**
 * Exports
 */
export { CountryStep };
