import axios from 'axios';
import { PROJECT_API_URL } from 'config';
import { getIdToken } from 'firebaseAPI';
import { VibrantAPI } from 'types/vibrant';
import { captureError } from 'utils';

/**
 * Get a merchant token
 */
const apiCreateCustomToken = async (): Promise<{ token: string }> => {
  try {
    const idToken = await getIdToken();
    const resp = (await axios.get(
      `${PROJECT_API_URL}/api/v1/merchant/createCustomToken`,
      {
        headers: { token: idToken },
      },
    )) as VibrantAPI.GetMerchantTokenResponse;
    if (!resp.data.success) {
      throw new Error('Failed to fecth merchant token');
    } else {
      return resp.data.result;
    }
  } catch (exception) {
    captureError('Failed to fecth merchant token', exception);
    return exception;
  }
};

/**
 * Exports
 */
export { apiCreateCustomToken };
