/**
 * Imports
 */
import * as React from 'react';

import { styled } from 'theme';

/**
 * Styling
 */
const VERTICAL_SPACE_SIZE: WhiteSpaceLookup = {
  xl: 21,
  lg: 16,
  md: 12,
  sm: 6,
  xs: 4,
};
const HORIZENTAL_SPACING_SIZE: WhiteSpaceLookup = {
  xl: 32,
  lg: 24,
  md: 16,
  sm: 12,
  xs: 8,
};
const StyledSapce = styled.div<{ size: WhiteSpaceSizes; vertical?: boolean }>`
  padding: ${(props) => !props.vertical && VERTICAL_SPACE_SIZE[props.size]}px 0;
  width: ${(props) => props.vertical && HORIZENTAL_SPACING_SIZE[props.size]}px;
`;
/**
 * Types
 */
type WhiteSpaceLookup = {
  [key in WhiteSpaceSizes]: number;
};
type WhiteSpaceSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export interface SpaceProps {
  size?: WhiteSpaceSizes;
  vertical?: boolean;
}

/**
 * Space component
 */
const Space: React.FC<SpaceProps> = ({ size, vertical }) => {
  return <StyledSapce size={size ? size : 'md'} vertical={vertical} />;
};

/**
 * Exports
 */
export { Space };
