/**
 * Imports
 */
import { Form } from 'antd';
import { Input } from 'components';
import { NextButton, SignupText } from 'features/signupflow';
import { useMerchantApi, useSignupFlow } from 'features/signupflow/api';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Firebase } from 'types/firebase';
import { captureError, ONBOARDING_FIELDS } from 'utils';

const { TextArea } = Input;

/**
 * Types
 */
export type BusinessKYCDataType = Pick<
  Firebase.Merchant,
  'businessActivityDescription' | 'website'
>;

/**
 * BusinessActivityStep component
 */
const BusinessActivityStep: React.FC = () => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  const {
    handlers: { onUpdateBusinessActivity },
  } = useMerchantApi();
  const {
    handlers: { onNextStep },
  } = useSignupFlow();
  /* Local state */
  const [isNoteValid, setNoteIsValid] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  /* In-use variables */
  const hasDescription = !!onboardingCollection?.businessActivityDescription;
  const hasWebsite = !!onboardingCollection?.website;
  const isDisabled =
    (!hasDescription && !isNoteValid) || (hasDescription && !isNoteValid);

  const _onChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoteIsValid(e.target.value.length > 1 ? true : false);
  };

  const _onFinish = async (values: BusinessKYCDataType) => {
    setIsLoading(true);
    try {
      await onUpdateBusinessActivity({
        businessActivityDescription: values.businessActivityDescription,
        website: values?.website ? values.website : 'N/A',
      });
      setIsLoading(false);
      onNextStep();
    } catch (exception) {
      setIsLoading(false);
      captureError('Error: Failed to update business information', exception);
    }
  };

  React.useEffect(() => {
    if (hasDescription) {
      form.setFieldsValue({
        businessActivityDescription:
          onboardingCollection.businessActivityDescription,
      });
      setNoteIsValid(true);
    }
    if (hasWebsite) {
      form.setFieldsValue({
        website: onboardingCollection.website,
      });
    }
  }, [hasDescription, hasWebsite]);

  return (
    <>
      <SignupText
        subheading={<Trans i18nKey="BusinessActivityStep.heading" />}
      />
      <Form
        form={form}
        name="formBusiness"
        layout="vertical"
        onFinish={_onFinish}
        initialValues={{ owners: [{}] }}
      >
        <Form.Item
          name={ONBOARDING_FIELDS.businessActivityDescription}
          label={<Trans i18nKey="BusinessActivityStep.input_1_label" />}
          tooltip={<Trans i18nKey="BusinessActivityStep.input_1_tooltip" />}
        >
          <TextArea
            autoFocus
            rows={4}
            onChange={_onChangeNote}
            placeholder={t('BusinessActivityStep.input_1_placeholder')}
          />
        </Form.Item>
        <Form.Item
          name={ONBOARDING_FIELDS.website}
          label={<Trans i18nKey="BusinessActivityStep.input_2_label" />}
        >
          <Input placeholder={t('BusinessActivityStep.input_2_placeholder')} />
        </Form.Item>
        <Form.Item>
          <NextButton loading={isLoading} disabled={isDisabled} />
        </Form.Item>
      </Form>
    </>
  );
};

/**
 * Exports
 */
export { BusinessActivityStep };
