/**
 * Imports
 */
import { Anchor, Box, Button, Snackbar, Text, VibrantIcon } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Types
 */
export interface WelcomeSectionProps {}

/**
 * WelcomeSection component
 */
const WelcomeSection: React.FC<WelcomeSectionProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Box mb="48px">
      <Snackbar type="info">
        <Text size="ui3">{t('AuthStep.welcome.title')}</Text>
        <Box mt="16px">
          <Anchor
            size="ui3"
            href={t('AuthStep.welcome.link')}
            color="info"
            target="_blank"
          >
            <Box display={'flex'} alignItems="center">
              {t('AuthStep.welcome.link_text')}
              <Box ml={'8px'}>
                <VibrantIcon icon="arrow-long-right" color="White000" />
              </Box>
            </Box>
          </Anchor>
        </Box>
      </Snackbar>
    </Box>
  );
};

/**
 * Exports
 */
export { WelcomeSection };
