/**
 * Imports
 */
import { Button, Logo, Text, VibrantIcon } from 'components';
import { GoBackButton } from 'features/navigation/components';
import { openMobileMenu } from 'features/navigation/redux/actions';
import { currentRouteSelector } from 'features/navigation/redux/selectors';
import { StepIndex, StepOverview } from 'features/signupflow';
import { LogoutButton } from 'features/user/components';
import { userSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ROUTES } from 'router';
import { styled } from 'theme';

/**
 * Styling
 */
const Root = styled.div`
  width: 100%;

  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 1024px) {
    padding: 24px;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 432px;
  align-self: center;
`;

const SignupTextContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const Heading = styled(Text)`
  margin-bottom: 32px;
`;
const Subheading = styled(Text)`
  margin-bottom: 32px;
`;
const StepOverviewWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.Background.Primary};
  height: 100%;
  @media (max-width: 1024px) {
    display: none;
  }
`;
// Header
const HeaderRoot = styled.div`
  @media (max-width: 1024px) {
    margin-bottom: 8px;
  }
`;
const HeaderRow = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
  }
`;
const LogoutButtonWrapper = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const LogoWrapper = styled.div`
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const StepIndexWrapper = styled.div``;
const MobileMenuWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;
const HeaderAside = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 24px 24px;
  min-height: 96px;

  @media (max-width: 1024px) {
    padding: 0;
    min-height: 24px;
  }
`;

const LoginButtonWrapper = styled.div`
  cursor: pointer;
`;

/**
 * Types
 */
export interface SignupLayoutProps {
  hideGoBack?: boolean;
}
export interface HeaderProps {
  hideGoBack?: boolean;
}
export interface SignupTextProps {
  heading?: React.ReactNode;
  subheading?: React.ReactNode;
}
export interface NextButtonProps {
  disabled?: boolean;
  loading?: boolean;
  text?: React.ReactNode;
  // Func
  onClick?: () => void;
}

/**
 * NextButton
 */
const NextButton: React.FC<NextButtonProps> = ({
  onClick,
  disabled,
  loading,
  text,
}) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={onClick}
      block
      disabled={disabled}
      loading={loading}
      htmlType="submit"
      type="primary"
      iconRight={
        <VibrantIcon icon="arrow-long-right" color="White000" size={18} />
      }
    >
      {text ? text : t('_Common.actions.btn_continue')}
    </Button>
  );
};

/**
 * Optional label
 */
const OptionalLabel: React.FC = () => {
  return (
    <Text size="ui3" color="ui">
      <Trans i18nKey="_Common.labels.optional" />
    </Text>
  );
};
/**
 * SignupLayout text container
 */
const SignupText: React.FC<SignupTextProps> = ({ heading, subheading }) => {
  return (
    <SignupTextContainer>
      {heading && <Heading size="h3">{heading}</Heading>}
      {subheading && <Subheading size="h5">{subheading}</Subheading>}
    </SignupTextContainer>
  );
};
/**
 * SignupLayout component
 */
const Header: React.FC<HeaderProps> = ({ hideGoBack }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useHistory();
  const currentRoute = useSelector(currentRouteSelector);
  const user = useSelector(userSelector);
  /* In-use variables */
  const showLogin = currentRoute.pageId === 'home' && !user;

  const _openMobileMenu = () => {
    dispatch(openMobileMenu({}));
  };

  const _alreadyMember = () => {
    navigation.push(ROUTES.login.path);
  };

  const _goToHome = () => {
    navigation.push(ROUTES.home.path);
  };

  return (
    <HeaderRoot>
      <HeaderRow>
        <LogoWrapper onClick={_goToHome}>
          <Logo size={24} single />
        </LogoWrapper>
        <StepIndexWrapper>
          <StepIndex hideTime />
        </StepIndexWrapper>
        <MobileMenuWrapper onClick={_openMobileMenu}>
          <VibrantIcon icon="menu" size={20} />
        </MobileMenuWrapper>
      </HeaderRow>
      <HeaderAside>
        {hideGoBack ? <div /> : <GoBackButton />}
        <LogoutButtonWrapper>
          <LogoutButton />
        </LogoutButtonWrapper>
        {showLogin && (
          <LoginButtonWrapper>
            <Text size="ui3" onClick={_alreadyMember} color="ui">
              {t('_Common.actions.btn_account')}
            </Text>
          </LoginButtonWrapper>
        )}
      </HeaderAside>
    </HeaderRoot>
  );
};
/**
 * SignupLayout component
 */
const SignupLayout: React.FC<SignupLayoutProps> = ({
  children,
  hideGoBack,
}) => {
  return (
    <Root>
      <StepOverviewWrapper>
        <StepOverview />
      </StepOverviewWrapper>
      <Main>
        <Header hideGoBack={hideGoBack} />
        <Content>{children}</Content>
      </Main>
    </Root>
  );
};

/**
 * Exports
 */
export { SignupLayout, SignupText, NextButton, OptionalLabel };
