/**
 * Imports
 */
import { Form } from 'antd';
import { Input } from 'components';
import { NextButton, SignupText } from 'features/signupflow';
import { useMerchantApi, useSignupFlow } from 'features/signupflow/api';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Firebase } from 'types/firebase';
import { captureError, ONBOARDING_FIELDS } from 'utils';

/**
 * Types
 */
export interface RegistrationNumberFormProps {}
export type RegistrationNumberRow = Pick<
  Firebase.Merchant,
  'companyRegistrationNumber'
>;

/**
 * RegistrationNumberForm component
 */
const RegistrationNumberForm: React.FC<RegistrationNumberFormProps> = (
  _props,
) => {
  const { t } = useTranslation();
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  const {
    handlers: { onNextStep },
  } = useSignupFlow();
  const {
    handlers: { onUpdateRegistrationNumber },
  } = useMerchantApi();
  /* Local state */
  const [form] = Form.useForm();
  const [isValid, setIsValid] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const _onFinish = async (values: RegistrationNumberRow) => {
    setIsLoading(true);
    try {
      // Rm undefined values
      const filteredValues = JSON.parse(JSON.stringify(values));
      onUpdateRegistrationNumber(filteredValues);
      setIsLoading(false);
      onNextStep();
    } catch (exception) {
      setIsLoading(false);
      captureError('Error: Failed to update company details', exception);
    }
  };

  const _isValid = (allValues: RegistrationNumberRow) => {
    if (allValues.companyRegistrationNumber) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const _onChange = (
    _values: RegistrationNumberRow,
    allValues: RegistrationNumberRow,
  ) => {
    _isValid(allValues);
  };

  /**
   * Set existing values
   */
  React.useEffect(() => {
    if (onboardingCollection.companyRegistrationNumber) {
      const { companyRegistrationNumber } = onboardingCollection;
      form.setFieldsValue({ companyRegistrationNumber });
      setIsValid(true);
    }
  }, []);

  return (
    <>
      <SignupText
        subheading={
          <Trans i18nKey="RegistrationNumberStep.limited_liability.heading" />
        }
      />
      <Form
        layout="vertical"
        form={form}
        onValuesChange={_onChange}
        onFinish={_onFinish}
      >
        {/* companyRegistrationNumber */}
        <Form.Item
          name={ONBOARDING_FIELDS.companyRegistrationNumber}
          label={
            <Trans i18nKey="CompanyDetails.limited_liability.input_registration_number_label" />
          }
        >
          <Input
            placeholder={t(
              'CompanyDetails.limited_liability.input_registration_number_placeholder',
            )}
          />
        </Form.Item>
        <Form.Item>
          <NextButton loading={isLoading} disabled={!isValid} />
        </Form.Item>
      </Form>
    </>
  );
};

/**
 * Exports
 */
export { RegistrationNumberForm };
