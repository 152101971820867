/**
 * Imports
 */
import { Tooltip as AntdTooltip, TooltipProps as AntdTooltipProps } from 'antd';
import * as React from 'react';
import { styled } from 'theme';

/**
 * Styling
 */
const StyledTooltip = styled(AntdTooltip)`
  margin-top: 4px;
  margin-left: 8px;
`;

/**
 * Types
 */
export type ATooltipProps = AntdTooltipProps & Record<string, any>;

/**
 * Tooltip component
 */
const Tooltip: React.FC<ATooltipProps> = ({ children, ...rest }) => {
  return (
    <StyledTooltip {...rest}>
      <div>{children}</div>
    </StyledTooltip>
  );
};

/**
 * Exports
 */
export { Tooltip };
