/**
 * Imports
 */
import { Select, VibrantIcon } from 'components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { styled } from 'theme';
import { getBrowserLanguage, LanguageCodeTypes, LANGUAGE_OPTIONS } from 'utils';

const SelectWrapper = styled.div``;
const LanguageSelect = styled(Select)`
  && {
    .ant-select-dropdown {
      padding: 8px;
    }
    .ant-select-selector {
      background-color: transparent;
      border: none;
      height: 32px;

      display: flex;
      align-items: center;
    }
    .ant-select-arrow {
      color: ${({ theme }) => theme.colors.Text.Heading};
    }
    .ant-select-arrow .anticon:not(.ant-select-suffix) {
      pointer-events: none;
    }
    // Dropdown item
    .ant-select-item {
      background-color: none;
      color: ${({ theme }) => theme.colors.Text.Heading};
      height: 32px;

      display: flex;
      align-items: center;
    }
    .ant-select-item-option {
      padding: 8px;
    }
    // Selected item
    .ant-select-item-option-active {
      background-color: ${({ theme: { colors } }) => colors.Background.Primary};
    }
  }
`;

/**
 * Types
 */
export interface LanguageSelectorProps {}

/**
 * LanguageSelector component
 */
const LanguageSelector: React.FC<LanguageSelectorProps> = (_props) => {
  const { i18n } = useTranslation();
  const _changeLanguage = (language: LanguageCodeTypes) => {
    i18n.changeLanguage(language);
  };

  return (
    <SelectWrapper>
      <LanguageSelect
        defaultValue={i18n.language || getBrowserLanguage()}
        style={{ width: 72 }}
        suffixIcon={<VibrantIcon icon="chevron-down" size={12} />}
        // @ts-ignore
        onChange={(val) => _changeLanguage(val)}
        // Needed to override select dropdown style
        getPopupContainer={(trigger) => {
          return trigger;
        }}
      >
        <Select.Option value={LANGUAGE_OPTIONS.da}>
          <Trans i18nKey="_Common.language_selector.da" />
        </Select.Option>
        <Select.Option value={LANGUAGE_OPTIONS.en}>
          <Trans i18nKey="_Common.language_selector.en" />
        </Select.Option>
        <Select.Option value={LANGUAGE_OPTIONS.es}>
          <Trans i18nKey="_Common.language_selector.es" />
        </Select.Option>
        <Select.Option value={LANGUAGE_OPTIONS.se}>
          <Trans i18nKey="_Common.language_selector.se" />
        </Select.Option>
      </LanguageSelect>
    </SelectWrapper>
  );
};

/**
 * Exports
 */
export { LanguageSelector };
