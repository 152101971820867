/**
 * Imports
 */
import { Col, Form, Row } from 'antd';
import { Input } from 'components';
import { NextButton, OptionalLabel, SignupText } from 'features/signupflow';
import { useMerchantApi, useSignupFlow } from 'features/signupflow/api';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Firebase } from 'types/firebase';
import {
  captureError,
  CONTACT_FIELDS,
  DIALLING_CODES,
  ONBOARDING_FIELDS,
} from 'utils';

/**
 * Types
 */
export interface LimitedLiabilityFormProps {}
export type CompanyDetailsLimitedRow = Pick<
  Firebase.Merchant,
  | 'addressLine1'
  | 'businessLegalName'
  | 'city'
  | 'merchantTradingName'
  | 'vatNumber'
  | 'zipCode'
> &
  Pick<Firebase.Contact, 'contactEmail' | 'phone'>;

/**
 * Check for merchantTradingName, else set businessLegalName as merchantTradingName
 */
const _checkTradingName = (
  values: CompanyDetailsLimitedRow,
): CompanyDetailsLimitedRow => {
  if (values.merchantTradingName) {
    return values;
  } else {
    const newValues = {
      ...values,
      merchantTradingName: values.businessLegalName,
    };
    return newValues;
  }
};

/**
 * LimitedLiabilityForm component
 */
const LimitedLiabilityForm: React.FC<LimitedLiabilityFormProps> = (_props) => {
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  const { t } = useTranslation();
  const {
    handlers: { onNextStep },
  } = useSignupFlow();
  const {
    handlers: { onUpdateCompanyDetails },
  } = useMerchantApi();
  /* Local state */
  const [form] = Form.useForm();
  const [isValid, setIsValid] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const _onFinish = async (values: CompanyDetailsLimitedRow) => {
    setIsLoading(true);
    try {
      const checkedValues = _checkTradingName(values);
      // Rm undefined values
      const filteredValues = JSON.parse(JSON.stringify(checkedValues));
      onUpdateCompanyDetails(filteredValues);
      setIsLoading(false);
      onNextStep();
    } catch (exception) {
      setIsLoading(false);
      captureError('Error: Failed to update company details', exception);
    }
  };

  const _isValid = (allValues: CompanyDetailsLimitedRow) => {
    if (
      allValues.businessLegalName &&
      allValues.contactEmail &&
      allValues.phone &&
      allValues.addressLine1 &&
      allValues.city &&
      allValues.zipCode &&
      allValues.vatNumber
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const _onChange = (
    _values: CompanyDetailsLimitedRow,
    allValues: CompanyDetailsLimitedRow,
  ) => {
    _isValid(allValues);
  };

  /**
   * Set values if exists
   */
  React.useEffect(() => {
    if (onboardingCollection.businessLegalName) {
      const {
        businessLegalName,
        contact: { contactEmail, phone },
        addressLine1,
        zipCode,
        city,
        province,
        merchantTradingName,
        vatNumber,
      } = onboardingCollection;
      form.setFieldsValue({
        businessLegalName,
        contactEmail,
        phone: phone || DIALLING_CODES[onboardingCollection.countryCode!],
        addressLine1,
        zipCode,
        city,
        province,
        merchantTradingName,
        vatNumber,
      });
      setIsValid(true);
    }
  }, []);

  return (
    <>
      <SignupText
        subheading={
          <Trans i18nKey="CompanyDetails.limited_liability.heading" />
        }
      />
      <Form
        layout="vertical"
        form={form}
        onValuesChange={_onChange}
        onFinish={_onFinish}
      >
        {/* businessLegalName */}
        <Form.Item
          name={ONBOARDING_FIELDS.businessLegalName}
          label={
            <Trans i18nKey="CompanyDetails.limited_liability.input_name_label" />
          }
        >
          <Input
            placeholder={t(
              'CompanyDetails.limited_liability.input_name_placeholder',
            )}
          />
        </Form.Item>
        {/* phone */}
        <Form.Item
          name={CONTACT_FIELDS.phone}
          label={
            <Trans i18nKey="CompanyDetails.limited_liability.input_phone_label" />
          }
        >
          <Input
            placeholder={t(
              'CompanyDetails.limited_liability.input_phone_placeholder',
            )}
          />
        </Form.Item>
        {/* contactEmail */}
        <Form.Item
          name={CONTACT_FIELDS.contactEmail}
          label={
            <Trans i18nKey="CompanyDetails.limited_liability.input_email_label" />
          }
        >
          <Input
            placeholder={t(
              'CompanyDetails.limited_liability.input_email_placeholder',
            )}
          />
        </Form.Item>
        {/* addressLine1 */}
        <Form.Item
          name={ONBOARDING_FIELDS.addressLine1}
          label={
            <Trans i18nKey="CompanyDetails.limited_liability.input_addressline_label" />
          }
        >
          <Input
            placeholder={t(
              'CompanyDetails.limited_liability.input_addressline_placeholder',
            )}
          />
        </Form.Item>
        <Row gutter={8}>
          {/* city */}
          <Col span={12}>
            <Form.Item
              name={ONBOARDING_FIELDS.city}
              label={
                <Trans i18nKey="CompanyDetails.limited_liability.input_city_label" />
              }
            >
              <Input
                placeholder={t(
                  'CompanyDetails.limited_liability.input_city_placeholder',
                )}
              />
            </Form.Item>
          </Col>
          {/* zipCode */}
          <Col span={12}>
            <Form.Item
              name={ONBOARDING_FIELDS.zipCode}
              label={
                <Trans i18nKey="CompanyDetails.limited_liability.input_zipcode_label" />
              }
            >
              <Input
                placeholder={t(
                  'CompanyDetails.limited_liability.input_zipcode_placeholder',
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name={ONBOARDING_FIELDS.merchantTradingName}
          tooltip={
            <Trans i18nKey="CompanyDetails.limited_liability.input_trading_tooltip" />
          }
          label={
            <>
              <Trans i18nKey="CompanyDetails.limited_liability.input_trading_label" />
              &nbsp;
              <OptionalLabel />
            </>
          }
        >
          <Input
            placeholder={t(
              'CompanyDetails.limited_liability.input_trading_placeholder',
            )}
          />
        </Form.Item>
        <Form.Item
          name={ONBOARDING_FIELDS.vatNumber}
          label={
            <Trans i18nKey="CompanyDetails.limited_liability.input_vatnumber_label" />
          }
        >
          <Input
            placeholder={t(
              'CompanyDetails.limited_liability.input_vatnumber_placeholder',
            )}
          />
        </Form.Item>
        <Form.Item>
          <NextButton loading={isLoading} disabled={!isValid} />
        </Form.Item>
      </Form>
    </>
  );
};

/**
 * Exports
 */
export { LimitedLiabilityForm };
