/**
 * Imports
 */
import { Spin } from 'antd';
import * as React from 'react';
import { styled } from 'theme';
import { LoadingOutlined } from '@ant-design/icons';
import { ColorShadesType, VibrantColors } from 'theme/colors';

/**
 * CONSTANTS
 */
const SPINNER_HEIGHT = 24;

/**
 * Styling
 */
const Root = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: inherit;
`;
const SpinnerWrapper = styled.div`
  /* position: absolute;
   top: calc(50% - ${SPINNER_HEIGHT}px); */
`;

/**
 * Types
 */
export interface SpinnerProps {
  showSpinner: boolean;
  size?: number;
  color?: ColorShadesType;
}

/**
 * Spinner component
 */
const Spinner: React.FC<SpinnerProps> = ({ showSpinner, size, color }) => {
  if (!showSpinner) {
    return null;
  }

  return (
    <Root>
      <SpinnerWrapper>
        {/* prettier-ignore */}
        <Spin  indicator={<LoadingOutlined style={{ color: color
           ? VibrantColors.Shades[color]
           : VibrantColors.Shades.Orange500, fontSize: size ? size : SPINNER_HEIGHT }} spin />} />
      </SpinnerWrapper>
    </Root>
  );
};

/**
 * Exports
 */
export { Spinner };
