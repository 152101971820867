/**
 * Imports
 */
import { BaseLayout } from 'features/navigation/components';
import { CountryStep, SignupLayout } from 'features/signupflow';
import * as React from 'react';

/**
 * Types
 */
export interface HomePageProps {}

/**
 * HomePage component
 */
const HomePage: React.FC<HomePageProps> = (_props) => {
  return (
    <BaseLayout>
      <SignupLayout hideGoBack>
        <CountryStep />
      </SignupLayout>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { HomePage };
