/**
 * Imports
 */
import { BaseLayout } from 'features/navigation/components';
import * as React from 'react';

import { styled } from 'theme';
import { Space, Text } from 'components';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router';
import { ROUTES } from 'router';

/**
 * Styling
 */
const Root = styled.div`
  background-color: ${(props) => props.theme.colors.Background.Brand};
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const Content = styled.div`
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const TextWrapper = styled.div`
  max-width: 600px;
  text-align: center;
`;
const HomeLink = styled(Text)`
  display: inline-block;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.Text.Link};
  transition: ${({ theme }) => theme.transitions.HoverTextColor};
  &:hover {
    color: ${({ theme }) => theme.colors.Text.LinkHover};
  }
`;
const SupportLink = styled.a`
  display: inline-block;
  cursor: pointer;
  font-weight: bold;

  color: ${({ theme }) => theme.colors.Text.Link};
  transition: ${({ theme }) => theme.transitions.HoverTextColor};
  &:hover {
    color: ${({ theme }) => theme.colors.Text.LinkHover};
  }
`;

/**
 * Types
 */
export interface NoMatchPageProps {}

/**
 * NoMatchPage component
 */
const NoMatchPage: React.FC<NoMatchPageProps> = (_props) => {
  const navigation = useHistory();

  const _goToHome = () => {
    navigation.push(ROUTES.home.path);
  };

  return (
    <BaseLayout>
      <Root>
        <Content>
          <Text size="h2" color="white" bold>
            <Trans i18nKey="_Common.404.heading" />
          </Text>
          <Space size="lg" />
          <Space size="lg" />
          <TextWrapper>
            <Text size="h5" color="white">
              <Trans i18nKey="_Common.404.subheading" />
            </Text>
            <Space size="xl" />
            <Text size="ui2" color="white">
              <Trans i18nKey="_Common.404.subtext">
                <HomeLink size="ui2" bold onClick={_goToHome} />
                <SupportLink href="mailto:support@vibrant.io" />
              </Trans>
            </Text>
          </TextWrapper>
        </Content>
      </Root>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { NoMatchPage };
