import { message } from 'antd';
import * as React from 'react';

export interface MessageProps {
  content: React.ReactNode;
  duration?: number | null;
  onClose?: () => void;
}

/**
 * Error
 */
export const messageError = ({ ...rest }: MessageProps): React.ReactNode =>
  message.info({
    className: 'vibrant-error-message',
    ...rest,
    type: 'error',
    icon: null,
  });
/**
 * Warning
 */
export const messageWarning = ({ ...rest }: MessageProps): React.ReactNode =>
  message.info({
    className: 'vibrant-warning-message',
    ...rest,
    type: 'warning',
    icon: null,
  });
/**
 * Success
 */
export const messageSuccess = ({ ...rest }: MessageProps): React.ReactNode =>
  message.info({
    className: 'vibrant-success-message',
    ...rest,
    type: 'success',
    icon: null,
  });
/**
 * Info
 */
export const messageInfo = ({ ...rest }: MessageProps): React.ReactNode =>
  message.success({
    className: 'vibrant-info-message',
    ...rest,
    type: 'info',
    icon: null,
  });
