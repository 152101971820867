/**
 * Imports
 */
import { BaseLayout } from 'features/navigation/components';
import { SignupLayout } from 'features/signupflow';
import { AuthStep } from 'features/signupflow/components/Steps';
import * as React from 'react';

/**
 * Types
 */
export interface AuthPageProps {}

/**
 * AuthPage component
 */
const AuthPage: React.FC<AuthPageProps> = (_props) => {
  return (
    <BaseLayout>
      <SignupLayout>
        <AuthStep />
      </SignupLayout>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { AuthPage };
