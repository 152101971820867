/**
 * Imports
 */
import { Text } from 'components';
import { maybeUserSelector } from 'features/user/redux/selectors';
import { firebaseLogout } from 'firebaseAPI';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { styled } from 'theme';

/**
 * Styling
 */
const Root = styled.div`
  cursor: pointer;
  border-bottom: none;
  padding: 0 0 0 0;

  color: ${(props) => props.theme.colors.Text.Link};
  transition: ${(props) => props.theme.transitions.HoverTextColor};
  &:hover {
    color: ${(props) => props.theme.colors.Text.LinkHover};
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

/**
 * Types
 */
export interface LogoutButtonProps {}

/**
 * LogoutButton component
 */
const LogoutButton: React.FC<LogoutButtonProps> = (_props) => {
  /* History */
  const history = useHistory();
  const user = useSelector(maybeUserSelector);

  /**
   *  Handle logout
   */
  const _handleLogout = async () => {
    await firebaseLogout();
    history.replace({ pathname: '/' });
  };

  if (!user) {
    return null;
  }

  return (
    <Root onClick={_handleLogout}>
      <Text size="ui3">
        <Trans i18nKey="_Common.actions.btn_logout" />
      </Text>
    </Root>
  );
};

/**
 * Exports
 */
export { LogoutButton };
