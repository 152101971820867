/**
 * Imports
 */
import { combineReducers } from '@reduxjs/toolkit';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import features from 'features';

/**
 * The app's reducer
 */
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  navigation: features.navigation.redux.reducer,
  signupflow: features.signupflow.redux.reducer,
  user: features.user.redux.reducer,
});

/**
 * Exports
 */
export { rootReducer };
