/**
 * Notes: https://levelup.gitconnected.com/polling-in-javascript-ab2d6378705a
 */
/**
 *CONSTANTS
 */
const MAX_TRYS = 8;
const TRY_TIMEOUT = 500;

/**
 * Types
 */
type PollParamsType = {
  fn: (params?: any) => Promise<any>;
  interval?: number;
  maxAttempts?: number;
  params?: any;
  validate: any;
};
/**
 * Poller
 */
export const poll = async ({
  fn,
  validate,
  interval = TRY_TIMEOUT,
  maxAttempts = MAX_TRYS,
  params,
}: PollParamsType): Promise<any> => {
  let attempts = 0;

  const executePoll = async (resolve: any, reject: any) => {
    const result = await fn(params);
    attempts++;
    if (validate(result)) {
      return resolve(result);
    } else if (maxAttempts && attempts === maxAttempts) {
      return reject(new Error('Poll exceeded max attempts'));
    } else {
      setTimeout(executePoll, interval, resolve, reject);
    }
  };

  return new Promise(executePoll);
};
