/**
 * Imports
 */
import { vatErrorSelector } from 'features/signupflow/redux/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { Text, Snackbar } from 'components';
import { styled } from 'theme';
import { Trans } from 'react-i18next';

/**
 * Styling
 */
const Root = styled.div``;

const CompanyName = styled(Text)`
  margin-bottom: 8px;
`;
const ErrorText = styled(Text)`
  margin-bottom: 4px;
`;
const SupportLink = styled.a`
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.Text.Warning};
  transition: ${({ theme }) => theme.transitions.HoverTextColor};
  &:hover {
    color: ${({ theme }) => theme.colors.Text.LinkHover};
  }
`;
/**
 * Types
 */
export interface VatErrorProps {}
/**
 * VatError component
 */
const VatError: React.FC<VatErrorProps> = (_props) => {
  const vatError = useSelector(vatErrorSelector);

  if (!vatError) {
    return null;
  }

  return (
    <Root>
      <Snackbar type="warning">
        {vatError === 'NOT_FOUND' ? (
          <>
            <CompanyName size="ui2" bold>
              <Trans i18nKey="RegistrationNumberStep.cvr.errors.not_found_heading">
                <SupportLink href="mailto:support@vibrant.io" />
              </Trans>
            </CompanyName>
            <ErrorText size="ui2">
              <Trans i18nKey="RegistrationNumberStep.cvr.errors.not_found_text">
                <SupportLink href="mailto:support@vibrant.io" />
              </Trans>
            </ErrorText>
          </>
        ) : (
          <>
            <CompanyName size="ui2" bold>
              <Trans i18nKey="RegistrationNumberStep.cvr.errors.internal_error_heading">
                <SupportLink href="mailto:support@vibrant.io" />
              </Trans>
            </CompanyName>
            <ErrorText size="ui2">
              <Trans i18nKey="RegistrationNumberStep.cvr.errors.internal_error_text">
                <SupportLink href="mailto:support@vibrant.io" />
              </Trans>
            </ErrorText>
          </>
        )}
      </Snackbar>
    </Root>
  );
};

/**
 * Exports
 */
export { VatError };
