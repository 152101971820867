/**
 * Imports
 */
import * as React from 'react';
import { styled } from 'theme';
import { VibrantIcon } from 'components';
import { useHistory } from 'react-router-dom';

/**
 * Styling
 */
const Root = styled.div`
  border: 1px solid ${(props) => props.theme.colors.Border.BorderPrimary};
  border-radius: 50%;
  height: 32px;
  width: 32px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${(props) => props.theme.colors.Base.Brand};

    & svg {
      color: ${(props) => props.theme.colors.Base.Brand};
    }
  }

  @media (max-width: 1024px) {
    border: none;
    height: 24px;
    width: 24px;
  }
`;

/**
 * GoBackButton component
 */
const GoBackButton: React.FC = () => {
  const navigation = useHistory();

  const _goBack = () => {
    navigation.goBack();
  };

  return (
    <Root onClick={_goBack}>
      <VibrantIcon icon="arrow-long-left" color="Grey500" size={20} />
    </Root>
  );
};

/**
 * Exports
 */
export { GoBackButton };
