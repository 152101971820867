/**
 * Imports
 */
import * as React from 'react';
import styled, { css } from 'styled-components';
import { styled as vibrantStyled, theme } from 'theme';

/**
 * Types
 */
export type TextSize =
  // Headings
  | 'h0'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p1'
  | 'p2'
  | 'p3'
  | 'ui1'
  | 'ui2'
  | 'ui3';

export type ColorTypes =
  // Types
  | 'brand'
  | 'ui'
  | 'heading'
  | 'paragraph'
  | 'link'
  | 'disabled'
  | 'label'
  | 'placeholder'
  // Feedback
  | 'danger'
  | 'info'
  | 'success'
  | 'warning'
  // Basic
  | 'black'
  | 'white';

// TODO: Could be improved with mark (highlighted), code, line through style
export type TextProps = {
  size: TextSize;
  color?: ColorTypes;
  // Formats
  ellipsis?: boolean;
  clip?: boolean;
  // Text style
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  // state
  link?: boolean;
  onClick?: () => void;
};

/**
 * Constants
 */
const TEXT_COMMON_CSS = css`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'BasierRegular';
  margin: 0;
  padding: 0;
`;
const H_COMMON_CSS = css`
  color: ${theme.colors.Text.Heading};
  font-family: 'BasierBold';
  letter-spacing: -0.2px;
`;
const P_COMMON_CSS = css`
  color: ${theme.colors.Text.Paragraph};
`;

/**
 * Text Type (Semantic mapping)
 */
export const TEXT_TYPES = {
  // Headings
  h1: styled.h1,
  h2: styled.h2,
  h3: styled.h3,
  h4: styled.h4,
  h5: styled.h5,
  // Paragraphs
  p1: styled.p,
  p2: styled.p,
  p3: styled.p,
  // UI
  ui1: styled.p,
  ui2: styled.p,
  ui3: styled.p,
};

/**
 * Sizes
 */
const FONT_CSS = {
  // Headings
  h0: css`
    ${TEXT_COMMON_CSS}
    ${H_COMMON_CSS}
    font-size: 96px;
    line-height: 105.6px;
  `,
  h1: css`
    ${TEXT_COMMON_CSS}
    ${H_COMMON_CSS}
    font-size: 72px;
    line-height: 79.2px;
  `,
  h2: css`
    ${TEXT_COMMON_CSS}
    ${H_COMMON_CSS}
    font-size: 64px;
    line-height: 70.4px;
  `,
  h3: css`
    ${TEXT_COMMON_CSS}
    ${H_COMMON_CSS}
    font-size: 48px;
    line-height: 57.6px;

    @media (max-width: 600px) {
      font-size: 28px;
      line-height: 36.12px;
    }
  `,
  h4: css`
    ${TEXT_COMMON_CSS}
    ${H_COMMON_CSS}
    font-size: 40px;
    line-height: 48px;
  `,
  h5: css`
    ${TEXT_COMMON_CSS}
    ${H_COMMON_CSS}
    font-size: 28px;
    line-height: 36.12px;
  `,
  h6: css`
    ${TEXT_COMMON_CSS}
    ${H_COMMON_CSS}
    font-size: 21px;
    line-height: 27.3px;
  `,
  // Paragraph
  p1: css`
    ${TEXT_COMMON_CSS}
    ${P_COMMON_CSS}
    font-size: 21px;
    line-height: 33.6px;
  `,
  p2: css`
    ${TEXT_COMMON_CSS}
    ${P_COMMON_CSS}
    font-size: 17px;
    line-height: 27.2px;
  `,
  p3: css`
    ${TEXT_COMMON_CSS}
    ${P_COMMON_CSS}
    font-size: 13px;
    line-height: 20.8px;
  `,
  // UI
  ui1: css`
    ${TEXT_COMMON_CSS}
    font-size: 21px;
    line-height: 26.88px;
  `,
  ui2: css`
    ${TEXT_COMMON_CSS}
    font-size: 17px;
    line-height: 21.76px;
  `,
  ui3: css`
    ${TEXT_COMMON_CSS}
    font-size: 13px;
    line-height: 16.38px;
  `,
};

/**
 * Color variants
 */
export const TEXT_COLOR_TYPES = {
  // Types
  brand: css`
    color: ${theme.colors.Text.Brand};
  `,
  ui: css`
    color: ${theme.colors.Text.UI};
  `,
  heading: css`
    color: ${theme.colors.Text.Heading};
  `,
  paragraph: css`
    color: ${theme.colors.Text.Paragraph};
  `,
  disabled: css`
    color: ${theme.colors.Text.Disabled};
  `,
  label: css`
    color: ${theme.colors.Text.Label};
  `,
  link: css`
    color: ${theme.colors.Text.Link};
    transition: ${theme.transitions.HoverTextColor};
    &:hover {
      color: ${theme.colors.Text.LinkHover};
    }
  `,
  placeholder: css`
    color: ${theme.colors.Text.Placeholder};
  `,
  // Feedback
  danger: css`
    color: ${theme.colors.Text.Danger};
  `,
  info: css`
    color: ${theme.colors.Text.Info};
  `,
  success: css`
    color: ${theme.colors.Text.Success};
  `,
  warning: css`
    color: ${theme.colors.Text.Warning};
  `,
  // Basic
  white: css`
    color: ${theme.colors.Text.White};
  `,
  black: css`
    color: ${theme.colors.Text.Black};
  `,
};
/**
 * Ellipsis styling
 */
const ELLIPSIS_CSS = css`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const CLIP_CSS = css`
  max-width: 100%;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
`;
/**
 * Underline styling
 */
const UNDERLINE_CSS = css`
  text-decoration: underline;
`;
/**
 * Bold styling
 */
const BOLD_CSS = css`
  font-family: 'BasierBold';
`;
/**
 * Italic styling
 */
const ITALIC_CSS = css`
  font-style: italic;
`;
/**
 * Link styling
 */
const LINK_CSS = css`
  cursor: pointer;
  transition: color 0.2s ease;
  &:hover {
    color: ${(props) => props.theme.colors.Text.Brand}!important;
  }
`;

// TODO: Dynamic change TEXT_TYPE[size]
const StyledTextComponent = styled('div')<TextProps>`
  ${(props) => props.size && FONT_CSS[props.size]}
  ${(props) => props.color && TEXT_COLOR_TYPES[props.color]}
  ${(props) => props.ellipsis && ELLIPSIS_CSS}
  ${(props) => props.clip && CLIP_CSS}
  ${(props) => props.bold && BOLD_CSS}
  ${(props) => props.italic && ITALIC_CSS}
  ${(props) => props.underline && UNDERLINE_CSS}
  ${(props) => props.link && LINK_CSS}
`;

const Text: React.FC<TextProps> = ({ children, ...rest }) => {
  return <StyledTextComponent {...rest}>{children}</StyledTextComponent>;
};

/**
 * Label
 */
export interface LabelProps {
  text: React.ReactNode;
}
const StyledLabel = vibrantStyled(Text)`
   margin-bottom: 8px;
 `;
const Label: React.FC<LabelProps> = ({ text }) => {
  return <StyledLabel size="ui3">{text}</StyledLabel>;
};

/**
 * SubText
 */
export interface SubTextProps {
  text: React.ReactNode;
}
const StyledSubText = vibrantStyled(Text)`
 `;
const SubText: React.FC<SubTextProps> = ({ text }) => {
  return (
    <StyledSubText size="p3" color="ui">
      {text}
    </StyledSubText>
  );
};

/**
 * Anchor
 */
export type AnchorSizes = 'sm' | 'md' | 'lg';
type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  bold?: boolean;
  color?: ColorTypes;
  href: string;
  size: TextSize;
};
const StyledLink = vibrantStyled.a<AnchorProps>`
 ${(props) => props.size && FONT_CSS[props.size]}
 ${LINK_CSS};
 ${(props) => props.color && TEXT_COLOR_TYPES[props.color]}
 ${(props) => props.bold && BOLD_CSS}
  `;
const Anchor: React.FC<AnchorProps> = ({ children, ...rest }) => {
  return <StyledLink {...rest}>{children}</StyledLink>;
};
/**
 * Exports
 */
export { FONT_CSS, Text, Label, SubText, Anchor };
