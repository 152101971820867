/**
 * Imports
 */
import { Button, VibrantIcon } from 'components';
import { useSignupFlow } from 'features/signupflow';
import { countryCodeSelector } from 'features/signupflow/redux/selectors';
import { firebaseSignupWithGoogle } from 'firebaseAPI';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { styled } from 'theme';

/**
 * Styling
 */
const StyledButton = styled(Button)`
  background-color: #4285f4;

  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: #2472f2;
  }
`;

/**
 * GoogleSignupButton component
 */
const GoogleSignupButton: React.FC = () => {
  const countryCode = useSelector(countryCodeSelector);
  const {
    handlers: { onNextStep },
  } = useSignupFlow();
  /* Local state */
  const [isLoading, setIsLoading] = React.useState(false);

  const _handleGoogleLogin = async () => {
    setIsLoading(true);
    try {
      console.log('GoogleSignupButton', countryCode);
      await firebaseSignupWithGoogle(countryCode!);
      setIsLoading(false);
      onNextStep();
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <StyledButton
      type="primary"
      onClick={_handleGoogleLogin}
      block
      loading={isLoading}
    >
      <VibrantIcon icon="google" color="White000" />
      <Trans i18nKey="AuthStep.btn_google_signup" />
    </StyledButton>
  );
};

/**
 * Exports
 */
export { GoogleSignupButton };
