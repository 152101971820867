/**
 * Imports
 */
import { CheckOutlined } from '@ant-design/icons';
import { Form, FormItemProps, Upload as AntdUpload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { Icon, Text } from 'components';
import * as React from 'react';
import { styled } from 'theme';
import { ALLOWED_CONTENT_TYPES, ALLOWED_IMAGE_TYPES } from 'utils';
const { Dragger } = AntdUpload;
/**
 * Styling
 */
const StyledDragger = styled(Dragger)<{ isValid: boolean }>`
  background-color: white !important;
  padding: 8px 32px;

  && {
    border: ${({ isValid, theme }) =>
      isValid && `1px solid ${theme.colors.Border.BorderBrand}`};
  }
`;
const UploadContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const IconWrapper = styled.div`
  margin-right: 38px;
`;
const UploadText = styled.div`
  width: 100%;
  max-width: 200px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;
const CheckmarkWrapper = styled.div`
  margin-left: auto;
`;
const Checkmark = styled(Icon)``;

/**
 * Types
 */
export type UploadProps = {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  text?: React.ReactNode;
  image?: boolean;
} & FormItemProps;

/**
 * Upload component
 * Note: a surrounding div is needed for the form to bind to an form.item
 */
const Upload: React.FC<UploadProps> = ({
  icon,
  title,
  text,
  image,
  ...rest
}) => {
  const [isValid, setIsValid] = React.useState(false);
  const _onChange = (info: UploadChangeParam<UploadFile<any>>) => {
    setIsValid(info.fileList.length > 0 ? true : false);
  };
  return (
    <Form.Item {...rest}>
      <StyledDragger
        beforeUpload={() => false}
        accept={image ? `${ALLOWED_IMAGE_TYPES}` : `${ALLOWED_CONTENT_TYPES}`}
        onChange={_onChange}
        isValid={isValid}
      >
        <UploadContent>
          <IconWrapper>{icon}</IconWrapper>
          <UploadText>
            <Text size="ui2" bold>
              {title}
            </Text>
            <Text size="p3">{text}</Text>
          </UploadText>
          {isValid && (
            <CheckmarkWrapper>
              <Checkmark icon={<CheckOutlined />} color="Orange500" />
            </CheckmarkWrapper>
          )}
        </UploadContent>
      </StyledDragger>
    </Form.Item>
  );
};

/**
 * Exports
 */
export { Upload };
