/**
 * Imports
 */
import { message } from 'antd';
import {
  FirestoreUser,
  FullUser,
  UserInformationType,
} from 'features/user/types';
import firebaseNative from 'firebase/app';
import { Firebase } from 'types/firebase';
import { captureError, captureInfo, CURRENCY_MAP, poll, VAT_MAP } from 'utils';
import { getFirebaseInstance } from './firebaseAdapter';

/**
 * CONSTANTS
 */
export const FB_ERR_ALREADY_USER = 'auth/email-already-in-use';
export const FB_ERR_WRONG_PASSWORD = 'auth/wrong-password';
export type FirebaseCollectionsType =
  | 'softPosOnboarding'
  | 'merchants'
  | 'users'
  | 'emailLeads';
export type FirebaseCollectionsLookup = {
  [key in FirebaseCollectionsType]: FirebaseCollectionsType;
};
export const FB_COLLECTIONS_LOOKUP: FirebaseCollectionsLookup = {
  softPosOnboarding: 'softPosOnboarding',
  users: 'users',
  merchants: 'merchants',
  emailLeads: 'emailLeads',
};

/**
 * Check if newly created user exists in onboarding collection
 */
const _onboardingUserExists = async (
  userId: string,
): Promise<FullUser | string> => {
  try {
    const validateOnboardingUser = (
      onboardingUser: Firebase.SoftPosOnboarding,
    ) => !!onboardingUser;
    // We await poll for securing the new user is succesfully created
    // there will be a slight delay in user creation and onboarding user creation; hence the use of a poller
    const user = await poll({
      fn: getOnboardingUser,
      params: userId,
      validate: validateOnboardingUser,
    });
    return user;
  } catch (exception) {
    message.error('Timeout');
    captureError('Validate if onboarding user exists timed out', exception);
    throw exception;
  }
};

/**
 * Set initiali fields in onboarding collection
 */
const setOnboardingStarted = async (userId: string, countryCode: string) => {
  const firebase = getFirebaseInstance();
  const currency = CURRENCY_MAP[countryCode];
  const vat = VAT_MAP[countryCode];
  await firebase
    .firestore()
    .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
    .doc(userId)
    .update({
      vat,
      countryCode,
      currency,
      onboardingStartedAt: Date.now(),
    });
};

const _initializeSignup = async (
  userId: string,
  countryCode: string,
): Promise<FullUser | string> => {
  try {
    const resp = await _onboardingUserExists(userId);
    await setOnboardingStarted(userId, countryCode);
    return resp;
  } catch (exception) {
    throw exception;
  }
};

/**
 * Signup with Google
 */
const firebaseSignupWithGoogle = async (
  countryCode: string,
): Promise<FullUser | string> => {
  try {
    const firebase = getFirebaseInstance();

    const signinResult = await firebase
      .auth()
      .signInWithPopup(new firebaseNative.auth.GoogleAuthProvider());

    if (!signinResult.user) {
      throw new Error('Could not sign user in.');
    }
    if (!signinResult.additionalUserInfo?.isNewUser) {
      captureInfo('User already signed up with Google');
    }
    const resp = await _initializeSignup(signinResult.user.uid, countryCode);
    return resp;
  } catch (exception) {
    throw exception;
  }
};

/**
 * Signup with email
 */
const firebaseSignupWithEmail = async (
  email: string,
  name: string,
  password: string,
  countryCode: string,
): Promise<FullUser | string> => {
  try {
    const firebase = getFirebaseInstance();
    const signinResult = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    if (!signinResult.user) {
      throw new Error('Could not sign up user.');
    }

    if (!signinResult.additionalUserInfo?.isNewUser) {
      captureInfo('User already signed up with Email');
    }

    await signinResult.user.updateProfile({ displayName: name });
    const resp = await _initializeSignup(signinResult.user.uid, countryCode);
    return resp;
  } catch (exception) {
    throw exception;
  }
};

/**
 * Login with email or gmail
 */
const firebaseLogin = async (
  email: string,
  password: string,
): Promise<FullUser> => {
  const firebase = getFirebaseInstance();
  const signinResult = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);
  if (!signinResult.user) {
    throw new Error('Could not sign in user.');
  }
  return getUser(signinResult.user.uid);
};
/**
 * Logout
 */
const firebaseLogout = async (): Promise<void> => {
  const firebase = getFirebaseInstance();
  await firebase.auth().signOut();
};
/**
 * Get user token
 */
const getIdToken = async (): Promise<string> => {
  const firebase = getFirebaseInstance();
  const token = await firebase.auth().currentUser!.getIdToken();
  return token;
};
/**
 * Get firebase user information
 */
const getUserInformation = async (): Promise<UserInformationType> => {
  const firebase = getFirebaseInstance();
  const email = firebase.auth().currentUser!.email;
  if (!email) {
    throw new Error('Could not recieve user information');
  }
  return { email };
};
/**
 * Get user
 */
const getUser = async (userId: string): Promise<FullUser> => {
  const firebase = getFirebaseInstance();
  const docRef = firebase
    .firestore()
    .collection(FB_COLLECTIONS_LOOKUP.users)
    .doc(userId);

  const resp = await docRef.get();
  const user = resp.data() as FirestoreUser;
  const userAuthInfo = await getUserInformation();
  const mergedUser = {
    ...user,
    ...userAuthInfo,
  };
  return mergedUser;
};
/**
 * getOnboardingUser
 */
export const getOnboardingUser = async (
  userId: string,
): Promise<Firebase.SoftPosOnboarding> => {
  const firebase = getFirebaseInstance();
  const docRef = firebase
    .firestore()
    .collection(FB_COLLECTIONS_LOOKUP.softPosOnboarding)
    .doc(userId);

  const resp = await docRef.get();

  return resp.data() as Firebase.SoftPosOnboarding;
};
/**
 * Add email lead
 */
export const addEmailLead = async (email: string): Promise<void> => {
  const firebase = getFirebaseInstance();
  await firebase
    .firestore()
    .collection(FB_COLLECTIONS_LOOKUP.emailLeads)
    .add({ email });
};

/**
 * Exports
 */
export {
  firebaseLogin,
  firebaseLogout,
  firebaseSignupWithEmail,
  firebaseSignupWithGoogle,
  getIdToken,
  getUser,
};
