/**
 * Imports
 */
import { CheckOutlined, ShopOutlined } from '@ant-design/icons';
import { Icon, Text } from 'components';
import { vatDataSelector } from 'features/signupflow/redux/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { styled } from 'theme';

/**
 * Styling
 */
const Root = styled.div`
  border: 1px solid ${(props) => props.theme.colors.Border.BorderBrand};
  border-radius: 4px;
  padding: 24px;
  width: 100%;

  display: flex;
`;
const Aside = styled.div`
  margin-right: 20px;
`;
const CheckWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
const CompanyName = styled(Text)`
  margin-bottom: 4px;
`;
/**
 * Types
 */
export interface VatDataProps {}

/**
 * VatData component
 */
const VatData: React.FC<VatDataProps> = (_props) => {
  const vatData = useSelector(vatDataSelector);

  if (!vatData) {
    return null;
  }

  return (
    <Root>
      <Aside>
        <Icon icon={<ShopOutlined />} color="Orange500" size={20} />
      </Aside>
      <Content>
        <CompanyName size="ui2" bold>
          {vatData.companyName}
        </CompanyName>
        <Text size="ui2" color="info">
          {vatData.address}, {vatData.zipcode} {vatData.city}
        </Text>
        <Text size="ui2" color="info">
          {vatData.vat}
        </Text>
      </Content>
      <CheckWrapper>
        <Icon icon={<CheckOutlined />} color="Orange500" size={28} />
      </CheckWrapper>
    </Root>
  );
};

/**
 * Exports
 */
export { VatData };
