/**
 * Imports
 */
import { IdentityForm, RegistrationNumberForm } from 'features/signupflow';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';

/**
 * Types
 */
export interface RegistrationNumberStepProps {}

/**∏
 * RegistrationNumberStep component
 */
const RegistrationNumberStep: React.FC<RegistrationNumberStepProps> = (
  _props,
) => {
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  if (!onboardingCollection?.businessStructure) {
    return null;
  }
  const { businessStructure } = onboardingCollection;
  const isOther =
    businessStructure === 'other' ||
    businessStructure === 'ngo' ||
    businessStructure === 'public';
  const isLimitedLiabilityOrOther =
    businessStructure === 'limitedLiability' || isOther;

  return (
    <>
      {isLimitedLiabilityOrOther && <RegistrationNumberForm />}
      {onboardingCollection.businessStructure === 'soleTrader' && (
        <IdentityForm />
      )}
    </>
  );
};

/**
 * Exports
 */
export { RegistrationNumberStep };
