/**
 * Notes
 * https://github.com/soywod/react-pin-field/blob/master/src/demo/index.tsx
 */
/**
 * Imports
 */
import { apiSearchCvr } from 'apis';
import { enterVatInfo, vatError } from 'features/signupflow/redux/actions';
import {
  vatDataSelector,
  vatErrorSelector,
} from 'features/signupflow/redux/selectors';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import PinField from 'react-pin-field';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'theme';

/**
 * Styling
 */
const Root = styled.div`
  & input:last-child {
    border-right: 1px solid
      ${(props) => props.theme.colors.Border.BorderPrimary};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  & input:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  & input {
    border-right: none;
    border-top: 1px solid ${(props) => props.theme.colors.Border.BorderPrimary};
    border-bottom: 1px solid
      ${(props) => props.theme.colors.Border.BorderPrimary};
    border-left: 1px solid ${(props) => props.theme.colors.Border.BorderPrimary};
    text-align: center;
    height: 48px;
    width: calc(100% / 8);
  }

  & input:focus {
    border: 1px solid ${(props) => props.theme.colors.Border.BorderBrand};
    outline: none;
  }
  & input::placeholder {
    color: ${(props) => props.theme.colors.Text.Placeholder};
  }

  @media (max-width: 600px) {
    & input {
      height: 34px;
      width: calc(100% / 8);
    }
  }
`;

/**
 * Types
 */
export interface VatPinFieldProps {}

/**
 * VatPinField component
 */
const VatPinField: React.FC<VatPinFieldProps> = (_props) => {
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  const dispatch = useDispatch();
  const vatData = useSelector(vatDataSelector);
  const vatErrorMsg = useSelector(vatErrorSelector);
  /* Ref */
  const ref = React.useRef<HTMLInputElement[]>([]);
  /* In-use variables */
  const hasSearchData = vatData?.vat;
  const hasCvrData = onboardingCollection?.companyRegistrationNumber;

  const _onClear = () => {
    if (ref.current) {
      ref.current.forEach((input) => (input.value = ''));
    }
  };

  const _onAutoFill = (vat: string) => {
    ref.current.forEach(
      (input, index) => (input.value = vat.toString()[index]),
    );
  };

  const _onComplete = async (value: string) => {
    try {
      const resp = await apiSearchCvr(value);
      dispatch(enterVatInfo(resp));
    } catch (error) {
      if (error.message) {
        dispatch(vatError(error.message));
      }
    }
  };

  React.useEffect(() => {
    if (hasSearchData) {
      const { vat } = vatData!;
      _onAutoFill(vat);
    } else if (hasCvrData) {
      const { companyRegistrationNumber } = onboardingCollection!;
      _onAutoFill(companyRegistrationNumber!.toString());
    }
  }, [hasCvrData, hasSearchData]);

  React.useEffect(() => {
    if (!!vatErrorMsg) {
      _onClear();
    }
  }, [vatErrorMsg]);

  React.useEffect(() => {
    // console.log(ref.current[0].);
    // @ts-ignore
    // ref.current?.inputs[0]?.focus();
  }, []);
  return (
    <Root>
      <PinField
        placeholder="0"
        autoFocus
        onComplete={_onComplete}
        length={8}
        inputMode="numeric"
        validate="0123456789"
        ref={ref}
      />
    </Root>
  );
};

/**
 * Exports
 */
export { VatPinField };
