/**
 * Imports
 */
import { createSelector } from '@reduxjs/toolkit';

import { VibrantlyState } from 'store/types';
import { Firebase } from 'types/firebase';
import { UserState } from './reducer';

/**
 * Selectors
 */
export const rootSelector = (state: VibrantlyState): UserState => state.user;

export const maybeUserSelector = createSelector(
  [rootSelector],
  (root) => root.user,
);

export const userSelector = createSelector(
  [rootSelector],
  (root) => root.user!,
);

export const displayNameSelector = createSelector(
  (state: VibrantlyState) => state.firebase.auth,
  (root) => root.displayName,
);

export const firestoreRootSelector = createSelector(
  (state: VibrantlyState) => state.firestore,
  (root) => root,
);

export const onboardingCollectionSelector = createSelector(
  [firestoreRootSelector],
  (firestore) => firestore.data.softPosOnboarding as Firebase.SoftPosOnboarding,
);
