/**
 * Imports
 */
import * as Sentry from '@sentry/react';

/**
 * Log error
 */
const captureError = (message: string, exception: Error): void => {
  Sentry.captureMessage(message, {
    level: Sentry.Severity.Error,
    extra: { exception },
  });
};
/**
 * Log info
 */
const captureInfo = (message: string): void => {
  Sentry.captureMessage(message, {
    level: Sentry.Severity.Log,
  });
};

/**
 * Exports
 */
export { captureError, captureInfo };
