/**
 * Imports
 */
import { Spinner, Text } from 'components';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { styled } from 'theme';

/**
 * Styling
 */
const Root = styled.div`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Content = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Heading = styled(Text)`
  margin-bottom: 24px;
`;

/**
 * Types
 */
export interface OwnerLoadingProps {
  signupDone: boolean;
}

/**
 * OwnerLoading component
 */
const OwnerLoading: React.FC<OwnerLoadingProps> = ({ signupDone }) => {
  return (
    <Root>
      <Spinner showSpinner={!signupDone} size={48} />
      <Content>
        <Heading size="h3">
          <Trans
            i18nKey={
              signupDone
                ? 'OwnerStep.creating_account.heading_2'
                : 'OwnerStep.creating_account.heading_1'
            }
            values={{ company: 'Vibrant' }}
          />
        </Heading>
        <Text size="p1">
          <Trans
            i18nKey={
              signupDone
                ? 'OwnerStep.creating_account.subheading_2'
                : 'OwnerStep.creating_account.subheading_1'
            }
          />
        </Text>
      </Content>
    </Root>
  );
};

/**
 * Exports
 */
export { OwnerLoading };
