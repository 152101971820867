/**
 * Imports
 */
import { VibrantIcon } from 'components';
import * as React from 'react';
import { css } from 'styled-components';

import { styled, VibrantColors } from 'theme';

/**
 * Styling
 */
const SNACKBAR_TYPES: SnackbarLookup = {
  warning: css`
    background: ${VibrantColors.Feedback.Warning};
    color: ${VibrantColors.Text.Warning};

    & svg {
      color: ${VibrantColors.Shades.Yellow500};
      margin-right: 14px;
    }
  `,
  error: css`
    background: ${VibrantColors.Feedback.Danger};
    color: ${VibrantColors.Text.Danger};

    & svg {
      color: ${VibrantColors.Shades.Red500};
      margin-right: 14px;
    }
  `,
  info: css`
    background: ${VibrantColors.Feedback.Info};
    color: ${VibrantColors.Text.Info};

    & svg {
      color: ${VibrantColors.Shades.Blue500};
      margin-right: 14px;
    }
  `,
  success: css`
    background: ${VibrantColors.Feedback.Success};
    color: ${VibrantColors.Text.Success};

    & svg {
      color: ${VibrantColors.Shades.Green500};
      margin-right: 14px;
    }
  `,
};
const Root = styled.div<{ type: SnackbarTypes }>`
  ${(props) => props.type && SNACKBAR_TYPES[props.type]}
  font-weight: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20.8px;
  border-radius: 4px;
  padding: 12px 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Content = styled.div``;

/**
 * Types
 */
export type SnackbarLookup = {
  [k in SnackbarTypes]: any;
};
type SnackbarTypes = 'error' | 'warning' | 'info' | 'success';
export interface SnackbarProps {
  type: SnackbarTypes;
}

/**
 * Snackbar component
 */
const Snackbar: React.FC<SnackbarProps> = ({ children, type }) => {
  return (
    <Root type={type}>
      <VibrantIcon icon="sun" size={20} />
      <Content>{children}</Content>
    </Root>
  );
};

/**
 * Exports
 */
export { Snackbar };
