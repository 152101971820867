/**
 * Imports
 */
// Sentry setup
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { PROJECT_CONFIG } from 'config';
import { SplashScreen } from 'features/navigation/components';
import firebase from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Provider, ProviderProps } from 'react-redux';
import { hotjar } from 'react-hotjar';
/* Firebase*/
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { RootRouter } from 'router';
import { ThemeProvider } from 'theme';
import './index.css';
import reportWebVitals from './reportWebVitals';
// Store setup
import { storePromise } from './store';
// Aqntd style overrides
import './styles/antd/less/antd-overrides.less';
import './styles/fonts/basier.css';
import './translations/config';
import ReactGA from 'react-ga';

// Sentry setup
Sentry.init({
  enabled: PROJECT_CONFIG.environment === 'production',
  dsn: PROJECT_CONFIG.sentryDsn,
  environment: PROJECT_CONFIG.environment,
  integrations: [new Integrations.BrowserTracing()],
  release: PROJECT_CONFIG.gitRelease,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

/** Add google analytics */
try {
  if (PROJECT_CONFIG.environment === 'production') {
    console.log('Google analytics initialized');
    ReactGA.initialize('UA-184339813-1');
  }
} catch (e) {
  console.log('Google analytics failed');
  console.log(e);
}
/**
 * Constants
 */
const REACT_REDUX_FIREBASE_CONFIG = {
  // userProfile: 'fooUsers',
  // useFirestoreForProfile: true,
};

/**
 * `App` component
 */
const App = () => {
  /* Initialize hooks */
  useTranslation();
  /* Local state */
  const [store, setStore] = React.useState<ProviderProps['store'] | null>(null);
  /**
   * Initialize redux store
   */
  React.useEffect(() => {
    storePromise
      .then((_store) => setStore(_store))
      .catch(() => console.log('Error occurred getting store ready'));
  }, []);

  /**
   * Initialize hotjar
   */
  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      hotjar.initialize(2626582, 6);
    }
  }, []);

  if (!store) {
    return <SplashScreen />;
  }

  const reactReduxFirebaseProps = {
    firebase,
    config: REACT_REDUX_FIREBASE_CONFIG,
    dispatch: store.dispatch,
    createFirestoreInstance,
  };

  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...reactReduxFirebaseProps}>
        <ThemeProvider>
          <RootRouter />
        </ThemeProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

/**
 * Entry point
 */
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
