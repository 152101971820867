/**
 * Imports
 */
import { createReducer } from '@reduxjs/toolkit';
import { VibrantAPI } from 'types/vibrant';
import {
  EnterVatErrorAction,
  enterVatInfo,
  EnterVatInfoAction,
  setcountryCode,
  setDisplayName,
  SetDisplayNameAction,
  SetSignupCountryTypeAction,
  SignupInitiatedAction,
  signupInititated,
  vatError,
} from './actions';

/**
 * Types
 */
export interface SignupState {
  countryCode: null | string;
  displayName: string;
  signupInititated: boolean;
  step: number;
  vatData: null | VibrantAPI.GetVATResult;
  vatError: null | VibrantAPI.GetVATError;
}

/**
 * Constants
 */
const INITIAL_STATE: SignupState = {
  countryCode: null,
  displayName: '',
  signupInititated: false,
  step: 0,
  vatData: null,
  vatError: null,
};

/**
 * `signupflow` reducer
 */
const signupflowReducer = createReducer(INITIAL_STATE, {
  /**
   * SIGNUP_INITIATED
   */
  [signupInititated.type]: (state, action: SignupInitiatedAction) => {
    state.signupInititated = action.payload.signupInititated;
  },
  /**
   * SET_DISPLAY_NAME
   */
  [setDisplayName.type]: (state, action: SetDisplayNameAction) => {
    state.displayName = action.payload.displayName;
  },
  /**
   * SET_SIGNUP_COUNTRY
   */
  [setcountryCode.type]: (state, action: SetSignupCountryTypeAction) => {
    state.countryCode = action.payload.countryCode;
  },
  /**
   * ENTER_VAT_INFO
   */
  [enterVatInfo.type]: (state, action: EnterVatInfoAction) => {
    state.vatData = !action.payload.vat ? null : action.payload;
    state.vatError = !action.payload.vat ? state.vatError : null;
  },
  /**
   * VAT_ERROR
   */
  [vatError.type]: (state, action: EnterVatErrorAction) => {
    state.vatError = action.payload;
    state.vatData = null;
  },
});

/**
 * Exports
 */
export default signupflowReducer;
