/**
 * Imports
 */
import { BaseLayout } from 'features/navigation/components';
import { SignupLayout } from 'features/signupflow';
import { BusinessActivityStep } from 'features/signupflow/components/Steps';
import * as React from 'react';

/**
 * Types
 */
export interface BusinessActivityPageProps {}

/**
 * BusinessActivityPage component
 */
const BusinessActivityPage: React.FC<BusinessActivityPageProps> = (_props) => {
  return (
    <BaseLayout>
      <SignupLayout>
        <BusinessActivityStep />
      </SignupLayout>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { BusinessActivityPage };
