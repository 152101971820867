/**
 * Project API url
 */
const getprojectApiUrl = () => {
  const hostname = window.location.hostname;
  if (hostname.includes('dev')) {
    return 'https://api.dev.vibrant.one';
  }
  if (hostname.includes('uat')) {
    return 'https://api.uat.vibrant.one';
  }
  if (hostname.includes('signup.vibrant.io')) {
    return 'https://api.vibrant.one';
  }
  /// should be used for localhost
  // return 'https://api.vibrant.one';
  // return 'https://api.uat.vibrant.one';
  return 'https://api.dev.vibrant.one';
  // return 'http://localhost:3002';
};
export const PROJECT_API_URL = getprojectApiUrl();
/**
 * Firebase environment settings
 */
const getFirebaseConfig = () => {
  /**
   * Uat
   */
  if (PROJECT_API_URL.includes('uat')) {
    console.log('Running uat');
    return {
      apiKey: 'AIzaSyDVDd4j87hxMN6VRnxKqV9Pv2ynjCDp_CI',
      appId: '1:922162229168:web:6807b7c80433568a5a7075',
      authDomain: 'vibrant-fb-uat.firebaseapp.com',
      dashboardURL: 'https://me.uat.vibrant.one',
      databaseURL: 'https://vibrant-fb-uat.firebaseio.com',
      environment: 'uat',
      gitRelease: 'PENDING_1.0.0',
      measurementId: 'G-3KQTJVDM82',
      messagingSenderId: '922162229168',
      projectId: 'vibrant-fb-uat',
      sentryDsn:
        'https://73be25b50269457e92ad5a7c46d5afc1@o551933.ingest.sentry.io/5675936',
      signupURL: 'https://signup.uat.vibrant.one',
      storageBucket: 'vibrant-fb-uat.appspot.com',
    };
  }
  /**
   * Production
   */
  if (PROJECT_API_URL.includes('api.vibrant.one')) {
    console.log('Running production');
    return {
      apiKey: 'AIzaSyAbIbKSw_SmtYM8WLqs9ZUoFsb07q7i28o',
      appId: '1:836686013166:web:7dd1e6a0bdf1754ec409ff',
      authDomain: 'vibrantly-production.firebaseapp.com',
      dashboardURL: 'https://me.vibrant.io',
      databaseURL: 'https://vibrantly-production.firebaseio.com',
      environment: 'production',
      gitRelease: 'PENDING_1.0.0',
      measurementId: 'G-PFB0DTTY8V',
      messagingSenderId: '836686013166',
      projectId: 'vibrantly-production',
      sentryDsn:
        'https://73be25b50269457e92ad5a7c46d5afc1@o551933.ingest.sentry.io/5675936',
      signupURL: 'https://signup.vibrant.one',
      storageBucket: 'vibrantly-production.appspot.com',
    };
  }
  console.log('Running dev');
  /**
   * Dev
   */
  return {
    apiKey: 'AIzaSyCv-etousHNny0jlIiLLkXopYTyBJbeBLY',
    appId: '1:400993474174:web:b297881fc814d5d3d42a2f',
    authDomain: 'staging-727fe.firebaseapp.com',
    // dashboardURL: 'https://me.uat.vibrant.one',
    dashboardURL: 'https://softpos-merchant.dev.vibrant.one',
    databaseURL: 'https://staging-727fe.firebaseio.com',
    environment: 'localhost',
    gitRelease: 'PENDING_1.0.0',
    measurementId: 'G-RP87MB97P2',
    messagingSenderId: '400993474174',
    projectId: 'staging-727fe',
    sentryDsn:
      'https://73be25b50269457e92ad5a7c46d5afc1@o551933.ingest.sentry.io/5675936',
    signupURL: 'https://signup.dev.vibrant.one',
    storageBucket: 'staging-727fe.appspot.com',
  };
};
export const FIREBASE_CONFIG = getFirebaseConfig();
/**
 * Project environment settings
 */
const getProjectConfig = () => {
  if (PROJECT_API_URL.includes('uat')) {
    console.log('Running uat');
    return {
      environment: 'uat',
      gitRelease: 'PENDING_1.0.0',
      sentryDsn:
        'https://73be25b50269457e92ad5a7c46d5afc1@o551933.ingest.sentry.io/5675936',
    };
  }
  if (PROJECT_API_URL.includes('api.vibrant.one')) {
    console.log('Running production');
    return {
      environment: 'production',
      gitRelease: 'PENDING_1.0.0',
      sentryDsn:
        'https://73be25b50269457e92ad5a7c46d5afc1@o551933.ingest.sentry.io/5675936',
    };
  }
  console.log('Running dev');
  return {
    environment: 'localhost',
    gitRelease: 'PENDING_1.0.0',
    sentryDsn:
      'https://73be25b50269457e92ad5a7c46d5afc1@o551933.ingest.sentry.io/5675936',
  };
};
export const PROJECT_CONFIG = getProjectConfig();
