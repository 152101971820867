/**
 * Imports
 */
import { createAction } from '@reduxjs/toolkit';
import { VibrantlyAction } from 'store/types';
import { CurrentRoutePayload } from '../types';

/**
 * Constants
 */
const PREFIX = 'navigation';

/**
 * SET_CURRENT_ROUTE
 */
export const SET_CURRENT_ROUTE = `${PREFIX}/SET_CURRENT_ROUTE`;
export type SetCurrentRouteAction = VibrantlyAction<CurrentRoutePayload>;
export const setCurrentRoute = createAction<SetCurrentRouteAction['payload']>(
  SET_CURRENT_ROUTE,
);
/**
 * OPEN_MOBILE_MENU
 */
export const OPEN_MOBILE_MENU = `${PREFIX}/OPEN_MOBILE_MENU`;
export type OpenMobileMenuAction = VibrantlyAction<Record<string, unknown>>;
export const openMobileMenu = createAction<OpenMobileMenuAction['payload']>(
  OPEN_MOBILE_MENU,
);
/**
 * CLOSE_MOBILE_MENU
 */
export const CLOSE_MOBILE_MENU = `${PREFIX}/CLOSE_MOBILE_MENU`;
export type CloseMobileMenuAction = VibrantlyAction<Record<string, unknown>>;
export const closeMobileMenu = createAction<CloseMobileMenuAction['payload']>(
  CLOSE_MOBILE_MENU,
);
