/**
 * Imports
 */
import { createAction } from '@reduxjs/toolkit';
import { VibrantlyAction } from 'store/types';
import { LanguageCodeTypes } from 'utils';

import { FullUser } from '../types';

/**
 * Constants
 */
const PREFIX = 'user';

/**
 * USER_UPDATED
 */
export const USER_UPDATED = `${PREFIX}/USER_UPDATED`;
export type UserUpdatedAction = VibrantlyAction<FullUser>;
export const userUpdated = createAction<UserUpdatedAction['payload']>(
  USER_UPDATED,
);
/**
 * CHANGE_LANGUAGE
 */
export const CHANGE_LANGUAGE = `${PREFIX}/CHANGE_LANGUAGE`;
export type ChangeLanguage = VibrantlyAction<{
  language: LanguageCodeTypes;
}>;
/**
 * LOGOUT
 */
export const LOGOUT = `${PREFIX}/LOGOUT`;
export type LogoutAction = VibrantlyAction<any>;
export const logout = createAction<LogoutAction['payload']>(LOGOUT);
