/**
 * Imports
 */
import { Form, notification } from 'antd';
import { Button, Input, SubText } from 'components';
import { useSignupFlow } from 'features/signupflow';
import { setDisplayName } from 'features/signupflow/redux/actions';
import { countryCodeSelector } from 'features/signupflow/redux/selectors';
import {
  FB_ERR_ALREADY_USER,
  FB_ERR_WRONG_PASSWORD,
  firebaseLogin,
  firebaseSignupWithEmail,
} from 'firebaseAPI';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'router';
import { styled } from 'theme';

/**
 * Types
 */
type SignupValuesType = {
  email: string;
  name: string;
  password: string;
};

/**
 * Validation messages
 */
const FormItemButton = styled(Form.Item)`
  && {
    margin-bottom: 8px;
  }
`;

/**
 * Validation messages
 */
/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
};
/* eslint-enable no-template-curly-in-string */

/**
 * SignupForm component
 */
const SignupForm: React.FC = () => {
  const [isLoading, setIsloading] = React.useState(false);
  const { t } = useTranslation();
  const navigation = useHistory();
  const dispatch = useDispatch();
  const countryCode = useSelector(countryCodeSelector);
  const {
    handlers: { onNextStep },
  } = useSignupFlow();

  const _handleExistingUser = async (email: string, password: string) => {
    try {
      await firebaseLogin(email, password);
      notification.info({
        message: t('Notifications.error.user_already_exists_title'),
        description: t('Notifications.error.user_already_exists_msg'),
      });
      setIsloading(false);
      onNextStep();
    } catch (exception) {
      if (exception?.code === FB_ERR_WRONG_PASSWORD) {
        notification.info({
          message: t('Notifications.error.user_wrong_password_title'),
          description: t('Notifications.error.user_wrong_password_msg'),
        });
        setIsloading(false);
        navigation.push(ROUTES.login.path);
        setIsloading(false);
      } else {
        setIsloading(false);
      }
    }
  };

  const _onFinish = async (values: SignupValuesType) => {
    const { email, name, password } = values;
    try {
      setIsloading(true);
      await firebaseSignupWithEmail(email, name, password, countryCode!);
      dispatch(setDisplayName({ displayName: name }));
      setIsloading(false);
      onNextStep();
    } catch (exception) {
      if (exception?.code === FB_ERR_ALREADY_USER) {
        _handleExistingUser(email, password);
      } else {
        setIsloading(false);
      }
    }
  };

  return (
    <Form
      validateMessages={validateMessages}
      layout="vertical"
      name="signup_form"
      onFinish={_onFinish}
    >
      <Form.Item
        label={t('AuthStep.signup_email.label_name')}
        name="name"
        rules={[
          {
            message: t('AuthStep.signup_email.validation_message_name'),
            required: true,
          },
        ]}
      >
        <Input placeholder="Mary Williams" />
      </Form.Item>
      <Form.Item
        label={t('AuthStep.signup_email.label_email')}
        name="email"
        rules={[
          {
            message: t('AuthStep.signup_email.validation_message_email'),
            required: true,
            type: 'email',
          },
        ]}
      >
        <Input placeholder="hello@example.com" />
      </Form.Item>
      <Form.Item
        label={t('AuthStep.signup_email.label_password')}
        name="password"
        rules={[
          {
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
            required: true,
            message: t('AuthStep.signup_email.validation_message_password'),
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <FormItemButton>
        <Button type="primary" htmlType="submit" block loading={isLoading}>
          {t('AuthStep.signup_email.btn_email_signup')}
        </Button>
      </FormItemButton>

      <SubText
        text={
          <span>
            <Trans
              i18nKey="AuthStep.accept_terms_text"
              values={{ company: 'Vibrant´s' }}
            >
              <a
                href={t('AuthStep.merchant_agreement_link')}
                target="_blank"
                rel="noreferrer"
              />
              <a
                href={t('AuthStep.privacy_policy__link')}
                target="_blank"
                rel="noreferrer"
              />
            </Trans>
          </span>
        }
      />
    </Form>
  );
};

/**
 * Exports
 */
export { SignupForm };
