/**
 * Imports
 */

import * as Sentry from '@sentry/react';
import { apiCreateCustomToken } from 'apis';
import { PROJECT_CONFIG } from 'config';
import { MobileMenu, SplashScreen } from 'features/navigation/components';
import { NoMatchPage } from 'features/navigation/pages';
import { setCurrentRoute } from 'features/navigation/redux/actions';
import { setcountryCode } from 'features/signupflow/redux/actions';
import { signupInititatedSelector } from 'features/signupflow/redux/selectors';
import { logout, userUpdated } from 'features/user/redux/actions';
import {
  maybeUserSelector,
  onboardingCollectionSelector,
  userSelector,
} from 'features/user/redux/selectors';
import { FullUser } from 'features/user/types';
import {
  FB_COLLECTIONS_LOOKUP,
  firebaseLogout,
  getFirebaseInstance,
  getUser,
} from 'firebaseAPI';
import * as React from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  PrivateRoute,
  PublicRoute,
  ROUTES,
  RoutesType,
  RouteType,
} from 'router';
import { styled } from 'theme';
import { Firebase } from 'types/firebase';
import { poll, redirectToMerchantDashboard } from 'utils';
import { useTranslation } from 'react-i18next';
import { LanguageCodeTypes } from 'utils';
const firebase = getFirebaseInstance();
/**
 * Styling
 */
const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.Background.White};

  height: 100%;
  min-height: 100%;
`;

/**
 * Copied directly from https://v5.reactrouter.com/web/example/query-parameters
 */
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Types
 */
export interface RootRouterProps {}
/**
 * Redirect returning user to last signup step
 */
const _redirect = async () => {
  const { token } = await apiCreateCustomToken();
  redirectToMerchantDashboard(token);
};

/**
 * Check last step
 */
const _checkLastStep = (
  onboardingCollection: Firebase.SoftPosOnboarding,
): RouteType | void => {
  if (!onboardingCollection.countryCode) {
    return ROUTES.home;
  }
  if (onboardingCollection.countryCode !== 'ES') {
    // Is done
    if (onboardingCollection.onboardingDone) {
      _redirect();
    }
    // Missing companyRegistrationNumber
    else if (!onboardingCollection.companyRegistrationNumber) {
      return ROUTES['registration-number'];
    }
    // Missing phone
    else if (!onboardingCollection.businessLegalName) {
      return ROUTES['company-details'];
    }
    // Missing businessActivityDescription
    else if (!onboardingCollection.businessActivityDescription) {
      return ROUTES['business-activity'];
    }
    // Missing monthlyTurnover
    else if (!onboardingCollection.monthlyTurnover) {
      return ROUTES.revenue;
    }
    // Missing iban
    else if (!onboardingCollection.iban) {
      return ROUTES.bank;
    }
    // Missing ownerData
    else if (!onboardingCollection.ownerData?.map((o: any) => o.roleDirector)) {
      return ROUTES.ceo;
    }
    // Missing photoId
    else if (
      onboardingCollection.ownerData?.map((o: any) => o.roleDirector) &&
      onboardingCollection.ownerData?.map((o: any) => o.photoId === null)
    ) {
      return ROUTES.owner;
    }
  } else {
    // Is done
    if (onboardingCollection.onboardingDone) {
      _redirect();
    }
    // Missing businessStructure
    else if (!onboardingCollection.businessStructure) {
      return ROUTES['business-structure'];
    }
    // Missing companyRegistrationNumber
    else if (!onboardingCollection.companyRegistrationNumber) {
      return ROUTES['registration-number'];
    }
    // Missing businessLegalName
    else if (!onboardingCollection.businessLegalName) {
      return ROUTES['company-details'];
    }
    // Missing companyDocumentsPath
    else if (!onboardingCollection.companyDocumentsPath) {
      return ROUTES['company-documents'];
    }
    // Missing companyDocumentsPath
    else if (!onboardingCollection.businessActivityDescription) {
      return ROUTES['business-activity'];
    }
    // Missing monthlyTurnover
    else if (!onboardingCollection.monthlyTurnover) {
      return ROUTES.revenue;
    }
    // Missing iban
    else if (!onboardingCollection.iban) {
      return ROUTES.bank;
    }
    // Missing ownerData
    else if (!onboardingCollection.ownerData?.map((o: any) => o.roleDirector)) {
      return ROUTES.ceo;
    }
    // Missing photoId
    else if (
      onboardingCollection.ownerData?.map((o: any) => o.roleDirector) &&
      onboardingCollection.ownerData?.map((o: any) => o.photoId === null)
    ) {
      return ROUTES.owner;
    }
  }
};

/**
 * `AuthenticatedContent` component
 */
const AuthenticatedContent = ({ children }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  const signupInititated = useSelector(signupInititatedSelector);

  /* Firestore */
  useFirestoreConnect([
    {
      collection: FB_COLLECTIONS_LOOKUP.softPosOnboarding,
      storeAs: FB_COLLECTIONS_LOOKUP.softPosOnboarding,
      doc: user.id,
    },
  ]);

  /**
   * Go to last step if user is returning user and didn't finish the sigupflow
   */
  const _goToUsersLastStep = () => {
    dispatch(
      setcountryCode({
        countryCode: onboardingCollection.countryCode as string,
      }),
    );
    const last = _checkLastStep(onboardingCollection);
    if (last) {
      history.push(last.path!);
    }
  };

  /**
   * Logout
   */
  const _logout = async () => {
    if (user) {
      await firebaseLogout();
    }
    history.push(ROUTES.home.path);
  };

  React.useEffect(() => {
    if (onboardingCollection?.onboardingId) {
      !signupInititated && _goToUsersLastStep();
    }
  }, [onboardingCollection?.onboardingId]);

  /**
   * Check if user is on logout page
   */
  React.useEffect(() => {
    const pathId = history.location.pathname.replace('/', '');
    const formattedId = pathId === '' ? 'home' : (pathId as RoutesType);
    // _setCurrentRoute(formattedId); is this needed?
    if (formattedId === 'logout') {
      _logout();
    }
  }, []);

  // We wait for onboardingCollection be loaded
  if (!onboardingCollection?.onboardingId) {
    return <SplashScreen />;
  }

  return children;
};

/**
 * `HistoryListener` component
 */
const HistoryListener = ({ children }: any) => {
  const history = useHistory();
  const query = useQuery();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(maybeUserSelector);
  const signupInititated = useSelector(signupInititatedSelector);
  const [isAuthenticationLoaded, setIsAuthenticationLoaded] = React.useState(
    false,
  );
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  // Local route state
  const [_route, setRoute] = React.useState({
    to: location.pathname,
    from: location.pathname,
  });

  /**
   * Set page tags for Sentry
   */
  const _setPageTag = (pathId: RoutesType) => {
    const pageName = (ROUTES[pathId]?.pageId || 'N/A') as string;
    Sentry.setTag('page_id', pageName);

    // Adds Google Analytics
    if (PROJECT_CONFIG.environment === 'production') {
      try {
        ReactGA.pageview(pageName);
      } catch (e) {
        console.log('GA - pageView failed');
        console.log(e);
      }
    }
  };

  /**
   * Set current route information
   */
  const _setCurrentRoute = (pathId: RoutesType) => {
    try {
      const routeData = {
        pageId: ROUTES[pathId].pageId,
        path: ROUTES[pathId].path,
        route: ROUTES[pathId].route,
      };
      dispatch(setCurrentRoute(routeData));
    } catch (exception) {
      console.log(exception);
    }
  };

  const { i18n } = useTranslation();
  const _changeLanguage = (language: LanguageCodeTypes) => {
    i18n.changeLanguage(language);
  };
  React.useEffect(() => {
    if (query.get('countryCode')) {
      const countryCode = query.get('countryCode')!.toLowerCase();
      dispatch(setcountryCode({ countryCode }));
      if (countryCode === 'es') {
        _changeLanguage('es');
      } else if (countryCode === 'dk') {
        _changeLanguage('da');
      } else if (countryCode === 'gb') {
        _changeLanguage('en');
      }
      history.push(ROUTES.auth.path);
    }
  }, []);

  /**
   * Sideeffect for setting page and signupflow information
   */
  React.useEffect(() => {
    const pathId = history.location.pathname.replace('/', '');
    const formattedId = pathId === '' ? 'home' : (pathId as RoutesType);

    _setPageTag(formattedId);
    _setCurrentRoute(formattedId);
    setRoute((prev) => ({ to: location.pathname, from: prev.to }));
  }, [location]);

  /**
   * Listen to onAuthStateChanged
   */
  React.useEffect(() => {
    if (firebase) {
      firebase.auth().onAuthStateChanged(async (authUser) => {
        setIsAuthenticationLoaded(false);
        if (authUser) {
          const validateUser = (user: FullUser) => !!user.id;
          const user = await poll({
            fn: getUser,
            params: authUser.uid,
            validate: validateUser,
          });
          if (user) {
            dispatch(userUpdated(user));
            Sentry.setUser({ id: user.id });
          }
          setIsAuthenticated(true);
        } else {
          dispatch(logout({}));
          Sentry.configureScope((scope) => scope.setUser(null));
          setIsAuthenticated(false);
        }
        setIsAuthenticationLoaded(true);
      });
    }
  }, []);

  if (!signupInititated && !isAuthenticationLoaded) {
    return <SplashScreen />;
  }
  if (isAuthenticated && !user) {
    return <SplashScreen />;
  }
  if (isAuthenticated && user) {
    return <AuthenticatedContent>{children}</AuthenticatedContent>;
  }

  return children;
};

/**
 * RootRouter component
 */
const RootRouter: React.FC<RootRouterProps> = () => {
  return (
    <BrowserRouter>
      <HistoryListener>
        <Root>
          <MobileMenu />
          <Switch>
            {Object.values(ROUTES).map((route) => {
              const RouteContainer = route.isPrivate
                ? PrivateRoute
                : PublicRoute;

              return (
                <RouteContainer
                  exact
                  key={route.route}
                  path={route.route}
                  component={route.component}
                  dontCheckAuth={route.dontCheckAuth}
                />
              );
            })}

            <Route path="*" component={NoMatchPage} />
          </Switch>
        </Root>
      </HistoryListener>
    </BrowserRouter>
  );
};

/**
 * Exports
 */
export { RootRouter };
