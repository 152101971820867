/**
 * Imports
 */
import { Anchor, Logo, Text, VibrantIcon } from 'components';
import { currentRouteSelector } from 'features/navigation/redux/selectors';
import { StepIndex } from 'features/signupflow';
import { useSignupFlow } from 'features/signupflow/api';
import { LanguageSelector } from 'features/user/components';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTES, RoutesType } from 'router';
import { styled } from 'theme';

/**
 * Styling
 */
const Root = styled.div`
  padding: 32px 64px 32px;
  min-height: 100vh;
  width: 100%;
  max-width: 488px;
  min-width: 488px;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;
const TopContent = styled.div``;
const BottomContent = styled.div``;
const Row = styled.div<{ push?: boolean }>`
  display: flex;
  justify-content: ${(props) => props.push && 'space-between'};
  align-items: center;
`;
const SupportRow = styled(Row)`
  margin-top: 64px;
`;
const LogoRow = styled(Row)`
  margin-bottom: 32px;
`;

const LogoWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  max-width: 106px;

  display: flex;
  flex: 1;
  align-self: center;
  justify-content: center;
`;
const Subheading = styled(Text)`
  margin-top: 16px;
`;
const Breadcrumb = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 24px;
`;
const CardIcon = styled.div`
  background-color: white;
  background: #ffffff;
  border-radius: 2px;
  height: 24px;
  width: 38px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
const BreadcrumbLink = styled(Text)`
  margin-left: 12px;
`;
const SupportWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const CardWrapper = styled.div`
  display: flex;
  & ${CardIcon}:first-child {
    margin-right: 16px;
  }
`;

/**
 * Types
 */
export type SubheadingLookup = {
  [k in RoutesType]: { title: React.ReactNode; subheading: React.ReactNode };
};
export type BreadcrumbState = 'active' | 'passed' | 'next';
export interface StepOverviewProps {}

/**
 * StepOverview component
 */
const StepOverview: React.FC<StepOverviewProps> = (_props) => {
  const navigation = useHistory();
  const { signupSteps, currentStep } = useSignupFlow();
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  const currentRoute = useSelector(currentRouteSelector);
  const showStepOverview = currentRoute.pageId !== 'home';

  const _goToStep = (path: string) => {
    navigation.push(path);
  };

  const _goToHome = () => {
    navigation.push(ROUTES.home.path);
  };

  return (
    <Root>
      <TopContent>
        <LogoRow push>
          <LogoWrapper onClick={_goToHome}>
            <Logo size={48} />
          </LogoWrapper>
          <LanguageSelector />
        </LogoRow>
        {showStepOverview && <StepIndex />}
        <Row>
          <Subheading size="p2">
            {showStepOverview && (
              <>
                {onboardingCollection?.businessStructure === 'soleTrader' &&
                currentStep?.pageSubheadingAlt
                  ? currentStep?.pageSubheadingAlt()
                  : currentStep.pageSubheading()}
              </>
            )}
            {!showStepOverview && <Trans i18nKey="CountryStep.subheading" />}
          </Subheading>
        </Row>
      </TopContent>
      <BottomContent>
        {showStepOverview && (
          <>
            {Object.values(signupSteps)
              .sort((a, b) => a.signupStep - b.signupStep)
              .map((step, index) => {
                if (step.signupStep === -1) {
                  return null;
                }
                const state =
                  step.signupStep === currentStep.signupStep
                    ? 'active'
                    : step.signupStep < currentStep.signupStep
                    ? 'passed'
                    : 'next';

                return (
                  <Breadcrumb key={index}>
                    <VibrantIcon
                      icon={
                        state === 'active'
                          ? 'radio-checked'
                          : state === 'passed'
                          ? 'checkmark-checked'
                          : 'radio-unchecked'
                      }
                      size={16}
                      color={state === 'passed' ? 'Orange500' : 'Grey100'}
                    />
                    <BreadcrumbLink
                      size="ui2"
                      bold={state === 'active'}
                      color={state === 'active' ? 'heading' : 'ui'}
                      onClick={() =>
                        state === 'passed' ? _goToStep(step.path) : null
                      }
                    >
                      {step.pageName()}
                    </BreadcrumbLink>
                  </Breadcrumb>
                );
              })}
          </>
        )}
        <SupportRow push>
          <SupportWrapper>
            <Text size="ui2" color="ui">
              <Trans i18nKey="_Common.actions.btn_support_text" />
            </Text>
            <Anchor size="ui2" bold href="https://www.vibrant.io/contact">
              <Trans i18nKey="_Common.actions.btn_support" />
            </Anchor>
          </SupportWrapper>
          <CardWrapper>
            <CardIcon>
              <VibrantIcon size={22} icon="visa" />
            </CardIcon>
            <CardIcon>
              <VibrantIcon size={19} icon="master-card" />
            </CardIcon>
          </CardWrapper>
        </SupportRow>
      </BottomContent>
    </Root>
  );
};

/**
 * Exports
 */
export { StepOverview };
