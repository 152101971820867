/**
 * Imports
 */
import * as React from 'react';
import { LoginForm } from 'features/user/components';
import { BaseLayout } from 'features/navigation/components';

/**
 * LoginPage component
 */
const LoginPage: React.FC = () => {
  return (
    <BaseLayout>
      <LoginForm />
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { LoginPage };
