/**
 * Imports
 */
import { IbanForm } from 'features/signupflow';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { BankForm } from './BankForm';

/**
 * BankStep component
 */
const BankStep: React.FC = () => {
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  return (
    <>
      {onboardingCollection?.countryCode === 'DK' ? <BankForm /> : <IbanForm />}
    </>
  );
};

/**
 * Exports
 */
export { BankStep };
