/**
 * Imports
 */
import { FileTextOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { Icon, Upload } from 'components';
import {
  NextButton,
  SignupText,
  useMerchantApi,
  useSignupFlow,
} from 'features/signupflow';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { captureError, InputUploadContent, normalizeDocuments } from 'utils';

/**
 * Types
 */
export interface SoleTraderDocumentsProps {}

export type CompanyDocumentsFormRow = {
  address: InputUploadContent;
  vat: InputUploadContent;
};

/**
 * SoleTraderDocuments component
 */
const SoleTraderDocuments: React.FC<SoleTraderDocumentsProps> = (_props) => {
  const {
    handlers: { onNextStep },
  } = useSignupFlow();
  const {
    handlers: { onUpdateCompanyDocuments },
  } = useMerchantApi();
  /* Local state */
  const [form] = Form.useForm();
  const [isValid, setIsValid] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  /* Redux */
  const onboardingCollection = useSelector(onboardingCollectionSelector);

  const _onFinish = async (values: CompanyDocumentsFormRow) => {
    setIsLoading(true);
    try {
      const documents = normalizeDocuments(values);
      await onUpdateCompanyDocuments(documents);
      setIsLoading(false);
      onNextStep();
    } catch (exception) {
      setIsLoading(false);
      captureError('Error: Failed to update company documents', exception);
    }
  };

  const _isValid = (allValues: CompanyDocumentsFormRow) => {
    if (Object.keys(allValues).length > 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const _onChange = (
    _values: CompanyDocumentsFormRow,
    allValues: CompanyDocumentsFormRow,
  ) => {
    _isValid(allValues);
  };

  /**
   * Set valid if companyDocumentsPath
   */
  React.useEffect(() => {
    if (onboardingCollection.companyDocumentsPath) {
      setIsValid(true);
    }
  }, []);

  return (
    <>
      <SignupText
        subheading={<Trans i18nKey="CompanyDocuments.sole_trader.heading" />}
      />

      <Form
        layout="vertical"
        form={form}
        onValuesChange={_onChange}
        onFinish={_onFinish}
      >
        <Upload
          name={'vat'}
          tooltip={<Trans i18nKey="CompanyDocuments.sole_trader.vat_tooltip" />}
          icon={<Icon icon={<FileTextOutlined />} size={40} color="Grey500" />}
          label={
            <b>
              <Trans i18nKey="_Common.labels.upload_document" />
            </b>
          }
          text={<Trans i18nKey="CompanyDocuments.sole_trader.vat_label" />}
        />
        <Upload
          name={'address'}
          tooltip={
            <Trans i18nKey="CompanyDocuments.sole_trader.address_tooltip" />
          }
          icon={<Icon icon={<FileTextOutlined />} size={40} color="Grey500" />}
          label={
            <b>
              <Trans i18nKey="CompanyDocuments.sole_trader.address_title" />
            </b>
          }
          text={<Trans i18nKey="CompanyDocuments.sole_trader.address_label" />}
        />
        <Form.Item>
          <NextButton loading={isLoading} disabled={!isValid} />
        </Form.Item>
      </Form>
    </>
  );
};

/**
 * Exports
 */
export { SoleTraderDocuments };
