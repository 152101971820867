/**
 * Imports
 */
import { Drawer } from 'antd';
import { Logo, Space, Text, VibrantIcon } from 'components';
import { closeMobileMenu } from 'features/navigation/redux/actions';
import { showMobileMenuSelector } from 'features/navigation/redux/selectors';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'theme';
import { MenuLinks } from './MenuLinks';

/**
 * Styling
 */
const StyledDrawer = styled(Drawer)`
  && {
    .ant-drawer-content {
      background-color: ${(props) => props.theme.colors.Background.Primary};
    }
    .ant-drawer-body {
      display: flex;
      padding: 0;
    }
  }
`;
const Container = styled.div`
  display: flex;
  flex: 1;
  min-height: calc(100vh);
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 600px) {
    min-height: calc(100vh - (48px + 72px));
  }
`;
const Content = styled.div`
  padding: 24px;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;

  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;
const Footer = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.Border.BorderPrimary};
  padding: 24px;
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const Header = styled(Row)`
  padding-top: 24px;
  padding-left: 24px;
  margin-bottom: 80px;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: 600px) {
    margin-bottom: 48px;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const Top = styled(Column)`
  flex: 1;
  width: 100%;
`;
const Bottom = styled(Column)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;
const CardIcon = styled.div`
  background-color: white;
  background: #ffffff;
  border-radius: 2px;
  height: 24px;
  width: 38px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoWrapper = styled.div``;

/**
 * Types
 */
export interface MobileMenuProps {}

/**
 * MobileMenu component
 */
const MobileMenu: React.FC<MobileMenuProps> = (_props) => {
  const showMobileMenu = useSelector(showMobileMenuSelector);
  const dispatch = useDispatch();

  const _onClose = () => {
    dispatch(closeMobileMenu({}));
  };
  const _contactSupport = () => {
    console.log('_contactSupport');
  };

  return (
    <StyledDrawer
      placement="right"
      closable={true}
      onClose={_onClose}
      visible={showMobileMenu}
      width="100%"
      closeIcon={<VibrantIcon icon="close" size={20} />}
    >
      <Container>
        <Header>
          <LogoWrapper>
            <Logo size={24} single />
          </LogoWrapper>
        </Header>
        <Content>
          <Top>
            <MenuLinks />
          </Top>
          <Bottom>
            <Text size="ui3" color="ui">
              <Trans i18nKey="_Common.actions.btn_support_text" />
            </Text>
            <Space size="xs" />
            <Text size="ui1" link onClick={_contactSupport}>
              <Trans i18nKey="_Common.actions.btn_support" />
            </Text>
          </Bottom>
        </Content>
        <Footer>
          <Row>
            <CardIcon>
              <VibrantIcon size={22} icon="visa" />
            </CardIcon>
            <Space size="xs" vertical />
            <CardIcon>
              <VibrantIcon size={19} icon="master-card" />
            </CardIcon>
          </Row>
        </Footer>
      </Container>
    </StyledDrawer>
  );
};

/**
 * Exports
 */
export { MobileMenu };
