/**
 * Imports
 */
import { CheckOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Icon, Space, Text } from 'components';
import { closeMobileMenu } from 'features/navigation/redux/actions';
import { maybeUserSelector } from 'features/user/redux/selectors';
import { firebaseLogout } from 'firebaseAPI';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { styled } from 'theme';
import { LanguageCodeTypes, LANGUAGE_OPTIONS } from 'utils';

/**
 * Styling
 */
// Links
const Title = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid ${(props) => props.theme.colors.Border.BorderPrimary};
`;
const Link = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.Border.BorderPrimary};

  & div {
    color: ${(props) => props.selected && props.theme.colors.Text.Brand};
  }
`;

/**
 * Types
 */
export interface MenuLinksProps {}

/**
 * MenuLinks component
 */
const MenuLinks: React.FC<MenuLinksProps> = (_props) => {
  const user = useSelector(maybeUserSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const { i18n } = useTranslation();
  const _changeLanguage = (language: LanguageCodeTypes) => {
    i18n.changeLanguage(language);
  };

  const _handleLogout = async () => {
    await firebaseLogout();
    dispatch(closeMobileMenu({}));
    history.replace('/');
  };

  return (
    <>
      <Title>
        <Text size="ui3" color="ui">
          <Trans i18nKey="_Common.language_selector.title" />
        </Text>
      </Title>
      <Link
        onClick={() => _changeLanguage(LANGUAGE_OPTIONS.da)}
        selected={i18n.language === LANGUAGE_OPTIONS.da}
      >
        <Text size="p1">
          <Trans i18nKey="_Common.language_selector.da_long" />
        </Text>
        {i18n.language === LANGUAGE_OPTIONS.da && (
          <Icon icon={<CheckOutlined />} size={20} color="Orange500" />
        )}
      </Link>
      <Link
        onClick={() => _changeLanguage(LANGUAGE_OPTIONS.en)}
        selected={i18n.language === LANGUAGE_OPTIONS.en}
      >
        <Text size="p1">
          <Trans i18nKey="_Common.language_selector.en_long" />
        </Text>
        {i18n.language === LANGUAGE_OPTIONS.en && (
          <Icon icon={<CheckOutlined />} size={20} color="Orange500" />
        )}
      </Link>
      <Link
        onClick={() => _changeLanguage(LANGUAGE_OPTIONS.es)}
        selected={i18n.language === LANGUAGE_OPTIONS.es}
      >
        <Text size="p1">
          <Trans i18nKey="_Common.language_selector.es_long" />
        </Text>
        {i18n.language === LANGUAGE_OPTIONS.en && (
          <Icon icon={<CheckOutlined />} size={20} color="Orange500" />
        )}
      </Link>
      <Link
        onClick={() => _changeLanguage(LANGUAGE_OPTIONS.se)}
        selected={i18n.language === LANGUAGE_OPTIONS.se}
      >
        <Text size="p1">
          <Trans i18nKey="_Common.language_selector.se_long" />
        </Text>
        {i18n.language === LANGUAGE_OPTIONS.en && (
          <Icon icon={<CheckOutlined />} size={20} color="Orange500" />
        )}
      </Link>
      {user && (
        <>
          <Space size="xl" />
          <Title>
            <Text size="ui3" color="ui">
              <Trans i18nKey="_Common.menu.profile" />
            </Text>
          </Title>
          <Link onClick={_handleLogout}>
            <Text size="p1">
              <Trans i18nKey="_Common.actions.btn_logout" />
            </Text>
            <Icon icon={<PoweroffOutlined />} size={20} />
          </Link>
        </>
      )}
    </>
  );
};

/**
 * Exports
 */
export { MenuLinks };
