/**
 * Imports
 */
import { FileTextOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { Icon, Upload } from 'components';
import {
  NextButton,
  SignupText,
  useMerchantApi,
  useSignupFlow,
} from 'features/signupflow';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { captureError, InputUploadContent, normalizeDocuments } from 'utils';

/**
 * Types
 */
export interface LimitedLiabilityDocumentsProps {
  optional: boolean;
}

export type LimitedLiabilityFormRow = {
  company: InputUploadContent;
  vat: InputUploadContent;
};

/**
 * LimitedLiabilityDocuments component
 */
const LimitedLiabilityDocuments: React.FC<LimitedLiabilityDocumentsProps> = ({
  optional,
}) => {
  const {
    handlers: { onNextStep },
  } = useSignupFlow();
  const {
    handlers: { onUpdateCompanyDocuments },
  } = useMerchantApi();
  /* Local state */
  const [form] = Form.useForm();
  // If bussinessstructure is other (optional) we set true
  const [isValid, setIsValid] = React.useState(optional ? true : false);
  const [isLoading, setIsLoading] = React.useState(false);
  /* Redux */
  const onboardingCollection = useSelector(onboardingCollectionSelector);

  const _onFinish = async (values: LimitedLiabilityFormRow) => {
    setIsLoading(true);
    try {
      const documents = normalizeDocuments(values);
      await onUpdateCompanyDocuments(documents);
      setIsLoading(false);
      onNextStep();
    } catch (exception) {
      setIsLoading(false);
      console.log('exception', exception);
      captureError('Error: Failed to update company documents', exception);
    }
  };

  const _isValid = (allValues: LimitedLiabilityFormRow) => {
    if (allValues.company && allValues.vat) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const _onChange = (
    _values: LimitedLiabilityFormRow,
    allValues: LimitedLiabilityFormRow,
  ) => {
    _isValid(allValues);
  };

  /**
   * Set valid if companyDocumentsPath
   */
  React.useEffect(() => {
    if (onboardingCollection.companyDocumentsPath) {
      setIsValid(true);
    }
  }, []);

  return (
    <>
      <SignupText
        subheading={
          <Trans i18nKey="CompanyDocuments.limited_liability.heading" />
        }
      />
      <Form
        layout="vertical"
        form={form}
        onValuesChange={_onChange}
        onFinish={_onFinish}
      >
        <Upload
          tooltip={
            <Trans i18nKey="CompanyDocuments.limited_liability.company_tooltip" />
          }
          name={'company'}
          icon={<Icon icon={<FileTextOutlined />} size={40} color="Grey500" />}
          label={
            <b>
              <Trans i18nKey="_Common.labels.upload_document" />
            </b>
          }
          text={
            <Trans i18nKey="CompanyDocuments.limited_liability.company_label" />
          }
        />
        <Upload
          tooltip={
            <Trans i18nKey="CompanyDocuments.limited_liability.vat_tooltip" />
          }
          name={'vat'}
          icon={<Icon icon={<FileTextOutlined />} size={40} color="Grey500" />}
          label={
            <b>
              <Trans i18nKey="_Common.labels.upload_document" />
            </b>
          }
          text={
            <Trans i18nKey="CompanyDocuments.limited_liability.vat_label" />
          }
        />
        <Form.Item>
          <NextButton loading={isLoading} disabled={!isValid} />
        </Form.Item>
      </Form>
    </>
  );
};

/**
 * Exports
 */
export { LimitedLiabilityDocuments };
