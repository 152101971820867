/**
 * TYPES
 */
export type LanguageCodeTypes = 'en' | 'da' | 'se' | 'es';
export type LanguageCodeLookup = {
  [key in LanguageCodeTypes]: LanguageCodeTypes;
};

/**
 * CONSTANTS
 */
const allowedLanguages = ['da', 'en', 'se'];
export const LANGUAGE_OPTIONS: LanguageCodeLookup = {
  da: 'da',
  en: 'en',
  es: 'es',
  se: 'se',
};
export const DIALLING_CODES: { [id: string]: string } = {
  DK: '+45',
  SE: '+46',
  ES: '+34',
  DE: '+49',
};
export const CURRENCY_MAP: { [id: string]: string } = {
  DK: 'DKK',
  SE: 'SEK',
  ES: 'EUR',
};
export const VAT_MAP: { [id: string]: number } = {
  DK: 25,
  ES: 21,
  SE: 25,
};

/**
 * Get browser language
 */
export const getBrowserLanguage = (): LanguageCodeTypes => {
  const lang = navigator.language.split('-')[0].toString() as LanguageCodeTypes;
  if (allowedLanguages.includes(lang)) {
    return lang;
  } else {
    return 'en';
  }
};
