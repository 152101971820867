import { Firebase } from 'types/firebase';

/**
 * Helper Type
 * @desc Indexed map
 */
export interface Lookup<T extends any> {
  [key: string]: T;
}
/**
 * Helper type
 * @desc pick from any string literal
 */
export type Literal<T extends string> = {
  [K in T]: boolean;
};
export type StringLiteralPick<
  T extends string,
  K extends keyof Literal<T> = keyof Literal<T>
> = keyof Pick<Literal<T>, K>;

/**
 * SoftPosOnboarding
 * @description keys on SoftPosOnboarding
 */
export type OnboardingKeys = keyof Firebase.SoftPosOnboarding;
export const ONBOARDING_FIELDS: {
  [key in OnboardingKeys]: OnboardingKeys;
} = {
  addressLine1: 'addressLine1',
  averageTransactionAmount: 'averageTransactionAmount',
  bankAccountNumber: 'bankAccountNumber',
  bankRegistrationNumber: 'bankAccountNumber',
  businessActivityDescription: 'businessActivityDescription',
  businessLegalName: 'businessLegalName',
  businessStructure: 'businessStructure',
  city: 'city',
  companyDocumentsPath: 'companyDocumentsPath',
  companyRegistrationNumber: 'companyRegistrationNumber',
  contact: 'contact',
  countryCode: 'countryCode',
  createdAt: 'createdAt',
  currency: 'currency',
  merchantSegment: 'merchantSegment',
  deviceType: 'deviceType',
  iban: 'iban',
  merchantTradingName: 'merchantTradingName',
  monthlyTurnover: 'monthlyTurnover',
  onboardedAt: 'onboardedAt',
  onboardingDone: 'onboardingDone',
  onboardingId: 'onboardingId',
  onboardingStartedAt: 'onboardingStartedAt',
  ownerData: 'ownerData',
  province: 'province',
  swift: 'swift',
  vatNumber: 'vatNumber',
  vat: 'vat',
  website: 'website',
  zipCode: 'zipCode',
};

export const CONTACT_FIELDS: {
  [key in keyof Firebase.Contact]: keyof Firebase.Contact;
} = {
  contactEmail: 'contactEmail',
  fullName: 'fullName',
  phone: 'phone',
};
