/**
 * Imports
 */
import { BaseLayout } from 'features/navigation/components';
import { SignupLayout } from 'features/signupflow';
import { CeoStep } from 'features/signupflow/components/Steps';
import * as React from 'react';

/**
 * Types
 */
export interface CeoPageProps {}

/**
 * CeoPage component
 */
const CeoPage: React.FC<CeoPageProps> = (_props) => {
  return (
    <BaseLayout>
      <SignupLayout>
        <CeoStep />
      </SignupLayout>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { CeoPage };
