/**
 * Imports
 */
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Collapse, Form, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import {
  Icon,
  Input,
  messageError,
  Space,
  SubText,
  Text,
  VibrantIcon,
} from 'components';
import { setCurrentRoute } from 'features/navigation/redux/actions';
import {
  NextButton,
  OwnerLoading,
  SignupText,
  useMerchantApi,
} from 'features/signupflow';
import {
  displayNameSelector,
  onboardingCollectionSelector,
} from 'features/user/redux/selectors';
import { getFirebaseInstance } from 'firebaseAPI';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from 'router';
import { styled } from 'theme';
import { Firebase } from 'types/firebase';
import { captureError, redirectToMerchantDashboard } from 'utils';
const { Panel } = Collapse;
const { Dragger } = Upload;

/**
 * Styling
 */
const StyledFormItem = styled(Form.Item)<{ isCeo?: boolean; isLast?: boolean }>`
  && {
    display: ${(props) => props.isCeo && 'none'};

    margin-bottom: ${({ isLast }) => isLast && '8px'};
    & .ant-form-item-required {
      font-size: 13px !important;
      margin-bottom: 0 !important;
      &:before {
        display: none !important;
      }
    }
  }
`;
// Collapse
const StyledCollapse = styled(Collapse)`
  && {
    background-color: white !important;
    margin: 0 -32px;

    & .ant-collapse-header {
      background-color: white;
      padding: 24px 32px !important;

      display: flex;
      align-items: center;
    }

    .ant-collapse-content-box {
      padding: 32px;
    }

    & .ant-collapse-arrow {
      top: 18px !important;
      left: 32px !important;
      margin-bottom: 10px !important;
    }

    & .ant-collapse-extra {
      margin-left: auto;
    }

    @media (max-width: 600px) {
      margin: 0;
    }
  }
`;
const CollapseIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    fill: none !important;
  }
`;
const PanelName = styled(Text)`
  margin-left: 32px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
// Upload
const StyledDragger = styled(Dragger)`
  background-color: white !important;
  padding: 8px 32px;
`;
const UploadContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const IconWrapper = styled.div`
  margin-right: 38px;
`;
const UploadText = styled.div`
  width: 100%;
  max-width: 200px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;
// Button
const RemoveButton = styled(Text)`
  color: ${(props) => props.theme.colors.Text.UI};
  transition: ${(props) => props.theme.transitions.HoverTextColor};
  &:hover {
    color: ${(props) => props.theme.colors.Text.Brand};
  }
`;
const AddButton = styled.div`
  border: 1px solid ${(props) => props.theme.colors.Border.BorderPrimary};
  border-top: none;
  cursor: pointer;
  padding: 24px 32px;

  margin: 0 -32px;

  display: flex;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.Background.Primary};
  }

  @media (max-width: 600px) {
    margin: 0;
  }
`;
const AddButtonText = styled(Text)`
  margin-left: 24px;
`;
/**
 * Types
 */
export type OwnerFormData = {
  owners: OwnerFormRow[];
};
export type OwnerFormRow = {
  name: string;
  photoId: { file: File; fileList: UploadFile[] };
  addressId: { file: File; fileList: UploadFile[] };
};

const LABELS: {
  [key in Firebase.CountryCode]: {
    address_text: () => React.ReactNode;
    address_tooltip: () => React.ReactNode;
    address_btn: () => React.ReactNode;
    photoID_text: () => React.ReactNode;
    photoID_tooltip: () => React.ReactNode;
    photoID_btn: () => React.ReactNode;
  };
} = {
  SE: {
    address_text: () => <Trans i18nKey="OwnerStep.owner_form.addressId_text" />,
    address_tooltip: () => (
      <Trans i18nKey="OwnerStep.owner_form.addressId_tooltip" />
    ),
    address_btn: () => <Trans i18nKey="OwnerStep.owner_form.addressId_btn" />,
    photoID_text: () => <Trans i18nKey="OwnerStep.owner_form.photoID_text" />,
    photoID_tooltip: () => (
      <Trans i18nKey="OwnerStep.owner_form.photoID_tooltip" />
    ),
    photoID_btn: () => <Trans i18nKey="OwnerStep.owner_form.photoID_btn" />,
  },
  DK: {
    address_text: () => <Trans i18nKey="OwnerStep.owner_form.addressId_text" />,
    address_tooltip: () => (
      <Trans i18nKey="OwnerStep.owner_form.addressId_tooltip" />
    ),
    address_btn: () => <Trans i18nKey="OwnerStep.owner_form.addressId_btn" />,
    photoID_text: () => <Trans i18nKey="OwnerStep.owner_form.photoID_text" />,
    photoID_tooltip: () => (
      <Trans i18nKey="OwnerStep.owner_form.photoID_tooltip" />
    ),
    photoID_btn: () => <Trans i18nKey="OwnerStep.owner_form.photoID_btn" />,
  },
  ES: {
    address_text: () => (
      <Trans i18nKey="OwnerStep.owner_form.es_addressId_text" />
    ),
    address_tooltip: () => (
      <Trans i18nKey="OwnerStep.owner_form.es_addressId_tooltip" />
    ),
    address_btn: () => (
      <Trans i18nKey="OwnerStep.owner_form.es_addressId_btn" />
    ),
    photoID_text: () => (
      <Trans i18nKey="OwnerStep.owner_form.es_photoID_text" />
    ),
    photoID_tooltip: () => (
      <Trans i18nKey="OwnerStep.owner_form.photoID_tooltip" />
    ),
    photoID_btn: () => <Trans i18nKey="OwnerStep.owner_form.photoID_btn" />,
  },
};

/**
 * OwnerStep component
 */
const OwnerStep: React.FC = () => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [isFinishingSignup, setisFinishingSignup] = React.useState(false);
  const [signupDone, setSignupDone] = React.useState(false);
  const dispatch = useDispatch();
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  const {
    handlers: { onCreateCustomToken, onFinishSignup, onUpdateOwners },
  } = useMerchantApi();
  /* Local state */
  const [isValid, setIsValid] = React.useState(false);
  const [_liveValues, setLiveValues] = React.useState<any>({
    owners: [],
  });
  /* Redux */
  const displayName = useSelector(displayNameSelector);

  const _onFailed = async (_values: any) => {};

  const _onFinishSignup = async () => {
    if (!!onboardingCollection.onboardingDone) {
      const { token } = await onCreateCustomToken();
      redirectToMerchantDashboard(token);
      setSignupDone(true);
      setisFinishingSignup(false);
    } else {
      const { token } = await onFinishSignup();
      redirectToMerchantDashboard(token);
      setSignupDone(true);
      setisFinishingSignup(false);
    }
  };

  const _onFinish = async (values: OwnerFormData) => {
    try {
      setisFinishingSignup(true);
      setLiveValues(values);
      dispatch(
        setCurrentRoute({
          pageId: ROUTES.owner.pageId,
          path: ROUTES.owner.path,
          route: ROUTES.owner.route,
        }),
      );

      await onUpdateOwners(values);

      _onFinishSignup();
    } catch (exception) {
      setisFinishingSignup(false);
      captureError('Failed to upload ownerdata', exception);
      messageError({
        content: 'Failed to upload ownerdata, please try again',
      });
    }
  };

  const _onChange = (values: OwnerFormData, allValues: OwnerFormData) => {
    const fieldsFilledCount = allValues.owners.map((o: OwnerFormRow) => {
      const emptyStringRemoved = Object.values(o).filter((c) => c !== '');
      return emptyStringRemoved.length;
    });
    setLiveValues(allValues.owners);

    const allFieldsFilled = fieldsFilledCount.every((a) => a === 3);

    if (allFieldsFilled) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const _getDownloadUrl = (path: string): Promise<string> => {
    const storage = getFirebaseInstance().storage();
    return storage.ref(path).getDownloadURL();
  };

  const _setExistingData = async () => {
    // TODO: Not needed s of now
    // const data = await Promise.all(
    //   onboardingCollection.ownerData!.map(async (owner: Firebase.Owner) => {
    //     const photoId = owner.photoId && (await _getDownloadUrl(owner.photoId));
    //     const addressId =
    //       owner.addressId && (await _getDownloadUrl(owner.addressId));
    //     return {
    //       name: owner.name,
    //       photoId,
    //       addressId,
    //     };
    //   }),
    // );
    // form.setFieldsValue({
    //   owners: [...data],
    // });
  };

  React.useEffect(() => {
    if (onboardingCollection?.ownerData) {
      if (onboardingCollection.onboardingDone) {
        _setExistingData();
      } else {
        form.setFieldsValue({
          owners: [
            {
              name: onboardingCollection?.ownerData[0]?.name || displayName,
            },
          ],
        });
      }
    }
  }, [onboardingCollection]);

  return (
    <>
      {signupDone || isFinishingSignup ? (
        <OwnerLoading signupDone={signupDone} />
      ) : (
        <>
          <SignupText subheading={<Trans i18nKey="OwnerStep.heading" />} />
          <SubText text={<Trans i18nKey="OwnerStep.subtext" />} />
          <Space />
          <Form
            name="dynamic_form_nest_item"
            onFinish={_onFinish}
            onFinishFailed={_onFailed}
            autoComplete="off"
            form={form}
            onValuesChange={_onChange}
            layout="vertical"
            initialValues={{ owners: [{}] }}
          >
            <Form.List name="owners">
              {(fields, { add, remove }) => (
                <>
                  <StyledCollapse
                    defaultActiveKey={['0']}
                    expandIcon={({ isActive }) => (
                      <CollapseIcon>
                        <VibrantIcon
                          icon={isActive ? 'chevron-up' : 'chevron-down'}
                          size={12}
                        />
                      </CollapseIcon>
                    )}
                  >
                    {fields.map((field, index) => {
                      const showSsnPicture =
                        typeof form.getFieldValue('owners')[field.fieldKey]
                          ?.addressId === 'string';
                      const showPhotoPicture =
                        typeof form.getFieldValue('owners')[field.fieldKey]
                          ?.photoId === 'string';
                      return (
                        <Panel
                          header={
                            <PanelName size="ui2" color="heading">
                              {form.getFieldValue('owners')[field.fieldKey]
                                ?.name ||
                                (field.key === 0 && displayName) || (
                                  <Trans
                                    i18nKey="OwnerStep.owner_form.panel_label"
                                    values={{ number: `${field.key + 1}` }}
                                  />
                                )}
                            </PanelName>
                          }
                          key={index}
                          extra={
                            <RemoveButton
                              size="ui3"
                              onClick={() => remove(field.name)}
                            >
                              <Trans i18nKey="OwnerStep.btn_remove" />
                            </RemoveButton>
                          }
                        >
                          <StyledFormItem
                            {...field}
                            key={field.key}
                            isCeo={field.key === 0}
                            initialValue={field.key === 0 && displayName}
                            label={
                              <Trans i18nKey="OwnerStep.owner_form.name_label" />
                            }
                            name={[field.name, 'name']}
                            fieldKey={[field.fieldKey, 'name']}
                          >
                            <Input
                              placeholder={t(
                                'OwnerStep.owner_form.name_placeholder',
                              )}
                            />
                          </StyledFormItem>
                          {/* PhotoId */}
                          <StyledFormItem
                            {...field}
                            isLast
                            label={
                              <b>
                                <Trans i18nKey="OwnerStep.owner_form.photoID_label" />
                              </b>
                            }
                            tooltip={
                              <Trans i18nKey="OwnerStep.owner_form.photoID_tooltip" />
                            }
                            name={[field.name, 'photoId']}
                            fieldKey={[field.fieldKey, 'photoId']}
                          >
                            <StyledDragger
                              beforeUpload={() => false}
                              multiple={false}
                              accept="image/jpeg, image/png, application/pdf"
                            >
                              <UploadContent>
                                {showPhotoPicture ? (
                                  <img
                                    alt="photoId"
                                    style={{
                                      width: '64px',
                                      marginRight: '12px',
                                    }}
                                    src={
                                      form.getFieldValue('owners')[
                                        field.fieldKey
                                      ].photoId
                                    }
                                  />
                                ) : (
                                  <IconWrapper>
                                    <Icon
                                      icon={<UserOutlined />}
                                      size={40}
                                      color="Grey300"
                                    />
                                  </IconWrapper>
                                )}
                                <UploadText>
                                  <Text size="ui2" bold>
                                    {LABELS[
                                      onboardingCollection.countryCode!
                                    ].photoID_text()}
                                  </Text>
                                  <Text size="p3">
                                    {LABELS[
                                      onboardingCollection.countryCode!
                                    ].photoID_btn()}
                                  </Text>
                                </UploadText>
                              </UploadContent>
                            </StyledDragger>
                          </StyledFormItem>
                          {/* SSN */}
                          <StyledFormItem
                            {...field}
                            isLast
                            label={
                              <b>
                                <Trans i18nKey="OwnerStep.owner_form.addressId_label" />
                              </b>
                            }
                            tooltip={LABELS[
                              onboardingCollection.countryCode!
                            ].address_tooltip()}
                            name={[field.name, 'addressId']}
                            fieldKey={[field.fieldKey, 'addressId']}
                          >
                            <StyledDragger
                              beforeUpload={() => false}
                              multiple={false}
                              accept="image/jpeg, image/png, application/pdf"
                            >
                              <UploadContent>
                                {showSsnPicture ? (
                                  <img
                                    alt="addressId"
                                    style={{
                                      width: '64px',
                                      marginRight: '12px',
                                    }}
                                    src={
                                      form.getFieldValue('owners')[
                                        field.fieldKey
                                      ].addressId
                                    }
                                  />
                                ) : (
                                  <IconWrapper>
                                    <Icon
                                      icon={<UserOutlined />}
                                      size={40}
                                      color="Grey300"
                                    />
                                  </IconWrapper>
                                )}
                                <UploadText>
                                  <Text size="ui2" bold>
                                    {LABELS[
                                      onboardingCollection.countryCode!
                                    ].address_text()}
                                  </Text>
                                  <Text size="p3">
                                    {LABELS[
                                      onboardingCollection.countryCode!
                                    ].address_btn()}
                                  </Text>
                                </UploadText>
                              </UploadContent>
                            </StyledDragger>
                          </StyledFormItem>
                        </Panel>
                      );
                    })}
                  </StyledCollapse>
                  <StyledFormItem isLast>
                    <AddButton
                      onClick={() =>
                        add({
                          name: '',
                        })
                      }
                    >
                      <Icon icon={<PlusOutlined />} color="Orange500" />
                      <AddButtonText size="ui2" color="brand" bold>
                        <Trans i18nKey="OwnerStep.btn_add" />
                      </AddButtonText>
                    </AddButton>
                  </StyledFormItem>
                </>
              )}
            </Form.List>

            <Space />
            <StyledFormItem>
              <NextButton disabled={!isValid} />
            </StyledFormItem>
          </Form>
        </>
      )}
    </>
  );
};

/**
 * Exports
 */
export { OwnerStep };
