/**
 * Imports
 */
import { createReducer } from '@reduxjs/toolkit';
import { FullUser } from '../types';
import { logout, userUpdated, UserUpdatedAction } from './actions';

/**
 * Types
 */
export interface UserState {
  user: FullUser | null;
}

/**
 * Constants
 */
const INITIAL_STATE: UserState = {
  user: null,
};

/**
 * `user` reducer
 */
const userReducer = createReducer(INITIAL_STATE, {
  /**
   * USER_UPDATED
   */
  [userUpdated.type]: (state, action: UserUpdatedAction) => {
    state.user = action.payload;
  },
  /**
   * LOGOUT
   */
  [logout.type]: (state) => {
    state.user = null;
  },
});

/**
 * Exports
 */
export default userReducer;
