/**
 * Imports
 */
import {
  LimitedLiabilityDocuments,
  SoleTraderDocuments,
} from 'features/signupflow';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';

/**
 * Types
 */
export interface CompanyDocumentsStepProps {}

/**
 * CompanyDocumentsStep component
 */
const CompanyDocumentsStep: React.FC<CompanyDocumentsStepProps> = (_props) => {
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  if (!onboardingCollection?.businessStructure) {
    return null;
  }
  const { businessStructure } = onboardingCollection;
  const isOther =
    businessStructure === 'other' ||
    businessStructure === 'ngo' ||
    businessStructure === 'public';
  const isLimitedLiabilityOrOther =
    businessStructure === 'limitedLiability' || isOther;
  const isSoleTrader = businessStructure === 'soleTrader';
  return (
    <>
      {isLimitedLiabilityOrOther && (
        <LimitedLiabilityDocuments optional={isOther} />
      )}
      {isSoleTrader && <SoleTraderDocuments />}
    </>
  );
};

/**
 * Exports
 */
export { CompanyDocumentsStep };
