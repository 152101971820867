/**
 * Notes
 * https://data.virk.dk/inspiration/app-market/berig-dit-system-med-cvr-data
 * https://cvrapi.dk/documentation
 */

/**
 * Imports
 */
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { PROJECT_API_URL } from 'config';
import { VibrantAPI } from 'types/vibrant';

/**
 * Get company information from CVR or name
 */
const apiSearchCvr = async (value: string, name?: boolean): Promise<any> => {
  try {
    const response = (await axios.get(
      `${PROJECT_API_URL}/public/cvr?vat=${value}${name && '&name=true'}`,
    )) as VibrantAPI.GetVATResponse;
    return response.data.result;
  } catch (error) {
    const errMsg = 'Failed to retrieve data from cvrapi';
    if (error.response.data.error) {
      Sentry.captureMessage(errMsg, {
        fingerprint: ['cvrapi'],
        level: Sentry.Severity.Warning,
        extra: {
          reason: error.response.data.error,
          error,
        },
      });
      throw new Error(error.response.data.error);
    }
    throw new Error(errMsg);
  }
};

/**
 * Exports
 */
export { apiSearchCvr };
