/**
 * Imports
 */
import * as React from 'react';
import { BaseLayout } from 'features/navigation/components';
import { Spinner } from 'components';
import { styled } from 'theme';

/**
 * Styling
 */
const Content = styled.div`
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

/**
 * LogoutPage component
 */
const LogoutPage: React.FC = () => {
  return (
    <BaseLayout>
      <Content>
        <Spinner showSpinner size={64} />
      </Content>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { LogoutPage };
