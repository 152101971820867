import Icon from '@ant-design/icons';
import * as React from 'react';
import { Icon as CustomIcon } from 'components';
import { ColorShadesType } from 'theme/colors';
// Custom icons
import { ReactComponent as AndroidSvg } from '../../assets/icons/icon-android.svg';
import { ReactComponent as AppleSvg } from '../../assets/icons/icon-apple.svg';
import { ReactComponent as ArrowLongLeftSvg } from '../../assets/icons/icon-arrow-left.svg';
import { ReactComponent as ArrowLongRightSvg } from '../../assets/icons/icon-arrow-right.svg';
import { ReactComponent as CheckmarkCheckedSvg } from '../../assets/icons/icon-checkmark-checked.svg';
import { ReactComponent as ChevronDownSvg } from '../../assets/icons/icon-chevron-down.svg';
import { ReactComponent as ChevronUpSvg } from '../../assets/icons/icon-chevron-up.svg';
import { ReactComponent as CloseSvg } from '../../assets/icons/icon-close.svg';
import { ReactComponent as GoogleSvg } from '../../assets/icons/icon-google.svg';
import { ReactComponent as MasterCardSvg } from '../../assets/icons/icon-mastercard.svg';
import { ReactComponent as RadioCheckedSvg } from '../../assets/icons/icon-radio-checked.svg';
import { ReactComponent as RadioUncheckedSvg } from '../../assets/icons/icon-radio-unchecked.svg';
import { ReactComponent as MenuSvg } from '../../assets/icons/icon-menu.svg';
import { ReactComponent as SunSvg } from '../../assets/icons/icon-sun.svg';
import { ReactComponent as VisaCardSvg } from '../../assets/icons/icon-visa.svg';
import { ReactComponent as OverviewSvg } from '../../assets/icons/icon-overview.svg';
import { ReactComponent as TransactionSvg } from '../../assets/icons/icon-transaction.svg';
import { ReactComponent as NfcSvg } from '../../assets/icons/icon-nfc.svg';
import { ReactComponent as HomeSvg } from '../../assets/icons/icon-home.svg';
/**
 * Types
 */
export type VibrantIconTypes =
  | 'android'
  | 'apple'
  | 'arrow-long-left'
  | 'arrow-long-right'
  | 'checkmark-checked'
  | 'chevron-down'
  | 'chevron-up'
  | 'close'
  | 'google'
  | 'home'
  | 'master-card'
  | 'menu'
  | 'nfc'
  | 'overview'
  | 'radio-checked'
  | 'radio-unchecked'
  | 'sun'
  | 'transaction'
  | 'visa';

export type CustomIconType = React.FunctionComponent<
  React.SVGProps<SVGSVGElement>
>;
export type VibrantIconProps = {
  color?: ColorShadesType;
  icon: VibrantIconTypes;
  size?: number;
};

/**
 * CONST
 */
const ICON_MAPPER: { [key in VibrantIconTypes]: any } = {
  android: <Icon component={AndroidSvg} />,
  apple: <Icon component={AppleSvg} />,
  'arrow-long-left': <Icon component={ArrowLongLeftSvg} />,
  'arrow-long-right': <Icon component={ArrowLongRightSvg} />,
  'checkmark-checked': <Icon component={CheckmarkCheckedSvg} />,
  'chevron-down': <Icon component={ChevronDownSvg} />,
  'chevron-up': <Icon component={ChevronUpSvg} />,
  close: <Icon component={CloseSvg} />,
  google: <Icon component={GoogleSvg} />,
  'master-card': <Icon component={MasterCardSvg} />,
  menu: <Icon component={MenuSvg} />,
  'radio-checked': <Icon component={RadioCheckedSvg} />,
  'radio-unchecked': <Icon component={RadioUncheckedSvg} />,
  sun: <Icon component={SunSvg} />,
  visa: <Icon component={VisaCardSvg} />,
  overview: <Icon component={OverviewSvg} />,
  transaction: <Icon component={TransactionSvg} />,
  nfc: <Icon component={NfcSvg} />,
  home: <Icon component={HomeSvg} />,
};

const VibrantIcon: React.FC<VibrantIconProps> = ({ icon, color, size }) => (
  <CustomIcon icon={ICON_MAPPER[icon]} color={color} size={size} />
);

export { VibrantIcon };
