/**
 * Imports
 */
import { BaseLayout } from 'features/navigation/components';
import { BusinessStructureStep, SignupLayout } from 'features/signupflow';
import * as React from 'react';

/**
 * Types
 */
export interface BusinessStructurePageProps {}

/**
 * BusinessStructurePage component
 */
const BusinessStructurePage: React.FC<BusinessStructurePageProps> = (
  _props,
) => {
  return (
    <BaseLayout>
      <SignupLayout>
        <BusinessStructureStep />
      </SignupLayout>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { BusinessStructurePage };
