/**
 * Imports
 */
import { Text } from 'components';
import { useSignupFlow } from 'features/signupflow/api';
import * as React from 'react';
import { styled } from 'theme';

/**
 * Styling
 */
const Row = styled.div<{ push?: boolean }>`
  display: flex;
  justify-content: ${(props) => props.push && 'space-between'};
  align-items: center;
`;
const Divider = styled.div`
  height: 22px;
  margin: 0 16px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
const DividerCircle = styled.div`
  background-color: ${(props) => props.theme.colors.Background.Brand};
  border-radius: 50%;
  height: 4px;
  width: 4px;
`;

/**
 * Types
 */
export interface StepIndexProps {
  hideTime?: boolean;
}

/**
 * StepIndex component
 */
const StepIndex: React.FC<StepIndexProps> = ({ hideTime }) => {
  const { currentStep, lastStep } = useSignupFlow();

  if (!currentStep) {
    return null;
  }

  return (
    <Row>
      <Text size="ui2" color="ui">
        {currentStep.signupStep + 1} / {lastStep}
      </Text>
      {!hideTime && (
        <>
          <Divider>
            <DividerCircle />
          </Divider>
          <Text size="ui2" color="ui">
            {currentStep.pageTime()}
          </Text>
        </>
      )}
    </Row>
  );
};

/**
 * Exports
 */
export { StepIndex };
