/**
 * Imports
 */
import {
  Box,
  Input,
  Label,
  Snackbar,
  Space,
  Text,
  VibrantIcon,
} from 'components';
import { NextButton, SignupText } from 'features/signupflow';
import { useMerchantApi, useSignupFlow } from 'features/signupflow/api';
import { onboardingCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { styled } from 'theme';
import { captureError } from 'utils';
import { IBAN_EXAMPLES, IBAN_REGEX } from './iban-examples';

/**
 * Styling
 */
const StyledInput = styled(Input)`
  margin-bottom: 8px;
`;
const StyledLink = styled.a`
  border-radius: 28px;
  background-color: ${(props) => props.theme.colors.Background.Brand};
  color: ${(props) => props.theme.colors.Text.White}!important;
  padding: 8px 16px;
  transition: ${(props) => props.theme.transitions.HoverTextColor};
  margin-right: auto;
  max-width: fit-content;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:hover {
    transition: background-color 0.2s ease;
    background-color: ${(props) => props.theme.colors.Shades.Orange700};
  }
`;
const StyledButton = styled.div`
  cursor: pointer;
  display: inline;

  text-decoration: underline;
  & svg {
    margin-left: 4px;
  }
`;

/**
 * IbanForm component
 */
const IbanForm: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [validIban, setValidIban] = React.useState(false);
  const [touched, setTouched] = React.useState(false);
  const [ibanValue, setIbanValue] = React.useState('');
  const onboardingCollection = useSelector(onboardingCollectionSelector);
  const {
    handlers: { onUpdateIban },
  } = useMerchantApi();
  const {
    handlers: { onNextStep },
  } = useSignupFlow();

  const _onFinish = async () => {
    setIsLoading(true);
    try {
      await onUpdateIban({ iban: ibanValue });
      setIsLoading(false);
      onNextStep();
    } catch (exception) {
      setIsLoading(false);
      captureError(
        'Error: Failed to update bank information',
        exception as Error,
      );
    }
  };

  const checkIBAN = async () => {
    const isValid = IBAN_REGEX.test(ibanValue);
    setIsLoading(false);
    setTouched(true);
    if (isValid) {
      setValidIban(true);
    } else {
      setValidIban(false);
    }
  };
  const _forceSubmit = () => {
    setTouched(false);
    setValidIban(true);
  };

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const trimmedValue = value.replaceAll(' ', '');
    setTouched(false);
    setIsLoading(false);
    setIbanValue(trimmedValue.toUpperCase().trim());
  };

  React.useEffect(() => {
    // if input is cleared
    if (ibanValue.length === 0) {
      setTouched(false);
      setValidIban(false);
    }
    // The debounce function delays the processing of the keyup event until the user has stopped typing
    // for a predetermined amount of time, to avoid unnecessary checks
    const delayDebounceFn = setTimeout(() => {
      if (ibanValue.length > 0) {
        setIsLoading(true);
        checkIBAN();
      }
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [ibanValue]);

  React.useEffect(() => {
    if (onboardingCollection?.iban) {
      setIbanValue(onboardingCollection.iban);
    }
  }, [onboardingCollection]);

  return (
    <>
      <SignupText subheading={<Trans i18nKey="BankStep.iban_form.heading" />} />
      <Box mt="-16px" />
      <Text size="p2">
        <Trans i18nKey="BankStep.iban_form.subtext_text" />
      </Text>
      <Box mt="16px" />
      <Box width="auto">
        <StyledLink
          href="https://vibrant-help.zendesk.com/hc/en-us/articles/4417185693841-How-to-locate-your-IBAN-in-top-4-Danish-banks"
          target="_blank"
        >
          <Trans i18nKey="BankStep.iban_form.subtext_button" />
          <Box ml="8px" />
          <VibrantIcon icon="arrow-long-right" color="White000" />
        </StyledLink>
      </Box>
      <Box mt="32px" />
      <Label text={<Trans i18nKey="BankStep.iban_form.input_label" />} />
      <StyledInput
        value={ibanValue}
        placeholder={IBAN_EXAMPLES[onboardingCollection.countryCode!] || ''}
        onChange={_onChange}
      />
      {touched && !validIban && (
        <Snackbar type="warning">
          <Trans i18nKey="BankStep.iban_form.warning_msg">
            <StyledButton onClick={_forceSubmit} />
          </Trans>
        </Snackbar>
      )}
      <Space size="lg" />
      <NextButton
        loading={isLoading}
        onClick={_onFinish}
        disabled={!validIban}
      />
    </>
  );
};

/**
 * Exports
 */
export { IbanForm };
