/**
 * Imports
 */
import * as React from 'react';
import { Button as AntdButton } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button/button';
import { styled, VibrantColors } from 'theme';
import { css } from 'styled-components';

/**
 * Link
 */
const TEXT_COMMON_CSS = css`
  color: ${VibrantColors.Button.Link};
  padding: 0 0 3px 0;
  border-bottom: 2px solid ${VibrantColors.Button.Link};
  border-radius: 0;

  &:hover {
    color: ${VibrantColors.Button.LinkHover};
    border-bottom-color: ${VibrantColors.Button.LinkHover};
  }
`;
const DASHED = css`
  background-color: white;
  border: 1px solid ${VibrantColors.Border.BorderBrand};
  color: ${VibrantColors.Button.PrimaryColor};

  &:hover {
    background-color: white;
    color: ${VibrantColors.Button.PrimaryColor};
    border: 1px solid ${VibrantColors.Border.BorderBrand};
  }
`;
const DEFAULT = css`
  /* background-color: white;
  border: 1px solid ${VibrantColors.Border.BorderPrimary};
  color: ${VibrantColors.Button.Link};

  &:hover {
    background-color: white;
    color: ${VibrantColors.Button.LinkHover};
    border: 1px solid ${VibrantColors.Border.BorderBrand};
  } */
`;
/**
 * Styling
 */
const IconWrapper = styled.div`
  position: absolute;
  right: 24px;
`;
const StyledButton = styled(AntdButton)<AntdButtonProps & ButtonProps>`
  && {
    font-family: 'BasierBold', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    font-size: 17px;
    line-height: 21.76px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${(props) => props.type === 'link' && TEXT_COMMON_CSS}
  ${(props) => props.type === 'dashed' && DASHED}
  ${(props) => props.type === 'default' && DEFAULT}
`;

/**
 * Types
 */
export type ButtonProps = AntdButtonProps & {
  iconRight?: React.ReactNode;
};

/**
 * Button component
 */
const Button: React.FC<ButtonProps> = ({
  children,
  iconRight,
  ...restProps
}) => {
  return (
    <StyledButton {...restProps}>
      {children}
      {iconRight && <IconWrapper>{iconRight}</IconWrapper>}
    </StyledButton>
  );
};

/**
 * Exports
 */
export { Button };
