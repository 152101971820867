/**
 * Imports
 */

import { UploadFile } from 'antd/lib/upload/interface';
import { pickBy } from 'lodash';
import { captureError } from 'utils';

/**
 * Types
 */
export type InputUploadContent = { file: File; fileList: UploadFile[] };
export type UploadDocuments = {
  [key: string]: InputUploadContent;
};
export type UploadDocument = { name: string; file: File };
export type NormnalizedDocuments = UploadDocument[];

/**
 * Normalize documents for upload to firebase with filename and file
 */
export const normalizeDocuments = (
  documents: UploadDocuments,
): NormnalizedDocuments => {
  try {
    // Rm undefined values
    const filteredDocuments = pickBy(documents, (v) => v !== undefined);
    const normalized = Object.entries(filteredDocuments)
      .map((entry) => {
        return {
          fileList: entry[1].fileList.map((file, index) => {
            return {
              name: `${entry[0]}_${index}`,
              file: file.originFileObj as File,
            };
          }),
        };
      })
      .map((entry) => [...entry.fileList])
      .flat();
    return normalized;
  } catch (exception) {
    const errMsg = 'Failed to normalize documents for upload';
    captureError(errMsg, exception);
    throw new Error(errMsg);
  }
};
